import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';

import {
  InputLabelOrange,
  OutlinedButton,
  ContainedButton,
  CsvIcon,
} from './styles';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) =>
        (orderBy !== 'name' && desc(a, b, orderBy)) ||
        b.name.localeCompare(a.name)
    : (a, b) =>
        (orderBy !== 'name' && -desc(a, b, orderBy)) ||
        a.name.localeCompare(b.name);
}

export function FormCSV({
  handleClose,
  data,
  period,
  dataMonths,
  headCells,
  order,
  orderBy,
}) {
  const loading = useSelector((state) => state.Client.loadingFeedback);
  const options = ['Todas', 'Selecionadas'];

  const [option, setOption] = useState('Todas');
  const [headers, setHeaders] = useState([]);

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  useEffect(() => {
    if (option === 'Selecionadas') {
      const filterHeaders = Array.from(headCells.values())
        .filter((headCell) => headCell.show)
        .map((headCell) => ({
          key: headCell.id,
          label: headCell.label,
        }));
      setHeaders(filterHeaders);
    } else {
      const filterHeaders = Array.from(headCells.values()).map((headCell) => ({
        key: headCell.id,
        label: headCell.label,
      }));
      setHeaders(filterHeaders);
    }
  }, [option, headCells]);

  return (
    <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <CsvIcon size="24" color="#f8625c" style={{ marginRight: 15 }} />
        Exportar dados em CSV
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Escolha se deseja fazer download de todas as colunas ou somente as
          selecionadas
        </DialogContentText>
        <Divider style={{ marginBottom: '1rem' }} />
        <FormControl fullWidth>
          <InputLabelOrange id="feedback-select-label">
            Exportar colunas:
          </InputLabelOrange>
          <Select
            labelId="feedback-select-label"
            value={option}
            onChange={handleChange}
          >
            {options.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ padding: 24 }}>
        <OutlinedButton onClick={handleClose} color="primary">
          Cancelar
        </OutlinedButton>
        <ContainedButton
          style={{ width: 90 }}
          color="primary"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: 'white' }} />
          ) : (
            <CSVLink
              filename={`[STI] Drives Compartilhados - ${new Date()
                .toLocaleString()
                .replace(/\/|:|\s/g, '')}- ${
                (period === 'year' && `últimos ${dataMonths} meses`) ||
                (period === 'trimester' && 'últimos 3 meses') ||
                'últimos 30 dias'
              }.csv`}
              target="_blank"
              onClick={() => {
                toast.info('Download do CSV realizado com sucesso.', {
                  autoClose: 5000,
                });
                handleClose();
              }}
              data={stableSort(data, getSorting(order, orderBy))}
              headers={headers}
              style={{ color: 'white' }}
            >
              {/* <b style={{ color: '#f75a48' }}>Exportar</b>{' '} */}
              Exportar
            </CSVLink>
          )}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

FormCSV.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  period: PropTypes.string.isRequired,
  dataMonths: PropTypes.number.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      show: PropTypes.bool,
    })
  ).isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default FormCSV;
