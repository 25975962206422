import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 30px 50px;
  color: #333;
  overflow: hidden;
`;
export const Profile = styled.div`
  display: flex;
  align-items: center;
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 20px;
  }
  div {
    display: flex;
    flex-direction: column;

    small {
      font-size: 12px;
    }
  }
`;

export const Table = styled.table`
  width: 100%;

  -webkit-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  thead {
    tr {
      text-align: left;
      background: #fff;
      border: none;
      &:first-child {
        th {
          text-align: left !important;
          padding: 20px;
          font-size: 16px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
      th {
        padding: 20px 10px;
        font-size: 12px !important;

        &:first-child,
        &:last-child {
          text-align: center;
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:hover {
        background-color: #e0f3ff;
      }
      td {
        padding: 10px 5px;

        button {
          border: none;
          font-size: 12px;
          padding: 10px 20px;
          border-radius: 3px;
          display: flex;
          svg {
            margin-right: 10px;
          }
        }
        &:first-child,
        &:last-child {
          text-align: center;
        }
      }
    }
  }
`;

export const ButtonAddNew = styled.button`
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #d91333;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 40px;
  right: 40px;
  transition: all 0.3s;

  -webkit-box-shadow: 0px 3px 10px -4px rgba(122, 122, 122, 1);
  -moz-box-shadow: 0px 3px 10px -4px rgba(122, 122, 122, 1);
  box-shadow: 0px 3px 10px -4px rgba(122, 122, 122, 1);

  &:hover {
    background-color: #d92550;
    -webkit-box-shadow: 0px 3px 13px -2px rgba(0, 0, 0, 0.9);
    -moz-box-shadow: 0px 3px 13px -2px rgba(0, 0, 0, 0.9);
    box-shadow: 0px 3px 13px -2px rgba(0, 0, 0, 0.9);
  }
`;
export const HeaderAdmin = styled.div`
  display: flex;

  align-items: center;
  padding: 0 50px;
  width: 100%;
  min-height: 180px;
  background: hsla(0, 0%, 100%, 0.55);
  div {
    margin-top: 50px;
    padding: 10px;
    background: #fff;
    -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
    -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
    box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  }
  span {
    margin-left: 20px;
    margin-top: 50px;
    p {
      color: #495057;
      font-size: 1.7em;
    }
    small {
      color: #f8625c;
    }
  }
`;
