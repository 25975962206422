export function signInRequest(
  tokenRequest,
  accessToken,
  email,
  googleId,
  thumbnail
) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { tokenRequest, accessToken, email, googleId, thumbnail },
  };
}

export function signInSuccess(token, user, client, orgUnits) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, client, orgUnits },
  };
}
export function signUpRequest(clientData, emailAdmin) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { clientData, emailAdmin },
  };
}

export function signUpSuccess() {
  return {
    type: '@auth/SIGN_UP_SUCCESS',
  };
}
export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function signFailure(email, thumbnail, domain, registerTime) {
  return {
    type: '@auth/SIGN_IN_FAILURE',
    payload: { email, thumbnail, domain, registerTime },
  };
}
