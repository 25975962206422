import React from 'react';
import { useSelector } from 'react-redux';
import { MdRssFeed, MdLibraryBooks, MdWeb } from 'react-icons/md';
import {
  Container,
  CardContainer,
  CardsEditors,
  CardEditor,
  TotalCardEditor,
  LabelCardEditor,
} from './styles';

export default function EditorDriveCharts() {
  const { client_gplus, client_drive } = useSelector(
    (state) => state.ClientData.clientEditorDriveData
  );

  return (
    <>
      <Container>
        <CardContainer>
          <CardsEditors>
            <CardEditor>
              <TotalCardEditor>
                {client_drive && client_drive[0]
                  ? client_drive[0].create_forms || 0
                  : 0}
              </TotalCardEditor>
              <LabelCardEditor>
                <MdLibraryBooks size="18" color="#8832d8" /> &nbsp;
                <span className="forms">Formulários</span>
              </LabelCardEditor>
            </CardEditor>
            <CardEditor>
              <TotalCardEditor>
                {client_gplus && client_gplus[0]
                  ? client_gplus[0].communities_gplus || 0
                  : 0}
              </TotalCardEditor>
              <LabelCardEditor>
                <MdRssFeed size="18" color="rgb(222, 37, 1)" /> &nbsp;
                <span className="gplus">Comunidades</span>
              </LabelCardEditor>
            </CardEditor>
            <CardEditor className="last">
              <TotalCardEditor>
                {client_drive && client_drive[0]
                  ? client_drive[0].create_sites || 0
                  : 0}
              </TotalCardEditor>
              <LabelCardEditor>
                <MdWeb size="18" color="rgb(0, 88, 241)" /> &nbsp;
                <span className="sites">Sites</span>
              </LabelCardEditor>
            </CardEditor>
          </CardsEditors>
        </CardContainer>
      </Container>
    </>
  );
}
