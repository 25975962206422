import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  position: relative;
  button {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    background: none;
    border: none;
  }

  img {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
`;
export const ProfileCard = styled.div`
  position: absolute;
  width: auto;
  right: 10px;
  top: 52px;
  background: rgba(250, 251, 252, 0.1);
  border-radius: 4px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  transition: all 0.5s ease-out;
  @media ${device.mobileS} {
    right: -25px;
  }
  @media ${device.mobileM} {
    right: -25px;
  }
  @media ${device.tablet} {
    right: -25px;
  }
`;
export const TopCard = styled.div`
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: #f8625c;
  border-radius: 3px;
  div {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    p {
      font-size: 14px;
    }
    small {
      font-size: 0.8em;
    }

    button {
      background-color: rgba(250, 251, 252, 0.8);
      padding: 5px 10px;
      border-radius: 5px;
      color: #495057;

      &:hover {
        background-color: ${darken(0.1, 'rgba(250, 251, 252, 0.8)')};
        color: #fff;
      }
    }
  }
`;
export const CardContent = styled.div`
  background: #fff;
  max-height: 260px;
  padding: 0px 15px 20px 15px;
  width: 100%;
  header {
    padding: 20px 0;
  }
  a {
    text-decoration: none;
    color: #3f6ad8;
  }
  hr {
    margin: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
export const ButtonAdmin = styled(Link)`
  background: #f8625c !important;
  display: flex;
  max-width: 115px;
  align-items: center;
  padding: 10px 15px !important;
  color: #fff !important;
  font-size: 14px !important;

  border-radius: 5px;
  &:hover {
    background-color: ${darken(0.1, '#f8625c')}!important;
  }
`;
export const Logout = styled(GoogleLogout)`
  width: 90px;
  height: 35px;
  font-weight: bolder;
  div {
    padding: 0 10px !important;
  }
`;
