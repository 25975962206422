import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdCreateNewFolder } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ChartCreateAppsCardArea from '~/components/Dashboard/Organization/Chart/CreateApps/CardArea';
import {
  Container,
  MuiButtonGroup,
  ContainerGrid,
  LoadingArea,
  PeriodButton,
  App30Days,
  ContainerCard,
  HeaderChart,
  TitleText,
} from './styles';
import { getDocsCreateDataRequest } from '~/store/modules/ClientData/actions';

export default function AppUsageCharts() {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.ClientData.clientDocsCreateData
  );

  const profile = useSelector((state) => state.User.profile);
  const { currentUserClient, currentOrgUnits } = useSelector(
    (state) => state.Client
  );
  const [filter, setFilter] = useState({
    period: 'year',
    ou: currentOrgUnits[0],
  });

  const customer_id = useMemo(
    () => (profile ? currentUserClient || profile.client_id.name : null),
    [currentUserClient, profile]
  );

  function handleChangeOu(e) {
    setFilter({ ...filter, ou: e.target.value });
  }

  useEffect(() => {
    dispatch(getDocsCreateDataRequest(customer_id, filter));
  }, [filter, customer_id, dispatch]);

  const { dataMonths } = useSelector((state) => state.Client.userClient);

  return (
    <>
      <Container>
        <HeaderChart>
          <div className="title">
            <TitleText>
              <MdCreateNewFolder size="24" />
              <span>
                <p>Arquivos criados</p>
                <small>
                  {(filter.period === 'year' &&
                    `últimos ${dataMonths} meses`) ||
                    (filter.period === 'trimester' && 'últimos 3 meses') ||
                    'últimos 30 dias'}
                </small>
              </span>
            </TitleText>
            <FormControl
              // className={classes.formControl}
              style={{
                width: '400px',
                margin: '-4px auto',
                borderRight: '2px solid rgba(0, 0, 0, 0.1)',
                paddingRight: '10px',
                marginRight: '10px',
                fontWeight: 'normal',
              }}
            >
              <InputLabel
                id="create-apps-ou-label"
                htmlFor="create-apps-ou-select"
                style={{ color: '#f8625c' }}
              >
                Unidade Organizacional
              </InputLabel>
              <Select
                labelId="create-apps-ou-label"
                id="create-apps-ou-select"
                value={filter.ou}
                onChange={handleChangeOu}
                label="OU"
              >
                {currentOrgUnits.map((ou) => (
                  <MenuItem value={ou} key={ou}>
                    {ou === '/' ? 'TODAS' : ou}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MuiButtonGroup
              color="secondary"
              aria-label="outlined secondary button group"
            >
              <PeriodButton
                disabled={filter.period === 'month'}
                variant={filter.period === 'month' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter({ ...filter, period: 'month' });
                }}
              >
                Mês
              </PeriodButton>
              <PeriodButton
                disabled={filter.period === 'trimester'}
                variant={
                  filter.period === 'trimester' ? 'contained' : 'outlined'
                }
                onClick={() => {
                  setFilter({ ...filter, period: 'trimester' });
                }}
              >
                Trimestre
              </PeriodButton>
              <PeriodButton
                disabled={filter.period === 'year'}
                variant={filter.period === 'year' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter({ ...filter, period: 'year' });
                }}
              >
                Ano
              </PeriodButton>
            </MuiButtonGroup>
          </div>
        </HeaderChart>
        <hr />
        {(!loading && (
          <ContainerGrid>
            <ContainerCard>
              <App30Days>
                <ChartCreateAppsCardArea
                  color="rgb(82, 143, 245)"
                  keyName="documents"
                  itemNamePT="Documentos"
                  filter={filter}
                />
              </App30Days>
            </ContainerCard>
            <ContainerCard>
              <App30Days>
                <ChartCreateAppsCardArea
                  color="rgb(15, 157, 88)"
                  keyName="spreadsheets"
                  itemNamePT="Planilhas"
                  filter={filter}
                />
              </App30Days>
            </ContainerCard>
            <ContainerCard>
              <App30Days>
                <ChartCreateAppsCardArea
                  color="rgb(120, 80, 193)"
                  keyName="forms"
                  itemNamePT="Formulários"
                  filter={filter}
                />
              </App30Days>
            </ContainerCard>
            <ContainerCard>
              <App30Days>
                <ChartCreateAppsCardArea
                  color="rgb(245, 186, 21)"
                  keyName="presentations"
                  itemNamePT="Apresentações"
                  filter={filter}
                />
              </App30Days>
            </ContainerCard>
            <ContainerCard>
              <App30Days>
                <ChartCreateAppsCardArea
                  color="rgb(225, 100, 110)"
                  keyName="sites"
                  itemNamePT="Sites"
                  filter={filter}
                />
              </App30Days>
            </ContainerCard>
            <ContainerCard>
              <App30Days>
                <ChartCreateAppsCardArea
                  color="rgb(130, 130, 130)"
                  keyName="others"
                  itemNamePT="Outros"
                  filter={filter}
                />
              </App30Days>
            </ContainerCard>
          </ContainerGrid>
        )) || (
          <LoadingArea>
            <CircularProgress style={{ color: '#f8625c' }} />
          </LoadingArea>
        )}
      </Container>
    </>
  );
}
