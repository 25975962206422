/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { MdLaunch, MdArrowBack } from 'react-icons/md';
import logo from '~/assets/logo2.png';
import select_project from '~/assets/gifs/select_project_2.gif';
import acesso_conta from '~/assets/gifs/acesso_conta_servico.gif';
import create_service_acount from '~/assets/gifs/create_service_acount.gif';
import make_json from '~/assets/gifs/make_json.gif';
import seguranca from '~/assets/gifs/seguranca.gif';
import seguranca_fim from '~/assets/gifs/seguranca_fim.gif';
import cadastro_sti from '~/assets/gifs/cadastro_stI.gif';
import credenciais from '~/assets/conta-servico.png';

import {
  Container,
  Content,
  HeaderCredentials,
  MainCredentials,
  ItemList,
  SubItemList,
  ContentScopes,
  Warning,
  BackLink,
} from './styles';

export default function Tutorials() {
  useEffect(() => {
    async function loadScript() {
      const script = document.createElement('script');

      script.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
      script.async = true;

      document.body.appendChild(script);
    }
    loadScript();
  }, []);
  return (
    <>
      <BackLink to="/register">
        <MdArrowBack color="#1a73e8" size="18" /> Voltar
      </BackLink>
      <Container>
        <Warning>
          Caso você não se sinta confortável em proceguir com o procedimento de
          criação das credencias, fique a vontade para entrar em contato com o
          nosso time de Suporte para auxiliar no processo.
        </Warning>
        <Content>
          <HeaderCredentials>
            <h1>Credenciais de acesso ST Insights</h1>
          </HeaderCredentials>
          <MainCredentials>
            <section>
              <h2>Visão Geral</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
                optio facere reprehenderit, recusandae similique reiciendis
                tempore non itaque laudantium dolorem? Ab nesciunt dolore
                explicabo voluptate, praesentium iste laborum id est inventore
                ex ipsam consequuntur labore eum facilis similique optio natus
                molestias alias. Eveniet quisquam ut quidem fugit dicta animi
                culpa quasi saepe, doloremque quae mollitia aut sapiente et quod
                ipsam nulla dolores odit. Error ex fugit commodi debitis
                repudiandae libero animi deserunt reprehenderit dolorem, ut
                architecto quos quisquam voluptas, tenetur quam obcaecati nemo
                officiis repellat incidunt, placeat provident totam.
              </p>
            </section>
            <section>
              <h2>Como criar a credencial de acesso</h2>
            </section>
            <section>
              <h3>Gerar credenciais</h3>

              <ul>
                <li>
                  <ItemList>
                    <span>1- </span>
                    <p>Acesse o console de administração do G Suite</p>
                  </ItemList>
                  <a href="https://console.cloud.google.com/" target="blank">
                    <MdLaunch color="#fff" />
                    Ir para painel de console da google
                  </a>
                </li>
                <li>
                  <ItemList>
                    <span>2- </span>
                    <p>Selecione o projeto e clique em Abrir.</p>
                  </ItemList>
                  <img src={select_project} alt="selecionar projeto" />
                </li>

                <li>
                  <ItemList>
                    <span>3- </span>
                    <p>
                      Na navegação à esquerda, clique em "IAM e Admin `{'>'}`
                      Contas de serviço".
                    </p>
                  </ItemList>
                  <img src={acesso_conta} alt="acesso conta serviço" />
                </li>
                <li>
                  <ItemList>
                    <span>4- </span>
                    <p>
                      Crie uma conta de serviço seguindo as instruções abaixo.
                    </p>
                  </ItemList>
                  <ul>
                    <li>
                      <SubItemList>
                        <span>1- </span>
                        <p>
                          Crie uma nova conta serviço clicando no botão "+ CRIAR
                          CONTA DE SERVIÇO"
                        </p>
                      </SubItemList>
                    </li>
                    <li>
                      <SubItemList>
                        <span>2- </span>
                        <p>
                          Dê um nome e uma descrição para sua conta de serviço e
                          aperto no botão "CRIAR". O segundo passo, "Conceda
                          acesso para a conta serviço ao projeto", pode ser
                          ignorada, apenas clique no botão "CONTINUAR"
                        </p>
                      </SubItemList>
                      <img
                        src={create_service_acount}
                        alt="criando conta serviço"
                      />
                    </li>
                    <li>
                      <SubItemList>
                        <span>3- </span>
                        <p>
                          No terceiro passo da criação da conta serviço,você
                          pode gerar as credenciais de acesso clicando no botão
                          "CRIAR CHAVE". Na aba a direita verifique se a opção
                          "JSON" está selecionada e clique em "CRIAR" e depois
                          em "PRONTO"
                        </p>
                      </SubItemList>
                      <img src={make_json} alt="criando credenciais" />
                    </li>
                    <li>
                      <SubItemList>
                        <span>4- </span>
                        <p>
                          Para finalizar a configuração em seu painel GCP, vá
                          até o campo de busca na barra superior em busque por
                          "Admin SDK", isso ativará as apis necessárias para
                          resgatar os dados de adoção G Suite. Caso ainda não
                          esteja ativada, clique no botão "ATIVAR API"
                        </p>
                      </SubItemList>
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
            <section>
              <h3>Delegar autoridade de acesso</h3>
              <ul>
                <li>
                  <ItemList>
                    <span>1- </span>
                    <p>Acesse o painel de administração do G Suite</p>
                  </ItemList>
                  <a href="https://admin.google.com/ac/home" target="blank">
                    <MdLaunch color="#fff" />
                    Ir para painel de administração do G Suite
                  </a>
                </li>

                <li>
                  <ItemList>
                    <span>2- </span>
                    <p>
                      Clique na opção de Segurança e encontre a opção
                      "configurações avançadas"
                    </p>
                  </ItemList>
                  <img src={seguranca} alt="opção de segurança" />
                </li>
                <li>
                  <ItemList>
                    <span>3- </span>
                    <p>
                      No painel de configurações avançadas siga as instruções
                      abaixo.
                    </p>
                  </ItemList>
                  <ul>
                    <li>
                      <SubItemList>
                        <span>1- </span>
                        <p>
                          Abra o arquivo de credencial criado no tópico "Gerar
                          credenciais" e copie o valor do ítem "client_id", como
                          mostrado na figura abaixo
                        </p>
                      </SubItemList>
                      <img src={credenciais} alt="arquivo json credenciais" />
                    </li>
                    <li>
                      <SubItemList>
                        <span>2- </span>
                        <p>
                          Copie os links abaixo e cole no campo "Um ou mais
                          escopos de API" no painel de adminstração
                        </p>
                      </SubItemList>

                      <ContentScopes>
                        https://www.googleapis.com/auth/admin.directory.domain.readonly,
                        https://www.googleapis.com/auth/admin.directory.orgunit.readonly,
                        https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly,
                        https://www.googleapis.com/auth/admin.directory.user.readonly,
                        https://www.googleapis.com/auth/admin.reports.audit.readonly,
                        https://www.googleapis.com/auth/admin.reports.usage.readonly,
                        https://www.googleapis.com/auth/admin.directory.customer.readonly,
                        https://www.googleapis.com/auth/admin.directory.orgunit.readonly
                      </ContentScopes>
                      <br />
                      <img src={seguranca_fim} alt="opção de segurança" />
                    </li>
                  </ul>
                </li>
                <li>
                  <ItemList>
                    <span>4- </span>
                    <p>Tudo pronto para realizar o cadastro no ST Insights</p>
                  </ItemList>
                  <img src={cadastro_sti} alt="opção de segurança" />
                </li>
              </ul>
            </section>
          </MainCredentials>
          <picture>
            <img src={logo} alt="safetec" />
          </picture>
        </Content>
      </Container>
    </>
  );
}
