import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`;
export const Wraper = styled.div`
  position: fixed;
  top: 0;
  z-index: 20000;
  width: 85%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 50px;
  position: relative;
`;
export const ModalCard = styled.div`
  z-index: 100;
  background: white;
  position: absolute;
  border-radius: 3px;
  max-width: 100%;
  max-height: 100%;
  bottom: 0px;
  top: 0;
  overflow-y: auto;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
  margin-bottom: 10px;
  padding: 10px 50px;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);

  img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background: #999;
    -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
    -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
    box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  }
  span {
    margin-left: 20px;
    p {
      color: #495057;
      font-size: 1.7em;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
  }
  button {
    background: none;
    color: #f75a48;
    border: 1px solid #f75a48;
    border-radius: 4px;
  }
`;
export const ContainerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  div.charts {
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    justify-content: space-between;
    width: 100%;
  }
`;
export const CircularDiv = styled.div`
  margin-top: 50px;
  min-height: 185px;
  .MuiCircularProgress-root {
    width: 70px !important;
    height: 70px !important;
  }
  p {
    color: #333;
    margin-top: 30px;
  }
  svg {
    color: #999;
    width: 70px !important;
    height: 70px !important;
  }
`;
