import styled from 'styled-components';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  grid-area: CustomerStates;
  position: relative;
  border-radius: 3px;
  font-size: 11px;
  width: 100%;
  max-width: 100%;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  margin: 10px 0px;
  background-color: #fff;
  min-height: 246px;
  hr {
    margin: 0px !important;
  }

  @media ${device.laptopL} {
    margin-bottom: 0px;
  }
`;

export const Inative = styled.div`
  display: flex;
`;
export const CardState = styled.div`
  justify-content: flex-end;
  span.legenda {
    align-items: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: -8px;
    color: #999;
    font-weight: bolder;
    /* padding-top: 20px; */
    padding-right: 30px;
    /* width: 30%; */

    width: 100%;
    justify-content: flex-end;
    /* justify-content: space-between; */
  }
`;
export const ClientInfoCollumn = styled.div`
  min-width: 50% !important;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 4px;

  @media ${device.laptopL} {
    margin-top: 0px;
  }

  .autenticados {
    margin-top: 29px;
    min-width: 100%;

    @media ${device.laptopL} {
      margin-top: 0px;
      min-width: ${(props) => (props.haveBasic ? '50%' : '25%')};
    }
  }

  .inativas {
    @media ${device.laptopL} {
      min-width: ${(props) => (props.haveBasic ? '35%' : '30%')};
    }
  }
`;
export const FooterInfo = styled.p`
  font-size: 11px;
  font-style: italic;
  text-align: left;
  padding: 10px 0px;
`;

export const Card = styled.div`
  flex: 1 2 200px;
  min-width: 45%;
  min-height: 143px;
  padding: 0 20px;

  .progress {
    height: 0.8rem;
  }
  @media ${device.laptopL} {
    flex: 1 2 180px;
    padding: 0 10px;
  }
  @media ${device.laptopM} {
    flex: 1 2 200px;
    padding: 0 10px;
  }
`;
export const HeaderChart = styled.header`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 20px 30px;
  color: #999;
  font-weight: bolder;
  text-align: left;
  justify-content: space-between;
  height: 81px;
  svg {
    color: #f8625c;
  }

  div.title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    span {
      p {
        margin-left: 20px;
        padding: 0;
        line-height: 1;
        font-size: 1.5em !important;
        color: #333;
        font-weight: normal !important;
      }
      small {
        margin-left: 20px;
        color: #f8625c;
        font-size: 1em;
      }
    }
  }

  @media ${device.laptopL} {
    padding: 20px 30px;
  }
`;
export const Disabled = styled.div`
  display: ${(props) => (props.disabled ? 'inherit' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
`;
export const HeaderClientInfo = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  div {
    text-align: left;
    p {
      margin-bottom: 0px;
      font-size: 1rem;
      color: #495057;
    }
    small {
      font-size: 0.9rem;
      color: #999;
    }
  }

  span.tip {
    text-decoration: none;
  }
  span.tip:hover {
    cursor: help;
    position: relative;
  }
  span.tip span {
    display: none;
  }
  span.tip:hover span {
    border: #c0c0c0 1px dotted;
    padding: 10px 20px 10px 10px;
    display: block;
    z-index: 100;
    margin: 10px;
    width: 350px;
    position: absolute;
    top: 15px;
    left: -122px;
    text-decoration: none;
    font-size: 14px;
    color: #444;
    background-color: #eee;
    border-radius: 5px;
    box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  }
`;
export const Total = styled.div`
  h3 {
    font-size: 1.1rem;
    color: #999;
  }
`;
export const ItemFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 0 0px;
  div {
    text-align: left;
    p {
      margin-bottom: 0px;
      font-size: 0.8rem;
      color: #495057;
    }
    small {
      font-weight: bolder;
      font-size: 0.8rem;
      color: #999 !important;
    }
  }
  span.tip {
    text-decoration: none;
  }
  span.tip:hover {
    cursor: help;
    position: relative;
  }
  span.tip span {
    display: none;
  }
  span.tip:hover span {
    border: #c0c0c0 1px dotted;
    padding: 10px 20px 10px 10px;
    display: block;
    z-index: 100;
    margin: 10px;
    width: 350px;
    position: absolute;
    top: -120px;
    left: -132px;
    text-decoration: none;
    font-size: 14px;
    color: #444;
    background-color: #eee;
    border-radius: 5px;
    box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  }
`;
export const Footer = styled.footer``;
export const Percent = styled.div`
  color: #495057;
  font-size: 1rem;
`;
export const Table = styled.table`
  width: calc(100% + 10px);
  position: relative;
  left: -10px;
  -webkit-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  thead {
    tr {
      text-align: left;
      background: #fff;
      border: none;
      th {
        text-align: left !important;
        padding: 10px;
        font-size: 10px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
      th[colspan='3'] {
        text-align: center !important;
        border: 1px solid #ccc;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:hover {
        background-color: #e0f3ff;
      }
      td {
        padding: 4px 10px;
        font-size: 11px;

        text-align: left;
      }
    }
  }
`;

export const ListButton = styled.button`
  background: #fff;
  /* border: none; */
  border-width: 1px;
  border-color: #f8625c;
  border-radius: 4px;
  color: #f8625c;
  font-weight: bold;
  padding: 2px 5px;
  margin: -2px auto;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &:hover {
    box-shadow: 0 0 3px #f8625c;
    transition: box-shadow 0.3s;
  }

  &:disabled,
  &[disabled] {
    background: #fff;
    border: none;
    color: #666666;
    font-weight: bold;
    padding: 5px;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const InfoBasic = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #eee;
  margin-top: 15px;
  border-radius: 3px;
  color: #555;
`;

export const LoadingArea = styled.div`
  height: calc(99% - 81px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
