import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import Skeleton from '@material-ui/lab/Skeleton';
import ShareItens from './ShareItens';
import EditDocs from './EditDocs';
import DocsDrive from './DocsDrive';
import EmailSent from './EmailSent';
import MeetUsage from './MeetUsage';
import FileUploads from './FileUploads';
import avatar from '~/assets/sem-imagem-avatar.png';
import api from '~/services/api';
import {
  Overlay,
  ModalCard,
  Wraper,
  Container,
  UserInfo,
  ContainerItem,
} from './styles';

const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function Modal({
  isShowing,
  hide,
  profile_id,
  period,
  userTotals,
}) {
  const client_id = useSelector((state) => state.Client.currentUserClient);
  const domain = useSelector((state) => state.Client.currentUserClientDomain);
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  //
  useEffect(() => {
    async function loadUserDetails() {
      if (client_id && profile_id) {
        setLoading(true);
        const response = await api.get(
          `users/people/ranking/${client_id}/${profile_id}`,
          {
            params: {
              domain,
              period,
            },
          }
        );
        setUserDetails(response.data);
        setLoading(false);
      }
    }
    loadUserDetails();
  }, [client_id, domain, profile_id, period]);
  return isShowing ? (
    <>
      <Overlay />
      <Wraper>
        <ModalCard>
          <UserInfo>
            <div className="user-info">
              {loading ? (
                <>
                  <Skeleton
                    animation="wave"
                    variant="circle"
                    width={60}
                    height={60}
                  />
                  <span>
                    <Skeleton
                      animation="wave"
                      height={40}
                      width="200px"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="200px"
                      style={{ marginBottom: 6 }}
                    />
                  </span>
                </>
              ) : (
                <>
                  <img
                    src={
                      userDetails && userDetails.user.thumbnail
                        ? userDetails.user.thumbnail
                        : avatar
                    }
                    alt=""
                  />

                  <span>
                    <p>{userDetails ? userDetails.user.name : ''}</p>
                    <small>{userDetails ? userDetails.user.email : ''}</small>
                  </span>
                </>
              )}
            </div>
            <button type="button" onClick={hide}>
              <MdClose color="#f75a48" size={30} />
            </button>
          </UserInfo>

          <Container>
            <ContainerItem>
              <div className="charts main">
                {loading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height="200px"
                      width="500px"
                      style={{ marginBottom: 20, marginTop: 20 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height="200px"
                      width="500px"
                      style={{ marginBottom: 20, marginTop: 20 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height="200px"
                      width="500px"
                      style={{ marginTop: 40 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height="200px"
                      width="500px"
                      style={{ marginTop: 40 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height="200px"
                      width="500px"
                      style={{ marginTop: 40 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height="200px"
                      width="500px"
                      style={{ marginTop: 40 }}
                    />
                  </>
                ) : (
                  <>
                    <EditDocs
                      data={userDetails ? userDetails.editDocs : []}
                      period={period}
                      monthNames={monthNames}
                      total={userTotals.docs_edited}
                    />
                    <ShareItens
                      data={userDetails ? userDetails.shareDocs : []}
                      period={period}
                      monthNames={monthNames}
                      total={userTotals.shared_externally}
                    />
                    <DocsDrive
                      data={userDetails ? userDetails.docsNativos : []}
                      period={period}
                      monthNames={monthNames}
                      total={userTotals.natives_created}
                    />
                    <EmailSent
                      data={userDetails ? userDetails.emailSent : []}
                      period={period}
                      monthNames={monthNames}
                      total={userTotals.emails_sent}
                    />
                    <MeetUsage
                      data={userDetails ? userDetails.meetDuration : []}
                      period={period}
                      monthNames={monthNames}
                      total={userTotals.total_meet_duration}
                    />
                    <FileUploads
                      data={userDetails ? userDetails.fileUploads : []}
                      period={period}
                      monthNames={monthNames}
                      total={userTotals.files_upload}
                    />
                  </>
                )}
              </div>
            </ContainerItem>
          </Container>

          {/* <CircularDiv>
              <CircularProgress />
              <p>Buscando insights...</p>
              <p />
            </CircularDiv> */}
        </ModalCard>
      </Wraper>
    </>
  ) : null;
}

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  profile_id: PropTypes.string,
  period: PropTypes.string.isRequired,
  userTotals: PropTypes.arrayOf(
    PropTypes.shape({
      files_upload: PropTypes.number,
      total_meet_duration: PropTypes.number,
      emails_sent: PropTypes.number,
      natives_created: PropTypes.number,
      shared_externally: PropTypes.number,
      docs_edited: PropTypes.number,
    })
  ),
};
Modal.defaultProps = {
  profile_id: '',
  userTotals: [],
};
