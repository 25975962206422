import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { signUpSuccess } from '~/store/modules/Auth/actions';
import logo from '~/assets/logo_branca.png';
import {
  Configuration,
  Info,
  Integration,
} from '~/components/User/SignUp/Form';
import {
  Container,
  BoxRegister,
  ProfileContent,
  Header,
  ContainerForms,
} from './styles';
import api from '~/services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Configurar acesso', 'Preencher cadastro', 'Carregar indicadores'];
}

export default function SignUp() {
  const tokenJWT = useSelector((state) => state.Auth.token);

  if (tokenJWT) {
    api.defaults.headers.common.Authorization = `Bearer ${tokenJWT}`;
  }

  const classes = useStyles();
  const registerTime = useSelector((state) => state.Auth.registerTime);
  const [activeStep, setActiveStep] = useState(registerTime ? 2 : 0);
  const [clientData, setClientData] = useState({});
  // const registerDone = useSelector(state => state.Client.registerDone);
  const [registerDone, setRegisterDone] = useState(false);
  const steps = getSteps();
  const [loading, setLoading] = useState(false);
  const requester = useSelector((state) => state.Auth.requester);
  const dispatch = useDispatch();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function submitFromOutside(data) {
    setClientData(data);
    handleNext();
  }
  async function getLastData(response) {
    setLoading(true);
    setRegisterDone(false);
    // dispatch(setRegisterDone(false));
    if (response.profileObj.email !== requester.email) {
      toast.error(
        'Tente se cadastrar com a conta que você iniciou o processo.'
      );
      setLoading(false);
    } else {
      await api
        .post('client/register', {
          ...clientData,
          emailadmin: response.profileObj.email,
        })
        .then(() => {
          setLoading(false);
          toast.success('Registro concluído', { autoClose: 5000 });
          setRegisterDone(true);
          dispatch(signUpSuccess());
        })
        .catch(() => {
          setLoading(false);
          toast.error('Registro falhou', { autoClose: 5000 });
          setRegisterDone(false);
        });
    }
  }
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Configuration />;
      case 1:
        return <Info submitFromOutside={submitFromOutside} />;

      default:
        return 'Unknown step';
    }
  }

  const testAuthorization = async () => {
    setLoading(true);
    api
      .post('/tasks/validate', {
        emailadmin: requester.email,
      })
      .then(() => {
        setLoading(false);
        toast.success('Autorização concluída');
        handleNext();
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          console.tron.log(err);
        }
        setLoading(false);
        toast.error(
          'Pode levar algum tempo para sua autorização ser validada. Tente novamente'
        );
      });
  };
  return (
    <>
      <Container>
        <Header>
          <div className="logo">
            <img src={logo} alt="Safetec" />
          </div>
          <div className="user">
            <ProfileContent>
              <img src={(requester && requester.thumbnail) || logo} alt="" />
              <span>
                <p>{(requester && requester.email) || ''}</p>
              </span>
            </ProfileContent>
          </div>
        </Header>

        <BoxRegister>
          <div className={classes.root}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {activeStep === steps.length - 1 ? (
                <div>
                  <ContainerForms>
                    <Integration
                      getLastData={getLastData}
                      registerDone={registerDone}
                      isLoading={loading}
                    />
                  </ContainerForms>
                  <div className="container-steps">
                    <div className="loading">
                      {loading ? (
                        <div className="final-buttom">
                          <CircularProgress style={{ color: 'blue' }} />
                          <p>Carregando insights</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <ContainerForms>{getStepContent(activeStep)}</ContainerForms>
                  <div className="container-steps">
                    <div className="button-steps">
                      {activeStep === steps.length - 2 ? (
                        ''
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={testAuthorization}
                          className={classes.button}
                        >
                          {loading ? <CircularProgress /> : 'Avançar'}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </BoxRegister>
      </Container>
    </>
  );
}
