import { all, takeLatest, call, select } from 'redux-saga/effects';
import api from '~/services/api';

export function* registerAccessLog({ payload }) {
  try {
    // exemplo
    const { profile } = yield select((state) => state.User);
    const { page } = payload;
    const params = {
      page,
      email: profile.email,
      clientId: profile.client_id.name,
      name: profile.name,
    };

    yield call(api.post, '/users/logs/access', params);
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.tron.log(err);
    }
  }
}

export default all([
  takeLatest('@user/REGISTER_ACCESS_LOG', registerAccessLog),
]);
