import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { signOut } from '~/store/modules/Auth/actions';
import history from '~/services/history';

function* expireSession() {
  toast.warn('Sua sessão expirou. Atualize a página e logue novamente.', {
    autoClose: 5000,
  });
  yield put(signOut());
  history.push('/');
}

export default expireSession;
