import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Container = styled.div`
  main {
    width: 100%;
  }
`;
