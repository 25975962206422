import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch as Chave,
} from 'react-router-dom';
import { Container, SubMenu } from './styles';
import UserSettingsAccount from '~/components/User/Settings/Account';
import InsightsSettingsCustom from '~/components/Insights/Settings/Custom';
import api from '~/services/api';

export default function Profile() {
  const tokenJWT = useSelector((state) => state.Auth.token);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (tokenJWT) {
    api.defaults.headers.common.Authorization = `Bearer ${tokenJWT}`;
  }
  return (
    <>
      <Container>
        <Router>
          <SubMenu>
            <table>
              <thead>
                <tr>
                  <th>Configurações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Link to="/administracao/config">Conta</Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link to="/insights/config">Insights</Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </SubMenu>

          <Chave>
            <Route exact path="/administracao/config">
              <Account />
            </Route>
            <Route path="/insights/config">
              <Insights />
            </Route>
          </Chave>
        </Router>
      </Container>
    </>
  );
}

function Account() {
  return <UserSettingsAccount />;
}

function Insights() {
  return <InsightsSettingsCustom />;
}
