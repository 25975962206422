import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdInfo, MdAccountBox } from 'react-icons/md';
import ProgressBar from 'react-bootstrap/ProgressBar';
import CircularProgress from '@material-ui/core/CircularProgress';

import UsersModal from '~/components/Dashboard/Organization/Modal/Users2sv';
import UsersSuspendedModal from '~/components/Dashboard/Organization/Modal/UsersSuspended';
import {
  Container,
  CardState,
  Total,
  Footer,
  Percent,
  ItemFooter,
  ClientInfoCollumn,
  Card,
  Table,
  HeaderChart,
  HeaderClientInfo,
  ListButton,
  LoadingArea,
} from './styles';

export default function CustomerStates() {
  const [isShowing, setIsShowing] = useState(false);
  const [isShowingSuspended, setIsShowingSuspended] = useState(false);
  const { data, loading } = useSelector(
    (state) => state.ClientData.clientStatesData
  );

  const calcTotalQuota = useCallback(
    (quota_total = 0, licenses_total = 0, sku_name = '') => {
      let result = 'Ilimitado';
      if (sku_name.includes('Basic') || sku_name.includes('Starter')) {
        if (quota_total < 1024) {
          result = `${quota_total}\u00a0MB`;
        } else if (quota_total / 1024 >= 1 && quota_total / 1024 < 1024) {
          result = `${(quota_total / 1024).toFixed(2)}\u00a0GB`;
        } else {
          result = `${(quota_total / 1024 / 1024).toFixed(2)}\u00a0TB`;
        }
      } else if (sku_name === 'Google Workspace Enterprise Essentials') {
        result = `${licenses_total}\u00a0TB`;
      } else if (sku_name === 'Google Workspace Business Standard') {
        result = `${licenses_total * 2}\u00a0TB`;
      } else if (sku_name === 'Google Workspace Business Plus') {
        result = `${licenses_total * 5}\u00a0TB`;
      }

      return result;
    },
    []
  );

  const calcUsedQuotaAmount = useCallback((quota_total = 0) => {
    let result = '';
    if (quota_total < 1024) {
      result = `${quota_total}\u00a0MB`;
    } else if (quota_total / 1024 >= 1 && quota_total / 1024 < 1024) {
      result = `${(quota_total / 1024).toFixed(2)}\u00a0GB`;
    } else {
      result = `${(quota_total / 1024 / 1024).toFixed(2)}\u00a0TB`;
    }
    return result;
  }, []);

  const calcUsedQuotaPercentage = useCallback(
    (
      used_quota_in_mb = 0,
      total_quota_in_mb = 1,
      sku_name = '',
      licenses_total = 0
    ) => {
      const licensesToCalcWithTotal = [
        'G Suite Basic',
        'Google Workspace Business Starter',
      ];

      const licensesToCalcWithoutTotal = [
        'Google Workspace Enterprise Essentials',
        'Google Workspace Business Standard',
        'Google Workspace Business Plus',
      ];

      let result = 'N/A';

      if (licensesToCalcWithTotal.includes(sku_name) && used_quota_in_mb > 0) {
        result = `${((used_quota_in_mb / total_quota_in_mb) * 100).toFixed(
          1
        )}\u00a0%`;
      } else if (
        licensesToCalcWithoutTotal.includes(sku_name) &&
        used_quota_in_mb > 0
      ) {
        const convertTBToMB = (value) => value * 1024 * 1024;
        let multiplier = 1;

        if (sku_name === 'Google Workspace Business Standard') {
          multiplier = 2;
        } else if (sku_name === 'Google Workspace Business Plus') {
          multiplier = 5;
        }

        result = `${(
          (used_quota_in_mb / convertTBToMB(licenses_total * multiplier)) *
          100
        ).toFixed(1)}\u00a0%`;
      }

      return result;
    },
    []
  );

  const showModalSuspended = () => setIsShowingSuspended(true);
  const closeModalSuspended = () => setIsShowingSuspended(false);

  function showModal() {
    setIsShowing(true);
  }

  function closeModal() {
    setIsShowing(false);
  }

  return (
    <>
      <Container haveBasic={data.basic_total + data.starter_total > 0}>
        <HeaderChart>
          <div className="title">
            <MdAccountBox size="24" />
            <span>
              <p>Status das contas</p>
            </span>
          </div>
        </HeaderChart>
        <hr />
        {!loading ? (
          <>
            <CardState>
              <ClientInfoCollumn
                haveBasic={data.basic_total + data.starter_total > 0}
              >
                <Card>
                  <HeaderClientInfo>
                    <div>
                      <p>Licenças e Armazenamento</p>
                    </div>
                  </HeaderClientInfo>
                  <div style={{ overflowY: 'auto' }}>
                    <Table>
                      <thead>
                        <tr>
                          <th> </th>
                          <th colSpan="3">Quantidade</th>
                          <th colSpan="3">Armazenamento</th>
                        </tr>
                        <tr>
                          <th>Tipo licença</th>
                          <th>Total</th>
                          <th>Alocada</th>
                          <th>Sem uso</th>
                          <th>Total</th>
                          <th>Em uso</th>
                          <th>%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.licenses &&
                          data.licenses.length > 0 &&
                          data.licenses.map((license) => (
                            <tr key={license.sku_name}>
                              <td>{license.sku_name}</td>
                              <td>{license.licenses_total}</td>
                              <td>{license.licenses_used}</td>
                              <td>
                                {license.licenses_total -
                                  license.licenses_used || 0}
                              </td>
                              <td>
                                {calcTotalQuota(
                                  license.total_quota_in_mb,
                                  license.licenses_total,
                                  license.sku_name
                                )}
                              </td>
                              <td>
                                {calcUsedQuotaAmount(license.used_quota_in_mb)}
                              </td>
                              <td>
                                {calcUsedQuotaPercentage(
                                  license.used_quota_in_mb,
                                  license.total_quota_in_mb,
                                  license.sku_name,
                                  license.licenses_total
                                )}
                                {/* &nbsp;% */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <Footer />
                </Card>
                <Card className="autenticados">
                  <HeaderClientInfo>
                    <div>
                      <p>Usuários sem dupla autenticação</p>
                    </div>
                    <div>
                      <ListButton
                        onClick={showModal}
                        disabled={
                          data &&
                          data.num_users - data.num_users_2sv_enrolled === 0
                        }
                      >
                        Listar
                      </ListButton>
                    </div>
                  </HeaderClientInfo>
                  <ItemFooter>
                    <div>
                      <small>Total de usuários</small>
                    </div>
                    <Total>
                      <h3>
                        {data.length === undefined
                          ? `${data.num_users - data.num_users_2sv_enrolled}/${
                              data.num_users
                            }`
                          : `0/0`}
                      </h3>
                    </Total>
                  </ItemFooter>
                  <ProgressBar
                    variant="danger"
                    now={data ? data.num_users_no_2sv_enrolled_percent : 0}
                  />
                  <Footer>
                    <ItemFooter>
                      <div />
                      <Percent>
                        {data ? data.num_users_no_2sv_enrolled_percent : 0}%
                      </Percent>
                    </ItemFooter>
                  </Footer>
                </Card>
                <Card className="inativas">
                  <HeaderClientInfo>
                    <div>
                      <p>Licenças Inativas e de Arquivamento</p>
                    </div>
                    <div>
                      <ListButton
                        onClick={showModalSuspended}
                        disabled={
                          data &&
                          data.suspended_users === 0 &&
                          data.archived_users === 0
                        }
                      >
                        Listar
                      </ListButton>
                    </div>
                  </HeaderClientInfo>
                  <Footer>
                    <ItemFooter>
                      <div>
                        <small>Licenças suspensas</small>
                      </div>
                      <Total>
                        <h3>{data ? data.suspended_users : 0}</h3>
                      </Total>
                    </ItemFooter>
                    <ItemFooter>
                      <div>
                        <small>Licenças sem uso</small>
                      </div>
                      <Total>
                        <h3>
                          {data ? data.total_licenses - data.num_users : 0}
                        </h3>
                      </Total>
                    </ItemFooter>
                    <ItemFooter>
                      <div>
                        <small>
                          Licenças arquivadas{' '}
                          <span className="tip">
                            <MdInfo color="#333" size={16} />
                            <span>
                              Contas arquivadas é um novo recurso do Google
                              Workspace que oferece a possibilidade de manter os
                              arquivos e emails dos usuários de sua organização,
                              sem precisar que os mesmos tenham uma licença
                              Google Workspace atribuída a eles
                            </span>
                          </span>
                        </small>
                      </div>
                      <Total>
                        <h3>{data ? data.archived_users : 0}</h3>
                      </Total>
                    </ItemFooter>
                  </Footer>
                </Card>
                {data.basic_total + data.starter_total > 0 ? (
                  <Card>
                    <HeaderClientInfo>
                      <div>
                        <p>Cota Armazenamento Basic / Starter</p>
                      </div>
                    </HeaderClientInfo>
                    <Table>
                      <thead>
                        <tr>
                          <th>Faixa</th>
                          <th>% usuários</th>
                          <th>qtd usuários</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>até 50%</td>
                          <td>
                            {data.basic_total + data.starter_total > 0
                              ? data.num_users_50_used_percent
                              : 0}{' '}
                            %
                          </td>
                          <td>
                            {data.basic_total + data.starter_total > 0
                              ? data.total_users_50_used_percent || 0
                              : 0}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>50% - 80%</td>
                          <td>
                            {data.basic_total + data.starter_total > 0
                              ? data.num_users_50_80_used_percent
                              : 0}{' '}
                            %
                          </td>
                          <td>
                            {data.basic_total + data.starter_total > 0
                              ? data.total_users_50_80_used_percent || 0
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td>80%+</td>
                          <td>
                            {data.basic_total + data.starter_total > 0
                              ? data.num_users_80_used_percente
                              : 0}{' '}
                            %
                          </td>
                          <td>
                            {data.basic_total + data.starter_total > 0
                              ? data.total_users_80_used_percente || 0
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Footer />
                  </Card>
                ) : (
                  <></>
                )}
              </ClientInfoCollumn>
            </CardState>
          </>
        ) : (
          <LoadingArea>
            <CircularProgress style={{ color: '#f8625c' }} />
          </LoadingArea>
        )}
      </Container>
      <UsersModal isShowing={isShowing} hide={closeModal} />
      <UsersSuspendedModal
        isShowing={isShowingSuspended}
        hide={closeModalSuspended}
      />
    </>
  );
}
