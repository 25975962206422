import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { BiBarChartAlt2 } from 'react-icons/bi';
import { VscFilePdf } from 'react-icons/vsc';

import { toast } from 'react-toastify';
import { toPdf } from '~/utils/downloadPDF';

import DashboardOrganizationChartAppUsage from '~/components/Dashboard/Organization/Chart/AppUsage';
import DashboardOrganizationChartSharedDrives from '~/components/Dashboard/Organization/Chart/SharedDrives';
import DashboardOrganizationChartAppUsageDetails from '~/components/Dashboard/Organization/Chart/AppUsageDetails';
import DashboardOrganizationChartCustomerStates from '~/components/Dashboard/Organization/Chart/CustomerStates';
import DashboardOrganizationChartCreateApps from '~/components/Dashboard/Organization/Chart/CreateApps';
import DashboardOrganizationChartEditorDrive from '~/components/Dashboard/Organization/Chart/EditorDrive';
import {
  getCustomerStatesDataRequest,
  getEditorDriveDataRequest,
} from '~/store/modules/ClientData/actions';
import { getClientListRequest } from '~/store/modules/ClientList/actions';
import { registerAccessLog } from '~/store/modules/User/actions';
import {
  Container,
  DashboardTitleContainer,
  DashboardTitleText,
  PdfButton,
  TitleContent,
} from './styles';
import api from '~/services/api';

import { signOut } from '~/store/modules/Auth/actions';
import history from '~/services/history';

export default function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tokenJWT = useSelector((state) => state.Auth.token);

  const loginTime = useSelector((state) => state.Auth.loginTime);

  const moreThan24h = (new Date() - new Date(loginTime)) / 36e5 > 24;

  if (moreThan24h || !loginTime) {
    dispatch(signOut());
    history.push('/');
  }

  const { currentOrgUnits } = useSelector((state) => state.Client);

  if (!currentOrgUnits) {
    toast.warn('Sua sessão expirou. Logue novamente.', { autoClose: 5000 });
    dispatch(signOut());
    history.push('/');
  }

  if (tokenJWT) {
    api.defaults.headers.common.Authorization = `Bearer ${tokenJWT}`;
  }

  const profile = useSelector((state) => state.User.profile);
  const currentCustomer = useSelector(
    (state) => state.Client.currentUserClient
  );
  const currentDomain = useSelector(
    (state) => state.Client.currentUserClientDomain
  );

  // armazenar logs de acesso
  useEffect(() => {
    dispatch(registerAccessLog('Organização'));
  });

  useEffect(() => {
    async function loadData() {
      if (profile) {
        const customer_id = currentCustomer || profile.client_id.name;

        dispatch(getCustomerStatesDataRequest(customer_id));
        dispatch(getEditorDriveDataRequest(customer_id));
        dispatch(getClientListRequest(profile.client_id.name));
      }
    }
    loadData();
  }, [currentCustomer, dispatch, profile]);

  let disableDonwload = false;

  function downloadPdf() {
    if (!disableDonwload) {
      disableDonwload = true;

      const domain = currentDomain
        ? currentDomain.split('@')[1]
        : `${profile.email.split('@')[1]}`;

      toPdf(
        document.getElementById('pageOrganizacao'),
        profile,
        domain,
        'sti-pagina-organizacao'
      );
      disableDonwload = false;
    }
  }

  return (
    <>
      <Container id="pageOrganizacao">
        <DashboardTitleContainer>
          <TitleContent>
            <BiBarChartAlt2 size="50" color="#fff" />
            <DashboardTitleText>Organização</DashboardTitleText>
          </TitleContent>
          <PdfButton disable={disableDonwload}>
            <VscFilePdf size="30" color="#fff" onClick={downloadPdf} />
          </PdfButton>
        </DashboardTitleContainer>
        <DashboardOrganizationChartCustomerStates />
        <DashboardOrganizationChartAppUsageDetails />
        <DashboardOrganizationChartCreateApps />
        <DashboardOrganizationChartEditorDrive />
        <DashboardOrganizationChartAppUsage />
        <DashboardOrganizationChartSharedDrives />
      </Container>
    </>
  );
}
