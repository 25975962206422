import { all, put, takeLatest, call } from 'redux-saga/effects';
import { insightsSuccess } from './actions';
import api from '~/services/api';

export function* getInsights({ payload }) {
  try {
    const { customer_id } = payload;
    const response = yield call(api.get, '/insights', {
      params: { customer_id },
    });
    yield put(insightsSuccess(response.data));
  } catch (err) {
    // yield expireSession();
  }
}
export default all([takeLatest('@insights/INSIGHTS_REQUEST', getInsights)]);
