import React from 'react';
import PropTypes from 'prop-types';
import default_icon from '~/assets/sem-imagem-avatar.png';
import { Container } from './styles';

export default function Avatar({ data }) {
  return (
    <Container leftSide={data.total_trashed}>
      <img
        src={data.thumbnail || default_icon}
        alt={`imagem do usuário ${data.name}`}
        draggable="false"
      />
      <span className="badge">
        {data.total_user}
        {data.total_trashed}
        {data.total_downloaded}
        {data.total_shared}
      </span>
      <div className="tooltipavatar">
        <span className="tooltiptext">
          <span>{data.name}</span>

          <div>
            <p>
              <span>Email:</span> {data.email}
            </p>
          </div>
          <div>
            {data.user_share_internally >= 0 &&
              data.user_share_link >= 0 &&
              data.user_share_domian >= 0 && (
                <>
                  <p>
                    <span>Comp Interno:</span> {data.user_share_internally}
                  </p>
                  <p>
                    <span>Comp. com link:</span> {data.user_share_link}
                  </p>
                  <p>
                    <span>Comp dentro do domínio:</span>{' '}
                    {data.user_share_domian}
                  </p>
                </>
              )}

            {(data.total_trashed ||
              data.total_downloaded ||
              data.total_shared) && (
              <>
                <p>
                  <span>Data:</span> {data.date.value.split('-')[2]}/
                  {data.date.value.split('-')[1]}/
                  {data.date.value.split('-')[0]}
                </p>
              </>
            )}

            <p>
              <span>Total:</span> {data.total_user}
              {data.total_trashed}
              {data.total_downloaded}
              {data.total_shared}
            </p>
          </div>
        </span>
      </div>
    </Container>
  );
}

Avatar.propTypes = {
  data: PropTypes.shape({
    actor_email: PropTypes.string,
    date: PropTypes.shape({ value: PropTypes.string }),
    email: PropTypes.string,
    name: PropTypes.string,
    profile_id: PropTypes.string,
    thumbnail: PropTypes.string,
    user_share_domian: PropTypes.number,
    user_share_internally: PropTypes.number,
    user_share_link: PropTypes.number,
    total_shared: PropTypes.number,
    total_user: PropTypes.number,
    total_downloaded: PropTypes.number,
    total_trashed: PropTypes.number,
  }).isRequired,
};
