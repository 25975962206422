import styled from 'styled-components';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  position: relative;
  border-radius: 3px;
  font-size: 14px;
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  border-bottom: 4px solid #3ac47d;
  background-color: #fff;
  grid-area: gridProfile;
  .recharts-text.recharts-label {
    font-size: 12px;
  }
  .legenda {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-right: 7%;
    /* margin-bottom: 30px; */
  }
  hr {
    margin-top: 0;
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#f8625c' : 'white',
  }),
};

export const MultipleSelect = styled(Select).attrs({ styles: customStyles })`
  width: 100%;
`;

export const ContainerProfile = styled.div`
  padding-right: 7%;
  height: auto;
  font-size: 12px;
  min-height: 300px !important;
  div.table {
    width: 100%;
  }
`;

export const ResponsiveContainerChart = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`;
export const HeaderChart = styled.header`
  display: flex;
  align-items: center;
  padding: 11px 30px 0px;

  color: #333;
  text-align: left;
  justify-content: space-around;
  svg {
    color: #f8625c;
  }
  div.title {
    width: 65%;
    display: flex;
    padding: 10px;
    span {
      margin-left: 20px;
      p {
        padding: 0;
        line-height: 1;
        font-size: 1.5em;
      }
      small {
        color: #f8625c;
        font-size: 1em;
      }
    }
  }
  div.search {
    width: 50%;
  }

  @media ${device.mobileL} {
    > div {
      display: none !important;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.mobileM} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.tablet} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
`;
export const Legenda = styled.div`
  font-size: 12px;
  max-height: 28px;
  background: ${(props) => props.color};
  padding: 5px 15px;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  color: #fff;
  svg {
    fill: #fff;
  }
  .tooltipbox .tooltiptext {
    visibility: hidden;
    background-color: rgba(255, 255, 255, 0.9);
    color: #495057;
    border: 1px solid #333;
    border-radius: 6px;
    text-align: left;
    padding: 20px;
    position: absolute;
    z-index: 1;
    bottom: 35px;
    right: 0%;
  }

  .tooltiptext {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    width: auto;
    white-space: wrap;
    p {
      color: #495057;
      font-weight: bolder;
    }
    span {
      font-weight: bolder;
    }
  }

  &:hover .tooltipbox .tooltiptext {
    visibility: visible;
  }
`;
export const SearchUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto 20px;
  h3 {
    color: #333;
  }
  nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* input {
      width: 90%;
      margin-top: 15px;
      padding: 10px;
      border-radius: 4px;
      height: 35px;
      color: #f75a48;
      margin-bottom: 20px;
      border: none;
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
      box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -webkit-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -moz-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
    } */
    span.icon-search {
      padding: 9px;
      align-items: baseline;
    }
  }
`;

export const LoadingArea = styled.div`
  height: calc(99% - 81px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;

export const MuiButtonGroup = styled(ButtonGroup)`
  margin: auto;

  @media ${device.tablet} {
    display: none !important;
  }
`;

export const PeriodButton = styled(Button)`
  font-weight: bold !important;
  border-color: #f8625c !important;
  background-color: ${(props) =>
    props.variant === 'contained' ? '#f8625c' : '#fff'} !important;
  color: ${(props) =>
    props.variant === 'contained' ? '#fff' : '#f8625c'} !important;
`;
