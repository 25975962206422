import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MdSearch } from 'react-icons/md';
import { RiHardDrive2Line } from 'react-icons/ri';

import ChartDriveTable from './Table';
import {
  Container,
  HeaderChart,
  LoadingArea,
  MuiButtonGroup,
  PeriodButton,
  InfoIcon,
  MultipleSelect,
  SearchUser,
} from './styles';
import { getSharedDrivesDataRequest } from '~/store/modules/ClientData/actions';

export default function SharedDrivesDashboard() {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(
    (state) => state.ClientData.clientSharedDrives
  );

  const { currentUserClient } = useSelector((state) => state.Client);
  const [filter, setFilter] = useState({
    period: 'year',
  });
  const [drives, setDrives] = useState(null);

  const profile = useSelector((state) => state.User.profile);

  const headCellsArray = useMemo(
    () => [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Nome do Drive',
        show: true,
      },
      {
        id: 'viewed',
        numeric: true,
        disablePadding: false,
        label: 'Ler',
        show: true,
      },
      {
        id: 'created',
        numeric: true,
        disablePadding: false,
        label: 'Criar',
        show: true,
      },
      {
        id: 'edited',
        numeric: true,
        disablePadding: false,
        label: 'Editar',
        show: true,
      },
      {
        id: 'trashed',
        numeric: true,
        disablePadding: false,
        label: 'Excluir',
        show: true,
      },
      {
        id: 'shared',
        numeric: true,
        disablePadding: false,
        label: 'Compartilhar',
        show: true,
      },
      {
        id: 'downloaded',
        numeric: true,
        disablePadding: false,
        label: 'Download',
        show: true,
      },
      {
        id: 'uploaded',
        numeric: true,
        disablePadding: false,
        label: 'Upload',
        show: true,
      },
      {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        show: true,
      },
    ],
    []
  );

  const customer_id = useMemo(
    () => (profile ? currentUserClient || profile.client_id.name : null),
    [currentUserClient, profile]
  );

  useEffect(() => {
    dispatch(getSharedDrivesDataRequest(currentUserClient, filter));
  }, [filter, customer_id, currentUserClient, dispatch]);

  const { dataMonths } = useSelector((state) => state.Client.userClient);

  const selectOptions = useMemo(
    () =>
      data
        ? data
            .map((drive) => ({
              value: drive.name,
              label: drive.name,
            }))
            .sort((user1, user2) => (user1.label > user2.label ? 1 : -1))
        : [],
    [data]
  );

  const colorsGoogle = {
    green: '#34A853',
    red: '#EA4335',
    yellow: '#FBBC05',
    blue: '#4285F4',
  };

  return (
    <Container>
      <HeaderChart>
        <div className="title">
          <RiHardDrive2Line size="24" />
          <span>
            <p>Atividades em Drives Compartilhados </p>

            <small>
              {(filter.period === 'year' && `últimos ${dataMonths} meses`) ||
                (filter.period === 'trimester' && 'últimos 3 meses') ||
                'últimos 30 dias'}
            </small>
          </span>
          <div id="infoIcon">
            <span className="tip">
              <InfoIcon size={24} />
              <span>
                Operações realizadas com arquivos em Drives Compartilhados.
              </span>
            </span>
          </div>
        </div>

        <SearchUser className="search">
          <nav>
            <span className="icon-search">
              <MdSearch color="#333" size={20} />
            </span>
            <MultipleSelect
              isMulti
              name="data"
              options={selectOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Filtrar drives"
              noOptionsMessage={() => 'Não encontrado'}
              onChange={setDrives}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
            />
          </nav>
        </SearchUser>
        <MuiButtonGroup
          color="secondary"
          aria-label="outlined secondary button group"
        >
          <PeriodButton
            disabled={filter.period === 'month'}
            variant={filter.period === 'month' ? 'contained' : 'outlined'}
            onClick={() => {
              setFilter({ ...filter, period: 'month' });
            }}
          >
            Mês
          </PeriodButton>
          <PeriodButton
            disabled={filter.period === 'trimester'}
            variant={filter.period === 'trimester' ? 'contained' : 'outlined'}
            onClick={() => {
              setFilter({ ...filter, period: 'trimester' });
            }}
          >
            Trimestre
          </PeriodButton>
          <PeriodButton
            disabled={filter.period === 'year'}
            variant={filter.period === 'year' ? 'contained' : 'outlined'}
            onClick={() => {
              setFilter({ ...filter, period: 'year' });
            }}
          >
            Ano
          </PeriodButton>
        </MuiButtonGroup>
      </HeaderChart>
      <hr />
      {(!loading && (
        <ChartDriveTable
          valueName="score_docs_shared"
          colorTitle={colorsGoogle.green}
          drives={drives}
          headCellsArray={headCellsArray}
          period={filter.period}
          dataMonths={dataMonths}
        />
      )) || (
        <LoadingArea>
          <CircularProgress style={{ color: '#f8625c' }} />
        </LoadingArea>
      )}
    </Container>
  );
}
