import styled from 'styled-components';
import { MdInfo } from 'react-icons/md';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from 'react-select';

import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background: #fff;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  grid-area: Ranking !important;

  hr {
    padding: 0;
    margin: 0;
  }
`;

export const InfoIcon = styled(MdInfo)`
  margin-right: -13px;
  margin-left: 10px;
`;

export const LoadingArea = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;

export const MuiButtonGroup = styled(ButtonGroup)`
  margin: auto;

  @media ${device.tablet} {
    display: none !important;
  }
`;

export const PeriodButton = styled(Button)`
  font-weight: bold !important;
  border-color: #f8625c !important;
  background-color: ${(props) =>
    props.variant === 'contained' ? '#f8625c' : '#fff'} !important;
  color: ${(props) =>
    props.variant === 'contained' ? '#fff' : '#f8625c'} !important;
`;

export const Header = styled.header`
  text-shadow: 1px 2px 2px #333;
  width: 100%;
  height: 60px;
  background: #f75a48;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dashboard = styled.div`
  height: 100%;
`;
export const HeaderChart = styled.header`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 20px 30px;
  color: #999;
  font-weight: bolder;
  text-align: left;
  justify-content: space-between;
  svg {
    color: #f8625c;
  }
  div.title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    span {
      p {
        margin-left: 20px;
        padding: 0;
        line-height: 1;
        font-size: 1.5em !important;
        color: #333;
        font-weight: normal !important;
        display: flex;
      }

      small {
        margin-left: 20px;
        color: #f8625c;
        font-size: 1em;
      }
    }

    div#infoIcon {
      span.tip {
        text-decoration: none;
      }
      span.tip:hover {
        cursor: help;
        position: relative;
      }
      span.tip span {
        display: none;
      }
      span.tip:hover span {
        strong.azul {
          color: #4695e4;
        }
        z-index: 5;
        line-height: 2;
        border: #c0c0c0 1px dotted;
        padding: 10px 20px 10px 10px;
        display: block;
        /* margin-left: -136px; */
        width: 335px;
        position: absolute;
        text-decoration: none;
        font-size: 14px;
        color: #444;
        font-weight: bold;
        background-color: #eee !important;
        border-radius: 5px;
        box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
      }
    }
  }

  @media ${device.mobileL} {
    > div {
      display: none !important;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.mobileM} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.tablet} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#f8625c' : 'white',
  }),
};

export const MultipleSelect = styled(Select).attrs({ styles: customStyles })`
  width: 100%;
`;

export const SearchUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto 20px;
  font-weight: normal;

  @media ${device.laptop} {
    display: none;
  }

  h3 {
    color: #333;
  }
  nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span.icon-search {
      padding: 9px;
      align-items: baseline;
    }
  }
`;
