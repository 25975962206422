import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { darken } from 'polished';

export const CardSettings = styled.div`
  position: relative;
  width: 50%;
  margin: 0 40px;
  min-height: 200px;
  background: #fff;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
`;
export const ContainerForm = styled.div`
  padding: 0 20px 20px 20px;
  form {
    #client_id {
      display: none;
    }
    label {
      text-align: left !important;
      color: #999 !important;
      font-weight: bolder;
    }
    display: flex;
    flex-direction: column;
    input,
    select {
      padding: 10px;
      border-radius: 4px;
      height: 35px;
      color: #f75a48;
      margin-bottom: 20px;
      border: none;
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
      box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -webkit-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -moz-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
    }
    hr {
      border: 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin-bottom: 20px;
      margin-top: 10px;
    }
    button {
      height: 40px;
      margin: 5px 0 0;
      width: 30%;
      background: #f75a48;
      font-weight: bold;
      border-radius: 4px;
      font-size: 16px;
      border: none;
      color: #fff;
      transition: background 0.2s;
      &:hover {
        background-color: ${darken(0.03, '#f75a48')};
      }
    }
    span {
      color: #ee0000;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    a {
      color: #fff;
      margin-top: 10px;
      &:hover {
        color: ${darken(0.2, '#fff')};
      }
    }
  }
`;
export const HeaderSettings = styled.div`
  padding: 20px 20px 0 20px;
  color: #495057;
  h3 {
    font-size: 1.3em;
  }
`;
export const InfoAccount = styled.div`
  position: relative;
  border-radius: 4px;
  padding: 15px;
  margin: 20px;
  margin-top: 0px;
  background: #f8625c;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  h4 {
    font-size: 1.5em;
  }
  img {
    border: 4px solid white;
    border-radius: 55px;
  }
`;
export const Badge = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  top: -10px;
  right: -10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: ${(props) => (props.error ? 'red' : 'green')};
`;
export const GroupInput = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
export const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 200px;
`;
export const SearchUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    color: #333;
  }
  nav {
    width: 80%;
    display: flex;
    align-items: baseline;
    input {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      height: 35px;
      color: #f75a48;
      margin-bottom: 20px;
      border: none;
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
      box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -webkit-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -moz-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
    }
    span {
      padding: 9px;
      align-items: baseline;
    }
  }
`;

export const Loading = styled(CircularProgress)`
  color: #f8625c !important;
  width: 25px !important;
  height: 25px !important;
`;

export const ContainerUsers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;

  -webkit-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  thead {
    tr {
      width: 100%;
      th {
        padding: 10px 5px;
        text-align: left;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:hover {
        background-color: #e0f3ff;
      }
      td {
        padding: 10px 5px;

        button {
          border: none;
          font-size: 12px;
          padding: 10px 20px;
          border-radius: 3px;
          display: flex;
          svg {
            margin-right: 10px;
          }
        }
        img {
          margin-right: 10px;
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }
        &:first-child,
        &:last-child {
          text-align: center;
        }
      }
    }
  }
`;
