import React, { useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '~/services/api';
import { CardSettings, HeaderSettings, ContainerUsers, Table } from './styles';

export default function CustomInsights() {
  const client = useSelector((state) => state.Client.userClient);
  let loading = false;
  const [configs, setConfigs] = useState({
    inative_license: {
      active: false,
      trigger: 0,
      show_ever: false,
    },
    drive_usage: {
      active: false,
      trigger: 0,
    },
    calendar_usage: {
      active: false,
      trigger: 0,
    },
    meet_usage: {
      active: false,
      trigger: 0,
    },
    quota_50_80: {
      active: false,
      trigger: 0,
    },
    quota_80: {
      active: false,
      trigger: 0,
    },
    auth_2sv: {
      active: false,
      trigger: 0,
    },
    uploads_ms: {
      active: false,
      trigger: 0,
    },
    uploads_other: {
      active: false,
      trigger: 0,
    },
    doc_share_ext: {
      active: false,
      trigger: 0,
    },
    doc_share_int: {
      active: false,
      trigger: 0,
    },
    sites_gplus: {
      active: false,
      trigger: 0,
    },
    mass_operations: {
      active: false,
      trigger: 0,
    },
    num_users: 0,
  });
  useEffect(() => {
    async function loadSetings(client_id) {
      const response = await api.get(`/insights/config/${client_id}`);
      if (response.data) setConfigs(response.data);
    }
    loadSetings(client.client_id);
  }, [client.client_id]);

  function handleChange(e) {
    const { target } = e;
    const { name } = target;
    let valueSwitch = target.classList.contains('MuiSwitch-input')
      ? target.checked
      : configs[name].active;
    const valueNumber =
      target.className === 'trigger' ? target.value : configs[name].trigger;
    const valueCheck =
      target.className === 'show_ever'
        ? target.checked
        : configs[name].show_ever;

    if (valueCheck === true) valueSwitch = valueCheck;

    setConfigs({
      ...configs,
      [name]: {
        trigger: valueNumber,
        active: valueSwitch,
        show_ever: valueCheck,
      },
    });
  }
  async function handleSubmit(e) {
    e.preventDefault();
    loading = true;
    api
      .put(`/insights/config/`, {
        client_id: client.client_id,
        ...configs,
      })
      .then(() => {
        toast.success(
          'Configurações atualizadas! As Mudanças nos gatilhos passarão a valer no próximo ciclo de verificação de insights que ocorre a cada 24h.',
          { autoClose: 8000 }
        );
        loading = false;
      });
  }
  return (
    <>
      <CardSettings>
        <HeaderSettings>
          <h3>Configuração Insights</h3>
          <hr />
        </HeaderSettings>
        <ContainerUsers>
          <form onSubmit={handleSubmit}>
            <Table>
              <thead>
                <tr>
                  <th>Insight</th>
                  <th className="number">Gatilho</th>
                  <th>Ativar</th>
                  <th>Mostrar sempre</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <p>Uso do Google Drive</p>{' '}
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando a % de usuários que criaram arquivos for
                      menor que...
                    </p>
                    <input
                      type="number"
                      name="drive_usage"
                      value={configs.drive_usage && configs.drive_usage.trigger}
                      min={0}
                      max={100}
                      onChange={handleChange}
                      className="trigger"
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="drive_usage"
                          checked={
                            (configs.drive_usage &&
                              configs.drive_usage.active) ||
                            false
                          }
                          onChange={handleChange}
                          name="drive_usage"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_drive" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.drive_usage &&
                            configs.drive_usage.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="drive_usage"
                        id="checkbox_drive"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Arquivos MS Office</p>
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando a % de usuários que gravaram arquivos MS
                      Office no Google Drive for maior que…
                    </p>
                    <input
                      type="number"
                      className="trigger"
                      name="uploads_ms"
                      value={configs.uploads_ms && configs.uploads_ms.trigger}
                      min={0}
                      max={100}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="uploads_ms"
                          checked={
                            configs.uploads_ms && configs.uploads_ms.active
                          }
                          onChange={handleChange}
                          name="uploads_ms"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_ms" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.uploads_ms &&
                            configs.uploads_ms.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="uploads_ms"
                        id="checkbox_ms"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Outros arquivos</p>
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando a % de usuários que gravaram arquivos não
                      nativos (exceto MS Office) no Google Drive for maior que…
                    </p>
                    <input
                      type="number"
                      className="trigger"
                      name="uploads_other"
                      value={
                        configs.uploads_other && configs.uploads_other.trigger
                      }
                      min={0}
                      max={100}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="uploads_other"
                          checked={
                            configs.uploads_other &&
                            configs.uploads_other.active
                          }
                          onChange={handleChange}
                          name="uploads_other"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_other" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.uploads_other &&
                            configs.uploads_other.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="uploads_other"
                        id="checkbox_other"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Uso da Agenda</p>
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando a % de usuários que criaram compromissos na
                      agenda for menor que…
                    </p>
                    <input
                      type="number"
                      name="calendar_usage"
                      value={
                        configs.calendar_usage && configs.calendar_usage.trigger
                      }
                      onChange={handleChange}
                      min={0}
                      max={100}
                      className="trigger"
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="calendar_usage"
                          checked={
                            configs.calendar_usage &&
                            configs.calendar_usage.active
                          }
                          onChange={handleChange}
                          name="calendar_usage"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_calendar" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.calendar_usage &&
                            configs.calendar_usage.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="calendar_usage"
                        id="checkbox_calendar"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Uso do Google Meet</p>
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando a % de usuários que participaram de reuniões
                      pelo Meet for menor que…
                    </p>
                    <input
                      type="number"
                      className="trigger"
                      name="meet_usage"
                      value={configs.meet_usage && configs.meet_usage.trigger}
                      min={0}
                      max={100}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="meet_usage"
                          checked={
                            configs.meet_usage && configs.meet_usage.active
                          }
                          onChange={handleChange}
                          name="meet_usage"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_meet" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.meet_usage &&
                            configs.meet_usage.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="meet_usage"
                        id="checkbox_meet"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Compartilhamento externo</p>
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando a % de usuários que compartilharam arquivos
                      externamente for maior que…
                    </p>
                    <input
                      type="number"
                      className="trigger"
                      name="doc_share_ext"
                      value={
                        configs.doc_share_ext && configs.doc_share_ext.trigger
                      }
                      min={0}
                      max={100}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="doc_share_ext"
                          checked={
                            configs.doc_share_ext &&
                            configs.doc_share_ext.active
                          }
                          onChange={handleChange}
                          name="doc_share_ext"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_ext" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.doc_share_ext &&
                            configs.doc_share_ext.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="doc_share_ext"
                        id="checkbox_ext"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Compartilhamento Interno</p>
                  </td>
                  <td className="flex" />
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="doc_share_int"
                          checked={
                            configs.doc_share_int &&
                            configs.doc_share_int.active
                          }
                          onChange={handleChange}
                          name="doc_share_int"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_share" className="container">
                      <input
                        className="show_ever"
                        checked={configs.doc_share_int.show_ever || false}
                        type="checkbox"
                        onChange={handleChange}
                        name="doc_share_int"
                        id="checkbox_share"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Cota atingida 50% - 80%</p>
                  </td>
                  <td className="flex">
                    <p>
                      Verificação de atingimento de 50% a 80% da cota de
                      armazenamento da organização
                    </p>

                    <input
                      type="number"
                      className="trigger hide"
                      name="quota_50_80"
                      readOnly
                      value="0"
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="quota_50_80"
                          checked={
                            configs.quota_50_80 && configs.quota_50_80.active
                          }
                          onChange={handleChange}
                          name="quota_50_80"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_50_80" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.quota_50_80 &&
                            configs.quota_50_80.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="quota_50_80"
                        id="checkbox_50_80"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Cota atingida 80%</p>
                  </td>
                  <td className="flex">
                    <p>
                      Verificação de atingimento de 80% da cota de armazenamento
                      da organização
                    </p>
                    <input
                      type="number"
                      className="trigger hide"
                      name="quota_80"
                      readOnly
                      value="0"
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="quota_80"
                          checked={configs.quota_80 && configs.quota_80.active}
                          onChange={handleChange}
                          name="quota_80"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_80" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.quota_80 && configs.quota_80.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="quota_80"
                        id="checkbox_80"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Dupla autenticação</p>
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando a % de usuários SEM dupla autenticação for
                      maior que…
                    </p>
                    <input
                      type="number"
                      className="trigger"
                      name="auth_2sv"
                      value={configs.auth_2sv && configs.auth_2sv.trigger}
                      min={0}
                      max={100}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="auth_2sv"
                          checked={configs.auth_2sv && configs.auth_2sv.active}
                          onChange={handleChange}
                          name="auth_2sv"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_auth" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.auth_2sv && configs.auth_2sv.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="auth_2sv"
                        id="checkbox_auth"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Licenças inativas</p>
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando a % de licenças inativas (sem uso ou
                      suspensas) for maior que…
                    </p>
                    <input
                      type="number"
                      className="trigger"
                      name="inative_license"
                      value={
                        configs.inative_license &&
                        configs.inative_license.trigger
                      }
                      min={0}
                      max={100}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="inative_license"
                          checked={
                            configs.inative_license &&
                            configs.inative_license.active
                          }
                          onChange={handleChange}
                          name="inative_license"
                        />
                      }
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <label htmlFor="checkbox_lecenses" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.inative_license &&
                            configs.inative_license.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="inative_license"
                        id="checkbox_lecenses"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Criação de Sites e Comunidades </p>
                  </td>
                  <td className="flex">
                    <p>
                      Verificação de adoção das ferramentas Google Sites e
                      Google+
                    </p>
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="sites_gplus"
                          checked={
                            configs.sites_gplus && configs.sites_gplus.active
                          }
                          onChange={handleChange}
                          name="sites_gplus"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_sites" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.sites_gplus &&
                            configs.sites_gplus.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="sites_gplus"
                        id="checkbox_sites"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Ações em massa com arquivos</p>
                  </td>
                  <td className="flex">
                    <p>
                      Avisar quando qualquer usuário excluir, fazer download ou
                      compartilhar arquivos numa quantidade maior ou igual a...
                    </p>
                    <input
                      type="number"
                      className="trigger"
                      name="mass_operations"
                      value={
                        configs.mass_operations &&
                        configs.mass_operations.trigger
                      }
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          id="mass_operations"
                          checked={
                            configs.mass_operations &&
                            configs.mass_operations.active
                          }
                          onChange={handleChange}
                          name="mass_operations"
                        />
                      }
                    />
                  </td>
                  <td>
                    <label htmlFor="checkbox_operations" className="container">
                      <input
                        className="show_ever"
                        checked={
                          (configs.mass_operations &&
                            configs.mass_operations.show_ever) ||
                          false
                        }
                        type="checkbox"
                        onChange={handleChange}
                        name="mass_operations"
                        id="checkbox_operations"
                      />
                      <span className="checkmark" />
                    </label>
                  </td>
                </tr>
              </tbody>
            </Table>

            <button type="submit" className="submit">
              {loading ? <CircularProgress /> : 'Salvar'}
            </button>
          </form>
        </ContainerUsers>
      </CardSettings>
    </>
  );
}
