/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { format, parseISO } from 'date-fns';
import {
  Container,
  HeaderChart,
  CardLineChart,
  FlexContainer,
  CardContainer,
  NoData,
} from './styles';

export default function ProfileMeetUsage({ data, period, monthNames, total }) {
  return (
    <Container>
      <HeaderChart>
        <div>Histórico de duração das Meetings (Minutos)</div>
      </HeaderChart>
      <CardContainer>
        <FlexContainer>
          <CardLineChart>
            {data.length > 0 && total > 0 ? (
              <ResponsiveContainer minHeight="100%" width="100%">
                <AreaChart
                  data={data || ''}
                  margin={{
                    top: 20,
                    right: 50,
                    left: -10,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date.value"
                    interval="preserveStartEnd"
                    tickFormatter={(date) =>
                      period === 'year' && date
                        ? monthNames[parseISO(date).getMonth()].substring(0, 3)
                        : format(date ? parseISO(date) : new Date(), 'dd/MM')
                    }
                  >
                    <Label value="Data" offset={2} position="insideBottom" />
                  </XAxis>
                  <YAxis />
                  <Tooltip
                    wrapperStyle={{
                      width: '200px',
                      fontSize: 11,
                      fontWeight: 'bolder',
                    }}
                    labelFormatter={(value) => {
                      if (value) {
                        if (period === 'year') {
                          return `${
                            monthNames[parseISO(value).getMonth()]
                          } de ${parseISO(value).getFullYear()} `;
                        }
                        if (period === 'trimester') {
                          return `Semana terminada em ${format(
                            parseISO(value),
                            'dd/MM'
                          )}`;
                        }

                        return format(parseISO(value), 'dd/MM');
                      }
                      return format(new Date(), 'dd/MM');
                    }}
                  />
                  <Area
                    name="Duração das reuniões"
                    type="monotone"
                    dataKey="sum_meet_duration"
                    stroke="rgb(20, 146, 133)"
                    fill="transparent"
                    strokeWidth={2}
                    activeDot={{ r: 5 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <NoData>Sem dados</NoData>
            )}
          </CardLineChart>
        </FlexContainer>
      </CardContainer>
    </Container>
  );
}

ProfileMeetUsage.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      sum_meet_duration: PropTypes.number,
      date: PropTypes.string,
      dia: PropTypes.number,
      mes: PropTypes.number,
      semana: PropTypes.number,
    })
  ).isRequired,
  period: PropTypes.string.isRequired,
  monthNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  total: PropTypes.number.isRequired,
};
