import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 267px;
  margin: 20px auto 10px auto;
  .loginButton {
    div,
    svg {
      padding: 0 !important;
    }
  }
  div.formRow {
    width: 100%;
    display: flex;
    label {
      display: block;
      line-height: 1;
    }
    div {
      width: 100%;
      margin-right: 5px;
      padding-left: 5px;
    }
    input {
      width: 100%;
      height: 35px;
      color: #333;
    }
    span {
      color: red;
      font-size: 12px;
      display: block;
      position: relative;
      margin-top: -8px;
      margin-bottom: 12px;
    }
  }

  span.button {
    width: 100%;
    display: flex;
    position: relative;
    text-align: right;
    justify-content: flex-end;
    button {
      position: absolute;
      margin-top: 50px;
      padding: 6px 16px;
    }
  }
  div.config {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    div {
      max-width: 80%;
    }

    svg:hover {
      cursor: pointer;
    }
    picture {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    img {
      text-align: center;
      width: 80%;

      margin-top: 30px;
    }
    p {
      display: block;
      margin-bottom: 20px;
    }
    a {
      text-decoration: underline !important;
      opacity: 1;
      color: blue;
      font-size: 14px;
    }
  }
  div.integration {
    width: 100%;
    height: 100%;
    picture {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    img {
      text-align: center;
      width: 80%;

      margin-top: 30px;
    }
    p {
      display: block;
      margin-bottom: 20px;
    }
    a {
      text-decoration: underline !important;
      opacity: 1;
      color: blue;
      font-size: 14px;
    }
  }
  div.integration {
    p {
      font-size: 21px;
      color: #999;
      line-height: 1.4;
      text-align: center;
      margin-bottom: 50px;
    }
    span {
      display: flex;
      width: 100%;
      justify-content: center;
      button {
        border: 3px solid #3b9eff !important;
        border-radius: 4px !important;
        color: #333 !important;
      }
      a.register-done {
        border-radius: 5px;
        color: #f75a48 !important;
        padding: 8px 25px;
        background: #fff;
        border: 2px solid #f75a48 !important;
        font-weight: bolder;
        svg {
          margin: 0 10px 0 0 !important;
        }
        -webkit-box-shadow: 0px 8px 10px 3px rgba(199, 199, 199, 1);
        -moz-box-shadow: 0px 8px 10px 3px rgba(199, 199, 199, 1);
        box-shadow: 0px 8px 10px 3px rgba(199, 199, 199, 1);
      }

      .register-undone {
        cursor: default !important;
        border-radius: 5px;
        color: #999 !important;
        padding: 8px 25px;
        background: #fff;
        border: 2px solid #999 !important;
        font-weight: bolder;
        svg {
          margin: 0 10px 0 0 !important;
        }
        -webkit-box-shadow: 0px 8px 10px 3px rgba(199, 199, 199, 1);
        -moz-box-shadow: 0px 8px 10px 3px rgba(199, 199, 199, 1);
        box-shadow: 0px 8px 10px 3px rgba(199, 199, 199, 1);
      }
    }
  }
`;

export const Terms = styled.div`
  margin-top: 10px;
  p {
    color: #999;
    font-size: 12px;
  }
`;
