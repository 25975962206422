import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';
import {
  createClientSuccess,
  createClientFailure,
  updateClientSuccess,
  registerClientSuccess,
  sendFeedbackEndRequest,
} from './actions';

export function* sendFeedback({ payload }) {
  try {
    const { page, feedbackText } = payload;

    const { profile } = yield select((state) => state.User);

    const params = {
      page,
      feedbackText,
      email: profile.email,
      clientId: profile.client_id.name,
      name: profile.name,
      created_at: new Date(),
    };

    yield call(api.post, '/client/feedback', params);

    yield put(sendFeedbackEndRequest());

    toast.success('Feedback enviado com sucesso!');
  } catch (error) {
    toast.error(
      'Falha ao enviar feedback. Por favor, tente novamente mais tarde.'
    );
    yield put(sendFeedbackEndRequest());
  }
}

export function* registerClient({ payload }) {
  try {
    const { token, userName, emailadmin } = payload;
    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    const response = yield call(api.post, 'register', {
      userName,
      emailadmin,
    });

    const { authorizations, customerId } = response.data;

    yield put(registerClientSuccess(authorizations, customerId));
    toast.success('Seu registro foi iniciado');
  } catch (error) {
    yield put(createClientFailure());
    toast.error(error.response.data.message);
  }
}

export function* getDataClient({ payload }) {
  try {
    const { customer_id } = payload;

    const response = yield call(api.post, 'firstdata', {
      customer_id,
    });
    const allClients = response.data;
    yield put(createClientSuccess(allClients));

    toast.success('Cliente cadastrado com sucesso!');
    history.push('/');
  } catch (err) {
    toast.error(err.response.data.message);
    yield put(createClientFailure());
    history.push('/register');
  }
}

export function* updateClient({ payload }) {
  try {
    const {
      emailadmin,
      alert_email,
      company_name,
      phone,
      city,
      state,
      area_atuacao,
      client_id,
      start_work_time,
      end_work_time,
    } = payload.data;

    const response = yield call(api.put, 'client', {
      emailadmin,
      alert_email,
      phone,
      area_atuacao,
      client_id,
      company_name,
      city,
      state,
      start_work_time,
      end_work_time,
    });
    const client = response.data;
    yield put(updateClientSuccess(client));

    toast.success(
      'Configurações atualizadas! As alterações realizadas terão efeito em até 24h.'
    );
  } catch (err) {
    toast.error(err.response.data.message);
    yield put(createClientFailure());
  }
}

export default all([
  takeLatest('@client/GET_DATA_REQUEST', getDataClient),
  takeLatest('@client/UPDATE_REQUEST', updateClient),
  takeLatest('@client/REGISTER_REQUEST', registerClient),
  takeLatest('@client/SEND_FEEDBACK_REQUEST', sendFeedback),
]);
