import axios from 'axios';

const api = axios.create({
  withCredentials: true,
  baseURL: 'https://sti-001.appspot.com',
  // baseURL: 'http://localhost:8080',
  headers: { 'data-version': '6' },
});

export default api;
