import React from 'react';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';

import { signInRequest } from '~/store/modules/Auth/actions';

import logo from '~/assets/logo_branca.png';
import Container from '~/components/UI/Container';
import { Titulo } from './styles';
import { store } from '~/store';

export default function SignIn() {
  const dispatch = useDispatch();
  const { signed } = store.getState().Auth;
  // const loading = useSelector(state => state.Auth.loading);
  const SCOPES = [
    'https://www.googleapis.com/auth/admin.directory.user.readonly',
    // 'https://www.googleapis.com/auth/admin.directory.user',
  ];
  function responseGoogleLogin(response) {
    dispatch(
      signInRequest(
        response.tokenId,
        response.accessToken,
        response.profileObj.email,
        response.profileObj.googleId,
        response.profileObj.imageUrl
      )
    );
  }

  return (
    <Container>
      <img src={logo} alt="Safetec" />

      <form>
        <Titulo>Bem-vindo ao ST Insights!</Titulo>

        <GoogleLogin
          className="loginButton"
          clientId="171833423013-9crsjtnvkr9uqi9m6ifdasiqh82ogr9d.apps.googleusercontent.com"
          scope={`${SCOPES.join(' ')}`}
          onSuccess={responseGoogleLogin}
          onFailure={responseGoogleLogin}
          cookiePolicy="single_host_origin"
          buttonText="Login com Google"
          isSignedIn={signed}
          autoLoad={false}
        />
      </form>
    </Container>
  );
}
