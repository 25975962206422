import React, { useCallback, useMemo } from 'react';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MdApps } from 'react-icons/md';
import {
  Line,
  LineChart,
  XAxis,
  Tooltip,
  CartesianGrid,
  YAxis,
  Label,
} from 'recharts';
import { format, parseISO } from 'date-fns';
import {
  Container,
  HeaderChart,
  TooltipContainer,
  LabelTooltip,
  BodyTooltip,
  ResponsiveContainerChart,
} from './styles';

export default function AppUsageDetailsLine({
  data,
  xAxisDataKey,
  lineKeys,
  itemNamePT,
  filter,
}) {
  const monthNames = useMemo(
    () => [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    []
  );

  const labelFormatter = useCallback(
    (value) => {
      if (filter.period === 'year') {
        return monthNames[parseISO(value).getMonth()];
      }
      if (filter.period === 'trimester') {
        return `Semana terminada em ${format(parseISO(value), 'dd/MM')}`;
      }

      return format(parseISO(value), 'dd/MM');
    },
    [filter.period, monthNames]
  );

  const CustomTooltip = ({ active, payload = [], label }) => {
    if (active) {
      return (
        <TooltipContainer>
          <LabelTooltip>{labelFormatter(label)}</LabelTooltip>
          {payload.map((item) => (
            <BodyTooltip key={item.name} color={item.color}>
              {`${item.name}: ${item.value ? Math.round(item.value) : 0}`}
            </BodyTooltip>
          ))}
        </TooltipContainer>
      );
    }
    return null;
  };

  CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string,
        dataKey: PropTypes.string,
        fill: PropTypes.string,
        name: PropTypes.string,
        payload: PropTypes.shape({
          avg_meet_duration: PropTypes.number,
          avg_meet_participants: PropTypes.number,
          date: PropTypes.shape({ value: PropTypes.string }),
          ou: PropTypes.string,
          day: PropTypes.number,
          week: PropTypes.number,
          month: PropTypes.number,
          year: PropTypes.number,
        }),
        stroke: PropTypes.string,
        strokeWidth: PropTypes.number,
        value: PropTypes.number,
      })
    ),
    label: PropTypes.string,
  };

  CustomTooltip.defaultProps = {
    active: false,
    payload: [],
    label: '',
  };

  return (
    <Container>
      <HeaderChart>
        <MdApps size="24" /> {itemNamePT}
      </HeaderChart>
      <ResponsiveContainerChart width="100%" height={265}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xAxisDataKey}
            tickFormatter={(date) =>
              filter.period === 'year'
                ? monthNames[parseISO(date).getMonth()].substring(0, 3)
                : format(date ? parseISO(date) : new Date(), 'dd/MM')
            }
            angle={-35}
            textAnchor="end"
            interval={
              (filter.period === 'month' && 2) ||
              (filter.period === 'trimester' && 1) ||
              0
            }
            style={{ fontSize: 11, fontWeight: 'bold' }}
            height={50}
          >
            <Label
              style={{ fontSize: 12 }}
              value={
                (filter.period === 'year' && 'Meses') ||
                (filter.period === 'trimester' && 'Semanas') ||
                'Dias'
              }
              offset={0}
              position="insideBottom"
            />
          </XAxis>
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {lineKeys.map((lineKeyValues) => (
            <Line
              {...lineKeyValues}
              key={lineKeyValues.name}
              type="monotone"
              strokeWidth={3}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainerChart>
    </Container>
  );
}

AppUsageDetailsLine.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      avg_meet_duration: PropTypes.number,
      avg_meet_participants: PropTypes.number,
      date: PropTypes.shape({ value: PropTypes.string }),
      ou: PropTypes.string,
      day: PropTypes.number,
      week: PropTypes.number,
      month: PropTypes.number,
      year: PropTypes.number,
    })
  ).isRequired,
  xAxisDataKey: PropTypes.string.isRequired,
  lineKeys: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string,
      name: PropTypes.string,
      stroke: PropTypes.string,
    })
  ).isRequired,
  itemNamePT: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    period: PropTypes.string,
    ou: PropTypes.string,
  }).isRequired,
};
