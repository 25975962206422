import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  margin: 100px 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;
export const SubMenu = styled.div`
  background: #fff;
  width: 20%;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  table {
    width: 100%;

    thead {
      background: #f7f7f7;
      th {
        padding: 10px;
      }
    }
    tbody {
      tr {
        display: block;
        /* padding: 10px; */
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;

        &:hover {
          background: ${lighten(0.3, '#f75a48')};
        }
        td {
          display: inline-block;
          width: 100%;
          padding: 10px;

          a {
            display: inline-block;
            width: 100%;
            color: #f75a48;
          }
        }
      }
    }
  }
`;
