import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  grid-area: AppUsage;
  margin: 10px 0px;
`;
export const AppUsageChart = styled.div`
  /* margin: 5px; */
  height: 100%;
  text-align: center;
  font-size: 1.5em;
  grid-area: appTotal;
`;
