import styled from 'styled-components';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { GoogleLogout } from 'react-google-login';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  max-height: 1vh;
  .makeStyles-content-10 {
    padding: 0px !important;
  }
  .MuiListItem-gutters {
    padding-left: 23px !important;
  }
  .MuiAppBar-colorPrimary {
    background: #fff;
  }
  .makeStyles-appBarShift-3 {
    width: calc(100% - 300px);
    margin-left: 300px;
  }
  .makeStyles-menuButton-4 {
    color: #f8625c !important;
    margin-right: 15px !important;
  }
  .makeStyles-drawerOpen-7 {
    width: 300px;
  }

  a {
    text-decoration: none;
    font-size: 16px;
    color: #333;
  }
  main {
    width: 100%;
  }
  .makeStyles-drawerClose-9,
  .makeStyles-drawerClose-8 {
    @media ${device.mobileL} {
      width: 0 !important;
    }
    @media ${device.mobileS} {
      width: 0 !important;
    }
    @media ${device.mobileM} {
      width: 0 !important;
    }
    @media ${device.tablet} {
      width: 0 !important;
    }
  }

  .disable_link {
    pointer-events: none;
    color: #ccc;
    svg {
      color: #ccc !important;
    }
  }
`;

export const ContentTopMenu = styled.div`
  color: #f8625c !important;
  width: 100%;
  padding: 0 25px;
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all 0.2s;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  a.menu {
    color: #f8625c;
    text-decoration: none;
    font-weight: bolder;
    padding: 20px 35px;
    transition: all 0.4s;
    border-radius: 3px;

    &:hover {
      background: #f8625c;
      color: #fff;
    }
  }

  @media ${device.mobileL} {
    display: none;
  }
  @media ${device.mobileS} {
    display: none;
  }
  @media ${device.mobileM} {
    display: none;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const HeaderLogo = styled.div`
  padding-left: 18px;
  height: 60px;
  width: 500px;
  display: flex;
  align-items: center;
  transition: width 0.2s;
  a {
    font-size: 16px;
    font-weight: bold;
    color: #f8625c;
    outline: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  img {
    width: 80%;
    height: 80%;
    margin-right: 10px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  @media ${device.mobileS} {
    div {
      display: none !important;
    }
  }
  @media ${device.mobileM} {
    div {
      display: none !important;
    }
  }
  @media ${device.tablet} {
    div {
      display: none !important;
    }
  }
`;
export const HeaderContent = styled.div`
  font-size: 14px;
  color: #495057;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin: 0 30px;
  p {
    font-weight: bold;
  }
  img {
    border-radius: 50px;
    width: 45px;
    height: 45px;
  }

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      margin: 0 10px;
    }
    padding: 0 10px;
  }

  @media ${device.mobileS} {
    margin: 0;
    span {
      padding: 0;
    }
  }
  @media ${device.mobileM} {
    margin: 0;
    span {
      padding: 0;
    }
  }
  @media ${device.tablet} {
    margin: 0;
    span {
      padding: 0;
    }
  }
`;

export const ProfileContent = styled.div`
  @media ${device.mobileS} {
    display: none !important;
  }
  @media ${device.mobileM} {
    display: none !important;
  }
  @media ${device.tablet} {
    display: none !important;
  }
`;

export const Footer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ViewArea = styled.div`
  padding-left: 13px;
  p {
    color: #000;
  }

  @media ${device.mobileS} {
    display: none !important;
  }
  @media ${device.mobileM} {
    display: none !important;
  }
  @media ${device.tablet} {
    display: none !important;
  }
`;
export const Logout = styled(GoogleLogout)`
  width: auto;
  height: 35px;
  font-weight: bolder;
  div {
    margin-right: 0px !important;
  }
  div {
    padding: 0 10px !important;
  }
`;

export const Version = styled.div`
  color: #999;
  font-size: 10px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
`;

export const CustomListItem = styled(ListItem)`
  background-color: ${({ selected }) =>
    selected ? '#f8625c' : '#ffffff'} !important;
`;

export const CustomListItemText = styled(ListItemText)`
  color: ${({ selected }) => (selected ? '#ffffff' : '#333333')};
`;
