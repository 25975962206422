import styled from 'styled-components';
import ImageIconMaterialUi from '@material-ui/icons/Image';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  padding: 20px 40px;
  padding-top: 0px !important;
  grid-gap: 5px;
  margin-top: 80px;
  width: 100%;
  overflow: hidden;
`;
export const HeaderSettings = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 0 10px;
  color: #495057;
  h3 {
    font-size: 1.3em;
  }
`;
export const Board = styled.div`
  background: #fff;
  border-radius: 3px;
  padding: 40px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
`;
export const CardInsightA = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  flex: ${(props) => (props.doubleSizeCard ? '1 2 1000px' : '1 2 600px')};
  order: ${(props) => props.order};
  p {
    color: #495057;
    font-size: 1.1em;
  }
  header {
    color: ${(props) => (props.order === 1 ? '#333' : 'blue')};
  }
  &.top-divider {
    border-top-style: solid;
    border-top-color: #e5e5e5;
    border-top-width: 2px;
    padding-top: 20px;
  }
`;

export const ItemData = styled.div`
  max-height: 200px;
  flex: 1;
  margin: 0 20px;
  p {
    font-size: 0.9em;
    color: #495057;
  }
  .custom-tooltip {
    padding: 20px;
    color: #495057;
    max-width: 250px;
    background: rgba(256, 256, 256, 0.8);

    .label {
      font-weight: bolder;
    }
  }
  &.boilerplate {
    margin-bottom: 30px;
  }
  &.grid {
    text-align: center;
  }

  @media ${device.mobileL} {
    &.desktop-only {
      display: none !important;
    }
  }
  @media ${device.mobileS} {
    &.desktop-only {
      display: none !important;
    }
  }
  @media ${device.mobileM} {
    &.desktop-only {
      display: none !important;
    }
  }
  @media ${device.tablet} {
    &.tablet-hide {
      display: none !important;
    }
  }
`;
export const ItemDataGrid = styled.div`
  align-items: center;
  justify-content: center;
  display: grid;
  grid-auto-rows: 80px;
  grid-template-columns: ${(props) =>
    !props.total_result || props.total_result > 5
      ? 'repeat(5, 60px);'
      : `repeat(${props.total_result}, 60px);`};
`;

export const ItemBar = styled.div`
  width: 260px !important;
`;

export const ItemHeader = styled.div`
  text-align: ${({ center }) => (center ? 'center' : 'inherit')};
  min-height: 50px;
`;
export const ItemMedia = styled.div`
  font-size: 1.5em;
  font-weight: bolder;
  color: rgba(64, 132, 249, 0.9);
`;
export const ItemSites = styled.div`
  font-size: 2.5em;
  font-weight: bolder;
  color: rgba(64, 132, 249, 0.9);
  p {
    font-size: 0.8rem;
    font-weight: normal !important;
  }
  display: flex;
  justify-content: center;
`;
export const ItemFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 0 0px;
  div {
    text-align: left;
    p {
      margin-bottom: 0px;
      font-size: 0.8rem;
      color: #495057;
    }
    small {
      font-weight: bolder;
      font-size: 0.8rem;
      color: #999;
    }
  }
`;
export const Percent = styled.div`
  color: #495057;
  font-size: 1rem;
  text-align: right;
  width: auto !important;
`;
export const HeaderInsight = styled.header`
  display: flex;

  min-width: 100%;
  margin-left: -32px;
  align-items: center;
  h3 {
    font-size: 1.2em;
    margin-right: 5px;
  }
  svg.mainIcon {
    margin-top: -16px;
  }
  svg {
    margin-bottom: 8px;
    margin-right: 10px;
  }
  img {
    width: 60px;
    margin-top: -19px;
  }
`;
export const ContentInsight = styled.div`
  display: flex;
  flex-wrap: wrap;
  div.item {
    margin-right: 10px;
    margin-bottom: 10px;
    span {
      color: #f8625c;
      font-weight: bolder;
    }
    p {
      color: #999 !important;
    }
  }
`;

export const HeaderBoard = styled.div`
  display: flex;

  align-items: center;
  padding: 0 50px;
  min-height: 180px;
  background: hsla(0, 0%, 100%, 0.55);
  div {
    margin-top: 50px;
    padding: 10px;
    background: #fff;
    -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
    -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
    box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  }
  span {
    margin-left: 20px;
    margin-top: 50px;
    p {
      color: #495057;
      font-size: 1.7em;
    }
    small {
      color: #f8625c;
      font-size: 1em;
    }
  }
`;

export const ImageIcon = styled(ImageIconMaterialUi)`
  color: rgb(30, 113, 69);
`;
export const VideoIcon = styled(VideoLibraryIcon)`
  color: rgb(208, 69, 37);
`;
export const PdfIcon = styled(PictureAsPdfIcon)`
  color: rgb(43, 87, 151);
`;
export const OtherTypeIcon = styled(InsertDriveFileIcon)`
  color: rgb(130, 130, 130);
`;

export const DashboardTitleContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  margin-top: 10px;
  margin-bottom: 13px;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  border-radius: 5px;
  background: #f8625c;
  grid-gap: 5px;
  grid-area: Title !important;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
`;

export const DashboardTitleText = styled.span`
  width: 235px;
  white-space: nowrap;
  font-size: 22px;
  padding: 20px 30px;
  /* text-transform: uppercase; */
  color: #eee;
  font-weight: bolder;
  text-align: left;
`;

export const PdfButton = styled.span`
  width: 80%;
  margin-left: 60px;

  svg {
    cursor: pointer;
    float: right;
    margin-right: 22px;
  }
`;
