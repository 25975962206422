import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  grid-area: CreateApp;
  background: #fff;
  width: 100%;
  margin-top: 10px;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  hr {
    margin: 0 !important;
  }
`;

export const MuiButtonGroup = styled(ButtonGroup)`
  margin: auto;
`;

export const LoadingArea = styled.div`
  height: 675px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ContainerGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);

  @media ${device.laptopL} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${device.laptopM} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
  @media ${device.mobileM} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const ContainerCard = styled.div`
  width: 100%;
`;

export const PeriodButton = styled(Button)`
  font-weight: bold !important;
  border-color: #f8625c !important;
  background-color: ${(props) =>
    props.variant === 'contained' ? '#f8625c' : '#fff'} !important;
  color: ${(props) =>
    props.variant === 'contained' ? '#fff' : '#f8625c'} !important;
`;

export const App30Days = styled.div``;

export const TitleText = styled.div`
  width: 100%;
  display: flex;
`;

export const HeaderChart = styled.header`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 20px 30px;
  color: #999;
  font-weight: bolder;
  text-align: left;
  justify-content: space-between;
  svg {
    color: #f8625c;
  }
  div.title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    span {
      p {
        margin-left: 20px;
        padding: 0;
        line-height: 1;
        font-size: 1.5em !important;
        color: #333;
        font-weight: normal !important;
      }
      small {
        margin-left: 20px;
        color: #f8625c;
        font-size: 1em;
      }
    }
  }

  @media ${device.mobileL} {
    > div {
      display: none !important;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.mobileM} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.tablet} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
`;
