import styled from 'styled-components';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  grid-area: EditorDrive;
  position: relative;
  border-radius: 3px;
  font-size: 11px;
  max-width: 100%;
  height: 99%;
  max-height: 643px;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);

  background-color: #fff;

  hr {
    margin: 0px !important;
  }

  @media ${device.laptop} {
    max-height: none;
    height: auto !important;
  }
`;

export const CardContainer = styled.div``;

export const CardsEditors = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  padding: 0 30px;
`;

export const CardEditor = styled.div`
  display: block;
  flex: 10;
  justify-content: center;
  margin: 10px auto;
  align-items: center;
  font-size: 1.3em;
  color: #495057;
  &:not(.last) {
    border-right: 1px solid #495057;
  }
  @media ${device.mobileS} {
    border-right: none !important;
  }
  @media ${device.mobileM} {
    border-right: none !important;
  }
`;

export const TotalCardEditor = styled.div`
  padding-left: 5px;
  font-size: 4.2em;
  font-weight: bolder;
  margin-right: 5px;
  text-align: center;
`;

export const LabelCardEditor = styled.div`
  padding-left: 5px;
  text-align: center;

  span {
    font-size: 1.7em;
    &.forms {
      color: #8832d8;
    }
    &.gplus {
      color: rgb(222, 37, 1);
    }
    &.sites {
      color: rgb(0, 88, 241);
    }
  }
`;
