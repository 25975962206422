/* eslint-disable react/jsx-no-bind */
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdApps, MdHdrWeak, MdInfo } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';
import {
  format,
  parseISO,
  isThisMonth,
  isThisYear,
  isSameMonth,
  isSameYear,
} from 'date-fns';
import { getAppUsageDataRequest } from '~/store/modules/ClientData/actions';
import {
  Container,
  PeriodButton,
  MuiButtonGroup,
  LoadingArea,
  HeaderChart,
  CardLineChart,
  Legenda,
  FlexContainer,
  CardContainer,
  TitleApp,
  TitleText,
  NoData,
} from './styles';

export default function CardBarChart() {
  const dispatch = useDispatch();
  const { app_usage, loading } = useSelector(
    (state) => state.ClientData.clientAppUsageData
  );

  const { dataMonths } = useSelector((state) => state.Client.userClient);

  const profile = useSelector((state) => state.User.profile);
  const { currentUserClient, currentOrgUnits } = useSelector(
    (state) => state.Client
  );
  const [filter, setFilter] = useState({
    period: 'year',
    ou: currentOrgUnits[0],
  });

  const customer_id = useMemo(
    () => (profile ? currentUserClient || profile.client_id.name : null),
    [currentUserClient, profile]
  );

  useEffect(() => {
    dispatch(getAppUsageDataRequest(customer_id, filter));
  }, [filter, customer_id, dispatch]);

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const getUserAppPercent = (users_app, name_user_apps) => {
    const defaultUserActive = { user_active: app_usage.current_user_active };
    const appDate = new Date(users_app.date.value);

    let active;

    if (!(isThisMonth(appDate) && isThisYear(appDate))) {
      active = app_usage.user_active.find((active1) => {
        // 20 é apenas um dia aleatorio.
        const activeDate = new Date(active1.year, active1.month - 1, 20);

        if (
          isSameMonth(appDate, activeDate) &&
          isSameYear(appDate, activeDate)
        ) {
          return active1;
        }

        return null;
      });
    }

    if (!active) {
      active = defaultUserActive;
    }

    return Math.round((users_app[name_user_apps] / active.user_active) * 100);
  };

  function hangleChangeOu(e) {
    setFilter({ ...filter, ou: e.target.value });
  }

  return (
    <Container>
      <HeaderChart>
        <div className="title">
          <TitleText>
            <MdApps size="24" />
            <span>
              <p>Uso de aplicativos</p>

              <small>
                {(filter.period === 'year' && `últimos ${dataMonths} meses`) ||
                  (filter.period === 'trimester' && 'últimos 3 meses') ||
                  'últimos 30 dias'}
              </small>
            </span>
          </TitleText>
          <FormControl
            // className={classes.formControl}
            style={{
              width: '400px',
              margin: '-4px auto',
              borderRight: '2px solid rgba(0, 0, 0, 0.1)',
              paddingRight: '10px',
              marginRight: '10px',
              fontWeight: 'normal',
            }}
          >
            <InputLabel
              id="app-usage-ou-label"
              htmlFor="app-usage-ou-select"
              style={{ color: '#f8625c' }}
            >
              Unidade Organizacional
            </InputLabel>
            <Select
              labelId="app-usage-ou-label"
              id="app-usage-ou-select"
              value={filter.ou}
              onChange={hangleChangeOu}
              label="OU"
            >
              {currentOrgUnits.map((ou) => (
                <MenuItem value={ou} key={ou}>
                  {ou === '/' ? 'TODAS' : ou}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MuiButtonGroup
            color="secondary"
            aria-label="outlined secondary button group"
          >
            <PeriodButton
              disabled={filter.period === 'month'}
              variant={filter.period === 'month' ? 'contained' : 'outlined'}
              onClick={() => {
                setFilter({ ...filter, period: 'month' });
              }}
            >
              Mês
            </PeriodButton>
            <PeriodButton
              disabled={filter.period === 'trimester'}
              variant={filter.period === 'trimester' ? 'contained' : 'outlined'}
              onClick={() => {
                setFilter({ ...filter, period: 'trimester' });
              }}
            >
              Trimestre
            </PeriodButton>
            <PeriodButton
              disabled={filter.period === 'year'}
              variant={filter.period === 'year' ? 'contained' : 'outlined'}
              onClick={() => {
                setFilter({ ...filter, period: 'year' });
              }}
            >
              Ano
            </PeriodButton>
          </MuiButtonGroup>
        </div>
      </HeaderChart>

      <hr />
      <CardContainer>
        {!loading ? (
          <>
            <span className="legenda">
              <Legenda>
                <MdHdrWeak size="18" color="rgb(255, 210, 80)" /> Drive
              </Legenda>
              <Legenda>
                <MdHdrWeak size="18" color="rgb(214, 74, 58)" /> Gmail
              </Legenda>
              <Legenda>
                <MdHdrWeak size="18" color="rgb(20, 146, 133)" /> Meet
              </Legenda>
              <Legenda>
                <MdHdrWeak size="18" color="rgb(64, 132, 249)" /> Agenda
              </Legenda>
            </span>
            <FlexContainer>
              <CardLineChart>
                {app_usage &&
                app_usage.users_drive &&
                app_usage.users_drive.length > 0 ? (
                  <>
                    <TitleApp color="rgb(255, 210, 80)">
                      <MdInfo
                        color="#333"
                        title="Usuários que criaram arquivos no Google Drive"
                        size={20}
                      />{' '}
                    </TitleApp>
                    <ResponsiveContainer minHeight="100%" width="100%">
                      <ComposedChart
                        data={app_usage ? app_usage.users_drive : []}
                        margin={{
                          top: 20,
                          right: 30,
                          left: -10,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date.value"
                          padding={{ left: 5, right: 5 }}
                          tickFormatter={(date) =>
                            filter.period === 'year'
                              ? monthNames[parseISO(date).getMonth()].substring(
                                  0,
                                  3
                                )
                              : format(
                                  date ? parseISO(date) : new Date(),
                                  'dd/MM'
                                )
                          }
                          angle={-35}
                          textAnchor="end"
                          interval={
                            (filter.period === 'month' && 2) ||
                            (filter.period === 'trimester' && 1) ||
                            0
                          }
                          style={{ fontSize: 11, fontWeight: 'bold' }}
                          height={50}
                        >
                          <Label
                            style={{ fontSize: 12 }}
                            value={
                              (filter.period === 'year' && 'Meses') ||
                              (filter.period === 'trimester' && 'Semanas') ||
                              'Dias'
                            }
                            offset={0}
                            position="insideBottom"
                          />
                        </XAxis>
                        <YAxis
                          yAxisId="left"
                          tickFormatter={(percentage) => `${percentage} %`}
                          domain={[0, 100]}
                          label={{
                            value: 'Percentual de usuários',
                            angle: -90,
                            offset: 18,
                            position: 'insideBottomLeft',
                          }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          label={{
                            value: 'Quantidade de Arquivos Drive',
                            angle: 90,
                            offset: 8,
                            position: 'insideBottomRight',
                          }}
                        />

                        <Tooltip
                          wrapperStyle={{
                            width: '250px',
                            fontSize: 11,
                            fontWeight: 'bolder',
                          }}
                          labelFormatter={(value) => {
                            if (filter.period === 'year') {
                              return monthNames[parseISO(value).getMonth()];
                            }
                            if (filter.period === 'trimester') {
                              return `Semana terminada em ${format(
                                parseISO(value),
                                'dd/MM'
                              )}`;
                            }

                            return format(parseISO(value), 'dd/MM');
                          }}
                        />
                        <Bar
                          yAxisId="right"
                          name="Arquivos criados (Drive pessoal)"
                          dataKey="create_personal_drive"
                          barSize={18}
                          stackId="a"
                          fill="#F0E68C"
                          opacity="0.7"
                        />

                        <Bar
                          yAxisId="right"
                          name="Arquivos criados (Drive comp.)"
                          dataKey="create_shared_drive"
                          stackId="a"
                          barSize={18}
                          fill="#DF7401"
                          opacity="0.5"
                        />

                        <Line
                          name="Usuários que criaram aquivos"
                          yAxisId="left"
                          type="monotone"
                          dataKey={(users_drive) =>
                            getUserAppPercent(users_drive, 'user_drive')
                          }
                          unit="%"
                          stroke="#FFD700"
                          strokeWidth={3}
                          activeDot={{ r: 8 }}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </>
                ) : (
                  <NoData>Sem dados</NoData>
                )}
              </CardLineChart>
              <CardLineChart>
                {app_usage &&
                app_usage.users_meet &&
                app_usage.users_meet.length > 0 ? (
                  <>
                    <TitleApp color="rgb(20, 146, 133)">
                      {' '}
                      <MdInfo
                        color="#333"
                        title="Usuários que participaram de reuniões"
                        size={20}
                      />
                    </TitleApp>
                    <ResponsiveContainer minHeight="100%" width="100%">
                      <ComposedChart
                        data={app_usage ? app_usage.users_meet : []}
                        margin={{
                          top: 20,
                          right: 30,
                          left: -10,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date.value"
                          padding={{ left: 5, right: 5 }}
                          tickFormatter={(date) =>
                            filter.period === 'year'
                              ? monthNames[parseISO(date).getMonth()].substring(
                                  0,
                                  3
                                )
                              : format(
                                  date ? parseISO(date) : new Date(),
                                  'dd/MM'
                                )
                          }
                          angle={-35}
                          textAnchor="end"
                          interval={
                            (filter.period === 'month' && 2) ||
                            (filter.period === 'trimester' && 1) ||
                            0
                          }
                          style={{ fontSize: 11, fontWeight: 'bold' }}
                          height={50}
                        >
                          <Label
                            style={{ fontSize: 12 }}
                            value={
                              (filter.period === 'year' && 'Meses') ||
                              (filter.period === 'trimester' && 'Semanas') ||
                              'Dias'
                            }
                            offset={0}
                            position="insideBottom"
                          />
                        </XAxis>
                        <YAxis
                          yAxisId="left"
                          tickFormatter={(percentage) => `${percentage} %`}
                          domain={[0, 100]}
                          label={{
                            value: 'Percentual de usuários',
                            angle: -90,
                            offset: 18,
                            position: 'insideBottomLeft',
                          }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          label={{
                            value: 'Quantidade de reuniões',
                            angle: 90,
                            offset: 20,
                            position: 'insideBottomRight',
                          }}
                        />
                        <Tooltip
                          wrapperStyle={{
                            width: '200px',
                            fontSize: 11,
                            fontWeight: 'bolder',
                          }}
                          labelFormatter={(value) => {
                            if (filter.period === 'year') {
                              return monthNames[parseISO(value).getMonth()];
                            }
                            if (filter.period === 'trimester') {
                              return `Semana terminada em ${format(
                                parseISO(value),
                                'dd/MM'
                              )}`;
                            }

                            return format(parseISO(value), 'dd/MM');
                          }}
                        />
                        <Bar
                          yAxisId="right"
                          name="Reuniões criadas"
                          dataKey="meet_amt"
                          barSize={18}
                          fill="#149285"
                          opacity="0.6"
                        />
                        <Line
                          name="Usuários ativos"
                          yAxisId="left"
                          type="monotone"
                          dataKey={(users_meet) =>
                            getUserAppPercent(users_meet, 'user_meet')
                          }
                          unit="%"
                          stroke="rgb(20, 146, 133)"
                          activeDot={{ r: 8 }}
                          strokeWidth={3}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </>
                ) : (
                  <NoData>Sem dados</NoData>
                )}
              </CardLineChart>

              <CardLineChart>
                {app_usage &&
                app_usage.users_gmail &&
                app_usage.users_gmail.length > 0 ? (
                  <>
                    <TitleApp color="rgb(214, 74, 58)">
                      <MdInfo
                        color="#333"
                        size={20}
                        title="Usuários que enviaram e-mail"
                      />
                    </TitleApp>
                    <ResponsiveContainer minHeight="100%" width="100%">
                      <ComposedChart
                        data={app_usage ? app_usage.users_gmail : []}
                        margin={{
                          top: 20,
                          right: 30,
                          left: -10,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date.value"
                          padding={{ left: 5, right: 5 }}
                          tickFormatter={(date) =>
                            filter.period === 'year'
                              ? monthNames[parseISO(date).getMonth()].substring(
                                  0,
                                  3
                                )
                              : format(
                                  date ? parseISO(date) : new Date(),
                                  'dd/MM'
                                )
                          }
                          angle={-35}
                          textAnchor="end"
                          interval={
                            (filter.period === 'month' && 2) ||
                            (filter.period === 'trimester' && 1) ||
                            0
                          }
                          style={{ fontSize: 11, fontWeight: 'bold' }}
                          height={50}
                        >
                          <Label
                            style={{ fontSize: 12 }}
                            value={
                              (filter.period === 'year' && 'Meses') ||
                              (filter.period === 'trimester' && 'Semanas') ||
                              'Dias'
                            }
                            offset={0}
                            position="insideBottom"
                          />
                        </XAxis>
                        <YAxis
                          yAxisId="left"
                          tickFormatter={(percentage) => `${percentage} %`}
                          domain={[0, 100]}
                          label={{
                            value: 'Percentual de usuários',
                            angle: -90,
                            offset: 18,
                            position: 'insideBottomLeft',
                          }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          label={{
                            value: 'Quantidade de Emails',
                            angle: 90,
                            offset: 20,
                            position: 'insideBottomRight',
                          }}
                        />
                        <Tooltip
                          wrapperStyle={{
                            width: '200px',
                            fontSize: 11,
                            fontWeight: 'bolder',
                          }}
                          labelFormatter={(value) => {
                            if (filter.period === 'year') {
                              return monthNames[parseISO(value).getMonth()];
                            }
                            if (filter.period === 'trimester') {
                              return `Semana terminada em ${format(
                                parseISO(value),
                                'dd/MM'
                              )}`;
                            }

                            return format(parseISO(value), 'dd/MM');
                          }}
                        />
                        <Bar
                          yAxisId="right"
                          name="Emails enviados"
                          dataKey="email_sent_amt"
                          barSize={18}
                          fill="#D64A3A"
                          opacity="0.6"
                        />
                        <Line
                          name="Usuários ativos"
                          yAxisId="left"
                          type="monotone"
                          dataKey={(users_gmail) =>
                            getUserAppPercent(users_gmail, 'user_gmail')
                          }
                          unit="%"
                          stroke="rgb(214, 74, 58)"
                          strokeWidth={3}
                          activeDot={{ r: 8 }}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </>
                ) : (
                  <NoData>Sem dados</NoData>
                )}
              </CardLineChart>
              <CardLineChart>
                {app_usage &&
                app_usage.users_calendar &&
                app_usage.users_calendar.length > 0 ? (
                  <>
                    <TitleApp color="rgb(64, 132, 249)">
                      <MdInfo
                        title="Usuários que criaram eventos no Agenda"
                        color="#333"
                        size={20}
                      />
                    </TitleApp>
                    <ResponsiveContainer minHeight="100%" width="100%">
                      <ComposedChart
                        data={app_usage ? app_usage.users_calendar : []}
                        margin={{
                          top: 20,
                          right: 30,
                          left: -10,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date.value"
                          padding={{ left: 5, right: 5 }}
                          tickFormatter={(date) =>
                            filter.period === 'year'
                              ? monthNames[parseISO(date).getMonth()].substring(
                                  0,
                                  3
                                )
                              : format(
                                  date ? parseISO(date) : new Date(),
                                  'dd/MM'
                                )
                          }
                          angle={-35}
                          textAnchor="end"
                          interval={
                            (filter.period === 'month' && 2) ||
                            (filter.period === 'trimester' && 1) ||
                            0
                          }
                          style={{ fontSize: 11, fontWeight: 'bold' }}
                          height={50}
                        >
                          <Label
                            style={{ fontSize: 12 }}
                            value={
                              (filter.period === 'year' && 'Meses') ||
                              (filter.period === 'trimester' && 'Semanas') ||
                              'Dias'
                            }
                            offset={0}
                            position="insideBottom"
                          />
                        </XAxis>
                        <YAxis
                          yAxisId="left"
                          tickFormatter={(percentage) => `${percentage} %`}
                          domain={[0, 100]}
                          label={{
                            value: 'Percentual de usuários',
                            angle: -90,
                            offset: 18,
                            position: 'insideBottomLeft',
                          }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          label={{
                            value: 'Quantidade de Compromissos',
                            angle: 90,
                            offset: 18,
                            position: 'insideBottomRight',
                          }}
                        />
                        <Tooltip
                          wrapperStyle={{
                            width: '200px',
                            fontSize: 11,
                            fontWeight: 'bolder',
                          }}
                          labelFormatter={(value) => {
                            if (filter.period === 'year') {
                              return monthNames[parseISO(value).getMonth()];
                            }
                            if (filter.period === 'trimester') {
                              return `Semana terminada em ${format(
                                parseISO(value),
                                'dd/MM'
                              )}`;
                            }

                            return format(parseISO(value), 'dd/MM');
                          }}
                        />
                        <Bar
                          yAxisId="right"
                          name="Eventos criados"
                          dataKey="calendar_amt"
                          barSize={18}
                          fill="#4084F9"
                          opacity="0.6"
                        />
                        <Line
                          name="Usuários ativos"
                          unit="%"
                          yAxisId="left"
                          type="monotone"
                          dataKey={(users_calendar) =>
                            getUserAppPercent(users_calendar, 'user_calendar')
                          }
                          stroke="rgb(64, 132, 249)"
                          strokeWidth={3}
                          activeDot={{ r: 8 }}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </>
                ) : (
                  <NoData>Sem dados</NoData>
                )}
              </CardLineChart>
            </FlexContainer>
          </>
        ) : (
          <LoadingArea>
            <CircularProgress style={{ color: '#f8625c' }} />
          </LoadingArea>
        )}
      </CardContainer>
    </Container>
  );
}
