import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './config/ReactotronConfig';

import Routes from './routes/index';
import history from './services/history';

import { store, persistor } from './store';

import GlobalStyle from './styles/global';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <ToastContainer
            autoClose={3000}
            style={{
              zIndex: 1000,
              top: 87,
            }}
          />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
