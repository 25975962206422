import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { MdInfo } from 'react-icons/md';
import { GrDocumentCsv } from 'react-icons/gr';

export const InfoIcon = styled(MdInfo)`
  margin-right: -13px;
  margin-left: 10px;
  color: #ababab;
`;

export const CsvIcon = styled(GrDocumentCsv)`
  path {
    stroke: ${({ color }) => color || 'black'};
  }
`;

export const CellHead = styled(TableCell)`
  font-size: 16px !important;
  font-weight: bolder !important;

  div#infoIcon {
    span.tip {
      text-decoration: none;
    }
    span.tip:hover {
      cursor: help;
      position: relative;
    }
    span.tip span {
      display: none;
    }
    span.tip:hover span {
      border: #c0c0c0 1px dotted;
      padding: 10px 20px 10px 10px;
      display: block;
      margin-left: -136px;
      width: 244px;
      position: absolute;
      text-decoration: none;
      font-size: 14px;
      color: #444;
      background-color: #eee;
      border-radius: 5px;
      box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
    }
  }
`;

export const MyTableCell = styled(TableCell)`
  color: #495057 !important;
  padding-right: 20px !important;
  font-size: 14px !important;
`;

export const UsersButton = styled.button`
  border: 1px solid #f75a48;
  border-radius: 4px;
  background: #fff;
  padding: 5px 10px;
  color: #f75a48;
  font-weight: bold;
  font-size: 12px;

  &:hover {
    background: #f75a48;
    color: #fff;
  }
`;

export const Head = styled(TableHead)`
  background-color: #fff;
  color: #495057;
`;

export const RankingTable = styled(Table)`
  width: 100% !important;
`;

export const RankingContainer = styled(TableContainer)`
  width: 100% !important;
`;

export const RankingRoot = styled.div`
  width: 100% !important;
`;
export const MyTableRow = styled(TableRow)`
  text-align: center;
  font-size: 18px !important;
  color: #495057;
  &:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
export const SearchUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    color: #333;
  }
  nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span.icon-search {
      padding: 9px;
      align-items: baseline;
    }
  }
`;

export const ColumnSelector = styled.button`
  background-color: #fff;
  border: none;
  color: #f8625c;
  padding: 2px;

  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 50%;
  }
`;

export const CsvButton = styled.button`
  background-color: #fff;
  border: none;
  color: #f8625c;
  padding: 5px;
  margin-left: 7px;
  margin-right: 5px;

  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 50%;
  }
`;

export const ColumnSpan = styled.div`
  z-index: 6;
  line-height: 2;
  border: #c0c0c0 1px dotted;
  padding: 10px 20px 10px 10px;
  /* margin-left: -136px; */
  width: 300px;
  right: 40px;
  text-align: initial;
  position: absolute;
  text-decoration: none;
  font-size: 14px;
  color: #444;
  font-weight: bold;
  background-color: #eee !important;
  border-radius: 5px;
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);

  small {
    color: red;
  }
`;
