import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { transparentize } from 'polished';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  position: relative;
  border-radius: 3px;
  font-size: 11px;
  max-width: 100%;
  height: 650px;
  max-height: 650px;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);

  background-color: #fff;

  hr {
    margin: 0px !important;
  }

  @media ${device.laptop} {
    max-height: none;
    height: auto !important;
  }
`;

export const NoData = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #999;
  margin: -10px 10px 10px 10px;
  border-radius: 10px;
  background: repeating-linear-gradient(
    -55deg,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 30px,
    rgba(0, 0, 0, 0.1) 30px,
    rgba(0, 0, 0, 0.1) 60px
  );
`;

export const MuiButtonGroup = styled(ButtonGroup)`
  margin: auto;
`;

export const PeriodButton = styled(Button)`
  font-weight: bold !important;
  border-color: #f8625c !important;
  background-color: ${(props) =>
    props.variant === 'contained' ? '#f8625c' : '#fff'} !important;
  color: ${(props) =>
    props.variant === 'contained' ? '#fff' : '#f8625c'} !important;
`;

export const LoadingArea = styled.div`
  height: 568px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardContainer = styled.div`
  justify-content: flex-end;
  span.legenda {
    align-items: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: -8px;
    color: #999;
    font-weight: bolder;
    /* padding-top: 20px; */
    padding-right: 30px;
    /* width: 30%; */

    width: 100%;
    justify-content: flex-end;
    /* justify-content: space-between; */
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  div > {
    display: block;
  }
`;
export const CardLineChart = styled.div`
  display: flex !important;
  position: relative;
  text-align: center;
  font-size: 11px;
  width: 50%;
  height: 240px;
  flex-grow: 2;
  padding: 5px;
  margin-top: 25px;
  @media ${device.laptop} {
    flex: 1 2 400px;
  }
`;
export const HeaderChart = styled.header`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 20px 30px;
  color: #999;
  font-weight: bolder;
  text-align: left;
  justify-content: space-between;
  svg {
    color: #f8625c;
  }
  div.title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    span {
      p {
        margin-left: 20px;
        padding: 0;
        line-height: 1;
        font-size: 1.5em !important;
        color: #333;
        font-weight: normal !important;
      }
      small {
        margin-left: 20px;
        color: #f8625c;
        font-size: 1em;
      }
    }
  }

  @media ${device.mobileL} {
    > div {
      display: none !important;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.mobileM} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.tablet} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
`;

export const TitleText = styled.div`
  width: 100%;
  display: flex;
`;

export const Legenda = styled.div`
  font-size: 12px;
  text-transform: capitalize;
  padding-left: 40px;
`;
export const TitleApp = styled.div`
  display: flex !important;
  justify-content: space-between;
  width: 10% !important;
  color: ${(props) => props.color};
  font-weight: bolder;
  padding: 15px 0px 10px 0px;
  height: 87%;
  font-size: 14px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-transform: capitalize;

  svg {
    fill: ${(props) => transparentize(0.1, props.color)};
  }
`;
