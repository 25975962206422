import React from 'react';

import ChartAppUsageCardLine from '~/components/Dashboard/Organization/Chart/AppUsage/CardLine';
import { Container, AppUsageChart } from './styles';

export default function AppUsageCharts() {
  return (
    <Container>
      <AppUsageChart>
        <ChartAppUsageCardLine />
      </AppUsageChart>
    </Container>
  );
}
