import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MdBubbleChart, MdModeEdit } from 'react-icons/md';
import Header from '~/components/UI/Header';
import api from '~/services/api';

import { Container, Profile, Table, HeaderAdmin } from './styles';

export default function Admin() {
  const tokenJWT = useSelector((state) => state.Auth.token);

  if (tokenJWT) {
    api.defaults.headers.common.Authorization = `Bearer ${tokenJWT}`;
  }

  const newClients = useSelector((state) => state.Client.clients);
  const profile = useSelector((state) => state.User.profile);
  const [clients, setClients] = useState(newClients);
  useEffect(() => {
    async function loadClients() {
      const response = await api.get('/clients');
      setClients(response.data);
    }
    loadClients();
  }, [newClients]);

  return (
    <>
      <Header title="Admin" user={profile} />
      <HeaderAdmin>
        <div>
          <MdBubbleChart size="40" color="#f8625c" />
        </div>
        <span>
          <p>Painel de Administração</p>
          <small>Safetec Informática</small>
        </span>
      </HeaderAdmin>
      <Container>
        <Table>
          <thead>
            <tr>
              <th colSpan="7">Cientes ativos</th>
            </tr>
            <tr>
              <th>#</th>
              <th>Cliente</th>
              <th>Área de atuação</th>
              <th>Domínio</th>
              <th>Usuário adminstrador</th>
              <th>Email de grupo</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {clients
              ? clients.map((client) => (
                  <tr>
                    <td>
                      {' '}
                      #<strong>{client.id}</strong>
                    </td>
                    <td>{client.organization}</td>
                    <td>{client.area}</td>
                    <td>{client.domain}</td>
                    <td>
                      <Profile>
                        <img
                          src={
                            client.thumbnail ||
                            'https://api.adorable.io/avatars/52/abott@adorable.png'
                          }
                          alt="foto usuário adminstrador"
                        />
                        <div>
                          <strong>{client.emailadmin}</strong>
                          <small>{client.name}</small>
                        </div>
                      </Profile>
                    </td>
                    <td>{client.groupEmail}</td>
                    <td>
                      <button type="button">
                        <MdModeEdit size={12} color="#333" />
                        editar
                      </button>
                    </td>
                  </tr>
                ))
              : 'Nenhum cliente cadastrado'}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
