import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegLightbulb } from 'react-icons/fa';
import { BiBarChartAlt2 } from 'react-icons/bi';
import { FiSettings, FiUsers } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { RiFeedbackLine } from 'react-icons/ri';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  sendFeedbackRequest,
  sendFeedbackReset,
} from '~/store/modules/Client/actions';
import {
  InputLabelOrange,
  CssTextField,
  OutlinedButton,
  ContainedButton,
} from './styles';

const menuItems = new Map([
  [
    '/dashboard/organization',
    {
      name: 'Organização',
      path: '/dashboard/organization',
      icon: (
        <BiBarChartAlt2 size="16" color="#f8625c" style={{ marginRight: 10 }} />
      ),
    },
  ],
  [
    '/dashboard/people',
    {
      name: 'Pessoas',
      path: '/dashboard/people',
      icon: <FiUsers size="16" color="#f8625c" style={{ marginRight: 10 }} />,
    },
  ],
  [
    '/insights',
    {
      name: 'Insights',
      path: '/insights',
      icon: (
        <FaRegLightbulb size="16" color="#f8625c" style={{ marginRight: 10 }} />
      ),
    },
  ],
  [
    '/administracao/config',
    {
      name: 'Configurações',
      path: '/administracao/config',
      icon: (
        <FiSettings size="16" color="#f8625c" style={{ marginRight: 10 }} />
      ),
    },
  ],
  [
    '/insights/config',
    {
      name: 'Configurações',
      path: '/insights/config',
      icon: (
        <FiSettings size="16" color="#f8625c" style={{ marginRight: 10 }} />
      ),
    },
  ],
  [
    '/',
    {
      name: 'Outros',
      path: '/',
      icon: (
        <HiOutlineDocumentReport
          size="18"
          color="#f8625c"
          style={{ marginRight: 8 }}
        />
      ),
    },
  ],
]);

export function FormFeedback({
  feedbackText,
  handleChangeFeedbackText,
  handleClose,
  clearFeedbackText,
  path,
}) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.Client.loadingFeedback);
  const feedbackSent = useSelector((state) => state.Client.feedbackSent);

  const [page, setPage] = React.useState(menuItems.get(path).name || 'Outros');

  const handleChange = (event) => {
    setPage(event.target.value);
  };

  useEffect(() => {
    if (feedbackSent) {
      clearFeedbackText();
      dispatch(sendFeedbackReset());
      handleClose();
    }
  }, [clearFeedbackText, dispatch, feedbackSent, handleClose]);

  return (
    <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <RiFeedbackLine size="24" color="#f8625c" style={{ marginRight: 15 }} />
        Feedback
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Aqui você pode nos enviar um feedback rápido sobre sua experiência e
          sugestões de melhoria para o ST Insights.
        </DialogContentText>
        <Divider style={{ marginBottom: '1rem' }} />
        <FormControl fullWidth>
          <InputLabelOrange id="feedback-select-label">
            Feedback relacionado à página de:
          </InputLabelOrange>
          <Select
            labelId="feedback-select-label"
            value={page}
            onChange={handleChange}
            renderValue={(value) => {
              const { icon } = Array.from(menuItems.values()).find(
                (menuItem) => menuItem.name === value
              );
              return (
                <React.Fragment key={value}>
                  {icon || (
                    <HiOutlineDocumentReport
                      size="18"
                      color="#f8625c"
                      style={{ marginRight: 8 }}
                    />
                  )}
                  {value}
                </React.Fragment>
              );
            }}
          >
            {Array.from(menuItems.values()).map((item) => {
              if (item.path !== '/insights/config') {
                return (
                  <MenuItem value={item.name} key={item.name}>
                    {item.icon}
                    {item.name}
                  </MenuItem>
                );
              }
              return false;
            })}
          </Select>
        </FormControl>

        <br />
        <br />

        <CssTextField
          value={feedbackText}
          onChange={handleChangeFeedbackText}
          autoFocus
          onFocus={(e) => {
            // reset texto input focar no final do texto.
            const val = e.target.value;
            e.target.value = '';
            e.target.value = val;
          }}
          margin="dense"
          id="feedback-text"
          label="Escreva aqui seu feedback"
          fullWidth
          multiline
          variant="outlined"
          rows={4}
        />
      </DialogContent>
      <DialogActions style={{ padding: 24 }}>
        <OutlinedButton onClick={handleClose} color="primary">
          Cancelar
        </OutlinedButton>
        <ContainedButton
          style={{ width: 90 }}
          onClick={() => {
            dispatch(sendFeedbackRequest(page, feedbackText));
          }}
          color="primary"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: 'white' }} />
          ) : (
            'Enviar'
          )}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

FormFeedback.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleChangeFeedbackText: PropTypes.func.isRequired,
  clearFeedbackText: PropTypes.func.isRequired,
  feedbackText: PropTypes.string,
  path: PropTypes.string,
};

FormFeedback.defaultProps = {
  path: '/',
  feedbackText: '',
};

export default FormFeedback;
