import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { MdSearch, MdClearAll } from 'react-icons/md';
import { RiDriveLine } from 'react-icons/ri';
import Pagination from '@material-ui/lab/Pagination';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Container,
  HeaderChart,
  ContainerProfile,
  MultipleSelect,
  Legenda,
  SearchUser,
  LoadingArea,
  MuiButtonGroup,
  PeriodButton,
} from './styles';
import { getUserProfilesDataRequest } from '~/store/modules/ClientData/actions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    '&:after': {
      borderBottom: '2px solid #f8625c',
    },
  },
}));

export default function CardAreaChart() {
  const dispatch = useDispatch();
  const { currentUserClient, currentOrgUnits } = useSelector(
    (state) => state.Client
  );
  const classes = useStyles();

  const { data: userProfilesData, loading } = useSelector(
    (state) => state.ClientData.userProfilesData
  );

  const [dataType, setDataType] = useState('all_items');
  const [sortedBy, setSortedBy] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [filterOu, setFilterOu] = useState(currentOrgUnits[0]);
  const [filter, setFilter] = useState({
    period: 'trimester',
  });
  const [paginatedProfiles, setPaginatedProfiles] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPaginatedProfiles([]);
      setPage(value);
    }
  };

  const selectOptions = useMemo(
    () =>
      userProfilesData
        ? userProfilesData
            .map((rankingUser) => ({
              value: rankingUser.profile_id,
              label: rankingUser.name,
            }))
            .sort((user1, user2) => (user1.label > user2.label ? 1 : -1))
        : [],
    [userProfilesData]
  );

  useEffect(() => {
    setPage(1);
  }, [pageCount]);

  useEffect(() => {
    const countDouble = profiles.length / 25;
    const countInt = parseInt(countDouble, 10);

    const count = countDouble > countInt ? countInt + 1 : countInt;

    setPageCount(count);
  }, [profiles]);

  useEffect(() => {
    const splicedProfiles = [...profiles].splice((page - 1) * 25, 25);
    setPaginatedProfiles(splicedProfiles);
  }, [page, profiles]);

  useEffect(() => {
    let result = [];

    if (userProfilesData) {
      result = [...userProfilesData];

      if (filteredUsers && filteredUsers.length > 0) {
        result = result.filter((item) =>
          filteredUsers.find(
            (filteredUser) => filteredUser.value === item.profile_id
          )
        );
      }
      result = result.filter(
        (profile) =>
          profile.items_created_percent[dataType] ||
          profile.items_shared_percent[dataType] ||
          profile.items_viewed_percent[dataType] ||
          profile.items_edited_percent[dataType]
      );

      result = result.map((profile) => ({
        profile_id: profile.profile_id,
        name: profile.name,
        items_created_percent: profile.items_created_percent[dataType],
        items_shared_percent: profile.items_shared_percent[dataType],
        items_viewed_percent: profile.items_viewed_percent[dataType],
        items_edited_percent: profile.items_edited_percent[dataType],
      }));

      if (sortedBy) {
        result.sort((a, b) => {
          let sort = 0;

          if (a[sortedBy] > b[sortedBy]) {
            sort = -1;
          } else if (a[sortedBy] < b[sortedBy]) {
            sort = 1;
          }

          return sort;
        });
      } else if (result) {
        result.sort((a, b) => a.name.localeCompare(b.name));
      }
    }

    setProfiles(result);
  }, [userProfilesData, filteredUsers, page, dataType, sortedBy]);

  function handlerSort(e) {
    setSortedBy(e.target.id);
  }

  const renderValue = useCallback((item) => {
    const { x, y, value } = item;
    return (
      <text
        x={x + 10}
        y={y + 18}
        dx={10}
        dy={-4}
        fontSize="14"
        fontFamily="sans-serif"
        fontWeight="bolder"
        fill="#fff"
        textAnchor="middle"
      >
        {value}%
      </text>
    );
  }, []);

  const calculateGraphicValues = useCallback((values = {}) => {
    let arrayCopy = [...Object.entries(values).sort((a, b) => b - a)];
    const minimumPercentage = 5;

    let excessAmount = 0;
    let unchangedTotalAmount = 0;
    let percentToCalcReadjustment = 0;
    let count = 0;

    arrayCopy = arrayCopy.map((value) => {
      const result = value;

      if (value[1] < minimumPercentage) {
        count += 1;
        excessAmount += minimumPercentage - value[1];

        value[1] = minimumPercentage;
      } else if (value[1] > minimumPercentage) {
        unchangedTotalAmount += value[1];
      }

      return result;
    });

    if (count > 0) {
      percentToCalcReadjustment = excessAmount / unchangedTotalAmount;

      arrayCopy = arrayCopy.map((value) => {
        const result = value;

        if (value[1] > minimumPercentage) {
          value[1] -= value[1] * percentToCalcReadjustment;
        }

        return result;
      });
    }

    return arrayCopy;
  }, []);

  function changeDataType(e) {
    setDataType(e.target.value);
  }

  function handleChangeOu(e) {
    setFilterOu(e.target.value);
  }

  useEffect(() => {
    dispatch(
      getUserProfilesDataRequest(currentUserClient, filterOu, filter.period)
    );
  }, [currentUserClient, dispatch, filterOu, filter]);

  const barChartData = useMemo(
    () =>
      paginatedProfiles.map((profile) => {
        let result = {};

        if (
          profile.items_created_percent > 0 ||
          profile.items_shared_percent > 0 ||
          profile.items_edited_percent > 0 ||
          profile.items_viewed_percent > 0
        ) {
          result = {
            ...profile,
            ...Object.fromEntries(
              calculateGraphicValues({
                graphic_items_created_percent: profile.items_created_percent,
                graphic_items_shared_percent: profile.items_shared_percent,
                graphic_items_edited_percent: profile.items_edited_percent,
                graphic_items_viewed_percent: profile.items_viewed_percent,
              })
            ),
          };
        }

        return result;
      }),
    [calculateGraphicValues, paginatedProfiles]
  );
  const { dataMonths } = useSelector((state) => state.Client.userClient);

  return (
    <Container>
      <HeaderChart>
        <div className="title">
          <RiDriveLine size="30" />
          <span>
            <p> Perfil no G Drive</p>
            <small>
              proporção de atividades nos{' '}
              <small>
                {(filter.period === 'year' && `últimos ${dataMonths} meses`) ||
                  (filter.period === 'trimester' && 'últimos 3 meses') ||
                  'últimos 30 dias'}
              </small>
            </small>
          </span>
        </div>
        <FormControl
          style={{
            width: '400px',
            margin: '-4px auto',
            borderRight: '2px solid rgba(0, 0, 0, 0.1)',
            paddingRight: '10px',
            marginRight: '10px',
          }}
        >
          <InputLabel
            id="profile-collaboration-ou-label"
            htmlFor="profile-collaboration-ou-select"
            style={{ color: '#f8625c' }}
          >
            Unidade Organizacional
          </InputLabel>
          <Select
            labelId="profile-collaboration-ou-label"
            id="profile-collaboration-ou-select"
            value={filterOu}
            onChange={handleChangeOu}
            label="OU"
          >
            {currentOrgUnits.map((ou) => (
              <MenuItem value={ou} key={ou}>
                {ou === '/' ? 'TODAS' : ou}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SearchUser className="search">
          <nav>
            <span className="icon-search">
              <MdSearch color="#333" size={20} />
            </span>
            <MultipleSelect
              isMulti
              name="rankingUsers"
              options={selectOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Filtrar usuários"
              noOptionsMessage={() => 'Não encontrado'}
              onChange={setFilteredUsers}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
            />
          </nav>
        </SearchUser>
        <MuiButtonGroup
          color="secondary"
          aria-label="outlined secondary button group"
        >
          <PeriodButton
            disabled={filter.period === 'month'}
            variant={filter.period === 'month' ? 'contained' : 'outlined'}
            onClick={() => {
              setFilter({ ...filter, period: 'month' });
            }}
          >
            Mês
          </PeriodButton>
          <PeriodButton
            disabled={filter.period === 'trimester'}
            variant={filter.period === 'trimester' ? 'contained' : 'outlined'}
            onClick={() => {
              setFilter({ ...filter, period: 'trimester' });
            }}
          >
            Trimestre
          </PeriodButton>
          <PeriodButton
            disabled={filter.period === 'year'}
            variant={filter.period === 'year' ? 'contained' : 'outlined'}
            onClick={() => {
              setFilter({ ...filter, period: 'year' });
            }}
          >
            Ano
          </PeriodButton>
        </MuiButtonGroup>
      </HeaderChart>
      <hr />
      <div style={{ width: '100%', display: 'flex', marginBottom: '30px' }}>
        <FormControl
          className={classes.formControl}
          style={{ marginLeft: '150px', width: '290px' }}
        >
          <InputLabel
            id="file-type-label"
            htmlFor="file-type-select"
            style={{ color: '#f8625c' }}
          >
            Tipos de Arquivos
          </InputLabel>
          <Select
            labelId="file-type-label"
            id="file-type-select"
            value={dataType}
            className={classes.select}
            onChange={changeDataType}
            label="Tipo"
          >
            <MenuItem value="all_items">TODOS</MenuItem>
            <MenuItem value="docs" name="Google Documentos">
              Google Documentos
            </MenuItem>
            <MenuItem value="sheets" name="Google Planilhas">
              Google Planilhas
            </MenuItem>
            <MenuItem value="presentation" name="Google Apresentações">
              Google Apresentações
            </MenuItem>
            <MenuItem value="pdf" name="Arquivos PDF">
              Arquivos PDF
            </MenuItem>
            <MenuItem value="msoffice" name="Arquivos MS Office">
              Arquivos MS Office
            </MenuItem>
          </Select>
        </FormControl>

        <span className="legenda">
          <Legenda
            id="items_created_percent"
            onClick={handlerSort}
            color="rgb(64, 132, 249)"
            style={{
              marginRight: '30px',
            }}
          >
            <MdClearAll size="18" /> Criador
            <div className="tooltipbox">
              <div className="tooltiptext">
                <p>Arquivos criados pelo próprio usuário</p>
              </div>
            </div>
          </Legenda>
          <Legenda
            id="items_edited_percent"
            onClick={handlerSort}
            color="rgb(214, 74, 58)"
            style={{
              marginRight: '30px',
            }}
          >
            <MdClearAll size="18" /> Contribuinte
            <div className="tooltipbox">
              <div className="tooltiptext">
                <p>Arquivos dos outros editados pelo usuário</p>
              </div>
            </div>
          </Legenda>
          <Legenda
            id="items_viewed_percent"
            onClick={handlerSort}
            color="rgb(255, 210, 80)"
            style={{
              marginRight: '30px',
            }}
          >
            <MdClearAll size="18" /> Consumidor
            <div className="tooltipbox">
              <div className="tooltiptext">
                <p>Arquivos dos outros apenas visualizados</p>
              </div>
            </div>
          </Legenda>
          <Legenda
            id="items_shared_percent"
            onClick={handlerSort}
            color="rgb(20, 146, 133)"
          >
            <MdClearAll size="18" /> Engajador
            <div className="tooltipbox">
              <div className="tooltiptext">
                <p>Arquivos próprios compartilhados com os outros</p>
              </div>
            </div>
          </Legenda>
        </span>
      </div>
      <ContainerProfile>
        {pageCount ? (
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChange}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 15,
            }}
          />
        ) : null}
        {(!loading && (
          <ResponsiveContainer
            width="100%"
            height={30 * barChartData.length + 20}
          >
            <BarChart
              layout="vertical"
              data={barChartData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 14,
              }}
              stackOffset="expand"
              key={Math.random()}
            >
              <XAxis hide type="number" />
              <YAxis type="category" dataKey="name" stroke="#000" width={170} />
              <Bar
                dataKey="graphic_items_created_percent"
                stackId="a"
                fill="rgb(64, 132, 249)"
                barSize={20}
              >
                <LabelList
                  position="inside"
                  offset={5}
                  dataKey="items_created_percent"
                  content={renderValue}
                />
              </Bar>
              <Bar
                dataKey="graphic_items_edited_percent"
                stackId="a"
                fill="rgb(214, 74, 58)"
                barSize={20}
              >
                <LabelList
                  position="inside"
                  offset={5}
                  dataKey="items_edited_percent"
                  content={renderValue}
                />
              </Bar>
              <Bar
                dataKey="graphic_items_viewed_percent"
                stackId="a"
                fill="rgb(255, 210, 80)"
                barSize={20}
              >
                <LabelList
                  position="inside"
                  offset={5}
                  dataKey="items_viewed_percent"
                  content={renderValue}
                />
              </Bar>
              <Bar
                dataKey="graphic_items_shared_percent"
                stackId="a"
                fill="rgb(20, 146, 133)"
                barSize={20}
              >
                <LabelList
                  position="inside"
                  offset={5}
                  dataKey="items_shared_percent"
                  content={renderValue}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )) || (
          <LoadingArea>
            <CircularProgress style={{ color: '#f8625c' }} />
          </LoadingArea>
        )}
        {pageCount ? (
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChange}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 15,
            }}
          />
        ) : null}
      </ContainerProfile>
    </Container>
  );
}
