import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { MdApps } from 'react-icons/md';
import {
  XAxis,
  Tooltip,
  CartesianGrid,
  YAxis,
  Label,
  ComposedChart,
  Bar,
} from 'recharts';
import PropTypes from 'prop-types';
import {
  Container,
  HeaderChart,
  ResponsiveContainerChart,
  NoData,
} from './styles';

export default function CardAreaChart({ keyName, color, itemNamePT, filter }) {
  const { data } = useSelector(
    (state) => state.ClientData.clientDocsCreateData
  );
  const monthNames = useMemo(
    () => [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    []
  );

  const opaqueColors = {
    'rgb(82, 143, 245)': 'rgba(82, 143, 245, 0.6)',
    'rgb(15, 157, 88)': 'rgba(15, 157, 88, 0.6)',
    'rgb(120, 80, 193)': 'rgba(120, 80, 193, 0.6)',
    'rgb(245, 186, 21)': 'rgba(245, 186, 21, 0.6)',
    'rgb(225, 100, 110)': 'rgba(225, 100, 110, 0.6)',
    'rgb(130, 130, 130)': 'rgba(130, 130, 130, 0.6)',
  };

  return data.length > 0 ? (
    <Container>
      <HeaderChart>
        <MdApps size="24" /> {itemNamePT}
      </HeaderChart>
      <ResponsiveContainerChart width="100%" height={260}>
        <ComposedChart
          data={data || []}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            wrapperStyle={{
              width: '220px',
              fontSize: 11,
              fontWeight: 'bolder',
            }}
            labelFormatter={(value) => {
              if (filter.period === 'year') {
                return monthNames[parseISO(value).getMonth()];
              }
              if (filter.period === 'trimester') {
                return `Semana terminada em ${format(
                  parseISO(value),
                  'dd/MM'
                )}`;
              }

              return format(parseISO(value), 'dd/MM');
            }}
          />

          <YAxis
            type="number"
            allowDecimals={false}
            label={{
              value: 'Quantidade de arquivos',
              angle: -90,
              offset: 18,
              position: 'insideBottomLeft',
            }}
          />
          <XAxis
            dataKey="date.value"
            padding={{ left: 5, right: 5 }}
            tickFormatter={(date) =>
              filter.period === 'year'
                ? monthNames[parseISO(date).getMonth()].substring(0, 3)
                : format(date ? parseISO(date) : new Date(), 'dd/MM')
            }
            angle={-35}
            textAnchor="end"
            interval={
              (filter.period === 'month' && 2) ||
              (filter.period === 'trimester' && 1) ||
              0
            }
            style={{ fontSize: 11, fontWeight: 'bold' }}
            height={50}
          >
            <Label
              style={{ fontSize: 12 }}
              value={
                (filter.period === 'year' && 'Meses') ||
                (filter.period === 'trimester' && 'Semanas') ||
                'Dias'
              }
              offset={0}
              position="insideBottom"
            />
          </XAxis>
          <Bar
            dataKey={`${keyName}_not_shared_drive`}
            stackId="amt"
            name="Total no Drive Pessoal"
            fill={color}
            barSize={20}
          />
          <Bar
            dataKey={`${keyName}_shared_drive`}
            stackId="amt"
            name="Total no Drive Compartilhado"
            fill={opaqueColors[color]}
            barSize={20}
          />
        </ComposedChart>
      </ResponsiveContainerChart>
    </Container>
  ) : (
    <NoData>Sem dados</NoData>
  );
}

CardAreaChart.propTypes = {
  keyName: PropTypes.string,
  color: PropTypes.string,
  itemNamePT: PropTypes.string,
  filter: PropTypes.shape({ period: PropTypes.string }),
};

CardAreaChart.defaultProps = {
  keyName: 'no_key_name',
  color: 'rgb(130,130,130)',
  itemNamePT: 'Não Definido',
  filter: { period: 'year' },
};
