import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdFilterTiltShift } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import meet from '~/assets/icons/meet.svg';
import ChartAppUsageDetailsBar from '~/components/Dashboard/Organization/Chart/AppUsageDetails/Bar';
import ChartAppUsageDetailsLine from '~/components/Dashboard/Organization/Chart/AppUsageDetails/Line';
import {
  Container,
  MuiButtonGroup,
  ContainerGrid,
  LoadingArea,
  PeriodButton,
  App30Days,
  ContainerCard,
  HeaderChart,
  HeaderApp,
  NoData,
  TitleText,
} from './styles';
import { getAppUsageDetailsDataRequest } from '~/store/modules/ClientData/actions';

export default function AppUsageCharts() {
  const dispatch = useDispatch();
  const { loading, ...data } = useSelector(
    (state) => state.ClientData.clientAppUsageDetailsData
  );

  const profile = useSelector((state) => state.User.profile);
  const { currentUserClient, currentOrgUnits } = useSelector(
    (state) => state.Client
  );
  const [filter, setFilter] = useState({
    period: 'trimester',
    ou: currentOrgUnits[0],
  });

  const customer_id = useMemo(
    () => (profile ? currentUserClient || profile.client_id.name : null),
    [currentUserClient, profile]
  );

  function handleChangeOu(e) {
    setFilter({ ...filter, ou: e.target.value });
  }

  useEffect(() => {
    if (customer_id) {
      dispatch(getAppUsageDetailsDataRequest(customer_id, filter));
    }
  }, [filter, customer_id, dispatch]);

  const { dataMonths } = useSelector((state) => state.Client.userClient);

  return (
    <>
      <Container>
        <HeaderChart>
          <div className="title">
            <TitleText>
              <MdFilterTiltShift size="24" />
              <span>
                <p>Detalhes do uso de aplicativos</p>
                <small>
                  {(filter.period === 'year' &&
                    `últimos ${dataMonths} meses`) ||
                    (filter.period === 'trimester' && 'últimos 3 meses') ||
                    'últimos 30 dias'}
                </small>
              </span>
            </TitleText>
            <FormControl
              // className={classes.formControl}
              style={{
                width: '400px',
                margin: '-4px auto',
                borderRight: '2px solid rgba(0, 0, 0, 0.1)',
                paddingRight: '10px',
                marginRight: '10px',
                fontWeight: 'normal',
              }}
            >
              <InputLabel
                id="app-usage-details-ou-label"
                htmlFor="app-usage-details-ou-select"
                style={{ color: '#f8625c' }}
              >
                Unidade Organizacional
              </InputLabel>
              <Select
                labelId="app-usage-details-ou-label"
                id="app-usage-details-ou-select"
                value={filter.ou}
                onChange={handleChangeOu}
                label="OU"
              >
                {currentOrgUnits.map((ou) => (
                  <MenuItem value={ou} key={ou}>
                    {ou === '/' ? 'TODAS' : ou}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MuiButtonGroup
              color="secondary"
              aria-label="outlined secondary button group"
            >
              <PeriodButton
                disabled={filter.period === 'month'}
                variant={filter.period === 'month' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter({ ...filter, period: 'month' });
                }}
              >
                Mês
              </PeriodButton>
              <PeriodButton
                disabled={filter.period === 'trimester'}
                variant={
                  filter.period === 'trimester' ? 'contained' : 'outlined'
                }
                onClick={() => {
                  setFilter({ ...filter, period: 'trimester' });
                }}
              >
                Trimestre
              </PeriodButton>
              <PeriodButton
                disabled={filter.period === 'year'}
                variant={filter.period === 'year' ? 'contained' : 'outlined'}
                onClick={() => {
                  setFilter({ ...filter, period: 'year' });
                }}
              >
                Ano
              </PeriodButton>
            </MuiButtonGroup>
          </div>
        </HeaderChart>
        <hr />
        <HeaderApp>
          <img src={meet} alt="" />
          <h3>Google Meet</h3>
        </HeaderApp>
        {(!loading && (
          <ContainerGrid>
            <ContainerCard>
              {data &&
              data.internalAndExternalMeet &&
              data.internalAndExternalMeet.length > 0 ? (
                <App30Days>
                  <ChartAppUsageDetailsBar
                    data={data ? data.internalAndExternalMeet : []}
                    xAxisDataKey="date.value"
                    barKeys={[
                      {
                        dataKey: 'amt_external_meeting',
                        name: 'Reuniões externas',
                        fill: 'rgb(20, 146, 133)',
                      },
                      {
                        dataKey: 'amt_internal_meeting',
                        name: 'Reuniões internas',
                        fill: 'rgb(0, 86, 83)',
                      },
                    ]}
                    itemNamePT="Quantidade de reuniões internas e externas"
                    filter={filter}
                  />
                </App30Days>
              ) : (
                <NoData>Sem dados</NoData>
              )}
            </ContainerCard>
            <ContainerCard>
              {data &&
              data.durationAndParticipantMeet &&
              data.durationAndParticipantMeet.length > 0 ? (
                <App30Days>
                  <ChartAppUsageDetailsLine
                    data={data ? data.durationAndParticipantMeet : []}
                    xAxisDataKey="date.value"
                    lineKeys={[
                      {
                        dataKey: 'avg_meet_duration',
                        name: 'Duração média das reuniões',
                        stroke: 'rgba(20, 146, 133, 1)',
                      },
                    ]}
                    itemNamePT="Duração média das meetings (em minutos)"
                    filter={filter}
                  />
                </App30Days>
              ) : (
                <NoData>Sem dados</NoData>
              )}
            </ContainerCard>
            <ContainerCard>
              {data &&
              data.durationAndParticipantMeet &&
              data.durationAndParticipantMeet.length > 0 ? (
                <App30Days>
                  <ChartAppUsageDetailsLine
                    data={data ? data.durationAndParticipantMeet : []}
                    xAxisDataKey="date.value"
                    lineKeys={[
                      {
                        dataKey: 'avg_meet_participants',
                        name: 'Média de participantes',
                        stroke: 'rgba(20, 146, 133, 1)',
                      },
                    ]}
                    itemNamePT="Quantidade média de participantes por meet"
                    filter={filter}
                  />
                </App30Days>
              ) : (
                <NoData>Sem dados</NoData>
              )}
            </ContainerCard>
          </ContainerGrid>
        )) || (
          <LoadingArea>
            <CircularProgress style={{ color: '#f8625c' }} />
          </LoadingArea>
        )}
      </Container>
    </>
  );
}
