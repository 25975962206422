import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MdSearch, MdClose } from 'react-icons/md';

import default_icon from '~/assets/sem-imagem-avatar.png';
import {
  Overlay,
  ModalCard,
  Wraper,
  Header,
  SearchUser,
  ContainerUsers,
  Table,
  Content,
} from './styles';

export default function Modal({ isShowing, hide }) {
  const data = useSelector((state) => state.ClientData.clientStatesData.data);

  const [searchEmail, setSearchEmail] = useState('');

  const allUsers = useMemo(() => {
    const usersSuspendedAndArchived = {
      usersSuspended: [],
      usersArchived: [],
    };

    if (data && data.users_suspended) {
      const usersSuspended = [...data.users_suspended];

      usersSuspended.sort((a, b) => a.name.localeCompare(b.name));

      usersSuspendedAndArchived.usersSuspended = usersSuspended;
    }

    if (data && data.users_archived) {
      const usersArchived = [...data.users_archived];

      usersArchived.sort((a, b) => a.name.localeCompare(b.name));

      usersSuspendedAndArchived.usersArchived = usersArchived;
    }

    setSearchEmail('');

    return usersSuspendedAndArchived;
  }, [data]);

  const filteredUsers = useMemo(() => {
    const result = { ...allUsers };

    if (searchEmail !== '') {
      result.usersSuspended = result.usersSuspended.filter((user) => {
        const lc = user.email.toLowerCase();
        const filter = searchEmail.toLowerCase();

        return lc.includes(filter);
      });

      result.usersArchived = result.usersArchived.filter((user) => {
        const lc = user.email.toLowerCase();
        const filter = searchEmail.toLowerCase();

        return lc.includes(filter);
      });
    }

    return result;
  }, [allUsers, searchEmail]);

  function handlerGetUser(e) {
    setSearchEmail(e.target.value);
  }

  return isShowing ? (
    <>
      <Overlay />
      <Wraper>
        <ModalCard>
          <Header>
            <span>
              <p>Licenças suspensas e arquivadas</p>
            </span>
            <button type="button" onClick={hide}>
              <MdClose color="#f75a48" size={30} />
            </button>
          </Header>
          <SearchUser>
            <nav>
              <span>
                <MdSearch color="#333" size={20} />
              </span>
              <input
                type="text"
                placeholder="Buscar email"
                value={searchEmail}
                onChange={handlerGetUser}
              />
            </nav>
          </SearchUser>
          <Content>
            <ContainerUsers>
              <Table>
                <tbody>
                  {filteredUsers.usersArchived.map((myuser) => (
                    <tr key={`${myuser.email}_archived`}>
                      <td style={{ textAlign: 'center', width: '200px' }}>
                        <img
                          src={
                            myuser.thumbnail ? myuser.thumbnail : default_icon
                          }
                          alt=""
                        />
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {myuser.name}
                        <br />
                        {myuser.email}
                      </td>
                      <td>Arquivado</td>
                    </tr>
                  ))}
                  {filteredUsers.usersSuspended.map((myuser) => (
                    <tr key={`${myuser.email}_suspended`}>
                      <td style={{ textAlign: 'center', width: '200px' }}>
                        <img
                          src={
                            myuser.thumbnail ? myuser.thumbnail : default_icon
                          }
                          alt=""
                        />
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {myuser.name}
                        <br />
                        {myuser.email}
                      </td>
                      <td>Suspenso</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ContainerUsers>
          </Content>
        </ModalCard>
      </Wraper>
    </>
  ) : null;
}

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};
