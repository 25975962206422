import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import logo from '~/assets/logo2.png';

export const toPdf = (element, user, domain, pageName) => {
  toast.info('📄 Gerando PDF deste painel... Aguarde', {
    autoClose: 7000,
  });
  const doc = new JsPDF('p', 'px', 'a4', true);

  const { name } = user;

  const dateTime = new Date();

  const date = format(dateTime, 'dd/MM/yyyy');
  const hour = `${dateTime.getHours()}:${
    (dateTime.getMinutes() < 10 ? '0' : '') + dateTime.getMinutes()
  }`;
  const pdfDate = `${date} às ${hour}`;

  html2canvas(element, {
    scale: 2,
  }).then((canvas) => {
    const image = canvas.toDataURL('image/png', 1.0);

    // Adaptando a imagem para caber no pdf

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const widthRatio = pageWidth / canvas.width;
    const heightRatio = pageHeight / canvas.height;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = canvas.width * ratio;
    const canvasHeight = canvas.height * ratio;

    const marginX = (pageWidth - canvasWidth) / 2;
    // let marginY = (pageHeight - canvasHeight) / 2;

    if (canvasHeight > 540) {
      // marginY += (canvasHeight - 540) / 2;
      doc.internal.pageSize.height = pageHeight + (canvasHeight - 540) / 2;
    }

    doc.setFontSize(12);
    doc.setTextColor('#4F4F4F');
    doc.addImage(logo, 'PNG', 15, 10, 80, 20);
    doc.text('INDICADORES DE ADOÇÃO - ST INSIGHTS', 135, 20);

    doc.setFontSize(10);
    doc.text(domain, 190, 30);

    let nameMargin = 382;

    if (name.length > 15) {
      nameMargin -= (name.length - 15) * 3;
    } else {
      nameMargin += (15 - name.length) * 3;
    }

    doc.text(name, nameMargin, 20);
    doc.text(pdfDate, 370, 30);

    doc.addImage(
      image,
      'PNG',
      marginX,
      50,
      canvasWidth,
      canvasHeight,
      '',
      'FAST'
    );

    doc.save(pageName);
    // doc.output('dataurlnewwindow');
  });
};
