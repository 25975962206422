import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BackLink = styled(Link)`
  position: absolute;
  left: 20px;
  top: 10px;
  padding: 10px 20px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;
export const Container = styled.div`
  width: 100%;
  background: #fff;
  max-width: 1100px;
  min-width: 1100px;
  height: 650px;
  overflow-y: scroll;
`;
export const Content = styled.div`
  width: 70%;
  margin: 0 auto 100px auto;
  font-size: 1.1em;
  line-height: 1.4;
  color: #495057 !important;

  > picture {
    margin-top: 50px;
    text-align: center !important;
    display: block;
    > img {
      width: 30%;
    }
  }
`;
export const HeaderCredentials = styled.div`
  padding: 50px 0 20px 0;
  h1 {
    font-size: 2em !important;
  }
`;

export const MainCredentials = styled.div`
  margin-top: 25px;
  section {
    ul {
      margin-left: 10px;
    }
    margin-top: 25px;
    li {
      img {
        width: 100%;
      }
      a {
        margin-left: 20px;
        background: #1a73e8;
        padding: 10px 20px;
        border-radius: 3px;
        color: #fff;
        svg {
          margin-right: 5px;
        }
      }
      span {
        margin-right: 5px;
      }
    }
  }
  h2 {
    font-size: 1.6em;
  }
  h3 {
    font-size: 1.4em;
  }
`;
export const ItemList = styled.div`
  margin: 15px 0;
  width: 100%;
  display: flex;
`;
export const SubItemList = styled.div`
  margin: 10px 10px;
  width: 70%;
  display: flex;
`;
export const ContentScopes = styled.div`
  width: auto;
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
`;
export const Warning = styled.div`
  width: 100%;
  background: #fce8e6;
  color: #d5000095;
  padding: 20px 50px;
  line-height: 1.4;
  font-size: 14px;
`;
