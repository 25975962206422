import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  align-self: center;
  padding: 5px;

  .tooltipavatar .tooltiptext {
    visibility: hidden;
    background-color: rgba(255, 255, 255, 0.9);
    color: #495057;
    border: 1px solid #333;
    border-radius: 6px;
    text-align: left;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 2px;
    ${(props) => (!props.leftSide ? 'right:60px' : 'left:60px')};
  }

  .tooltiptext {
    min-height: 80px;
    min-width: 250px;
    width: auto;
    white-space: nowrap;
    p {
      color: #495057;
    }
    span {
      font-weight: bolder;
    }
  }

  &:hover .tooltipavatar .tooltiptext {
    visibility: visible;
  }

  img {
    width: 100%;
    border-radius: 50px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .badge {
    position: absolute;
    top: 50px;
    right: 6px;
    padding: 3px 10px;
    border-radius: 7px;
    background-color: #f8625c;
    color: white;
    min-width: 45px;
  }
`;
