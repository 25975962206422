import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { FaRegLightbulb } from 'react-icons/fa';
import { VscFilePdf } from 'react-icons/vsc';
import Skeleton from '@material-ui/lab/Skeleton';
import docs from '~/assets/icons/docs.png';
import forms from '~/assets/icons/forms.png';
import sheets from '~/assets/icons/sheets.png';
import slides from '~/assets/icons/slides.png';
import sites from '~/assets/icons/sites.png';
import currents from '~/assets/icons/currents.svg';
import excel from '~/assets/icons/excel.png';
import powerpoint from '~/assets/icons/powerpoint.png';
import word from '~/assets/icons/word.png';
import duble_auth from '~/assets/dupla_autenticacao.png';
import Avatar from '~/components/User/Avatar';
import api from '~/services/api';
import history from '~/services/history';
import { signOut } from '~/store/modules/Auth/actions';
import { registerAccessLog } from '~/store/modules/User/actions';
import { toPdf } from '~/utils/downloadPDF';

import {
  Container,
  Board,
  CardInsightA,
  ItemData,
  ItemHeader,
  ItemMedia,
  ItemFooter,
  Percent,
  ItemBar,
  ItemSites,
  ItemDataGrid,
  ImageIcon,
  VideoIcon,
  PdfIcon,
  OtherTypeIcon,
  DashboardTitleContainer,
  DashboardTitleText,
  PdfButton,
  TitleContent,
} from './styles';

export default function Insights() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tokenJWT = useSelector((state) => state.Auth.token);

  const loginTime = useSelector((state) => state.Auth.loginTime);

  const moreThan24h = (new Date() - new Date(loginTime)) / 36e5 > 24;

  if (moreThan24h || !loginTime) {
    toast.warn('Sua sessão expirou. Logue novamente.', { autoClose: 5000 });
    dispatch(signOut());
    history.push('/');
  }

  if (tokenJWT) {
    api.defaults.headers.common.Authorization = `Bearer ${tokenJWT}`;
  }

  const client = useSelector((state) => state.Client.userClient);
  const currentClient = useSelector((state) => state.Client.currentUserClient);
  const currentDomain = useSelector(
    (state) => state.Client.currentUserClientDomain
  );
  const profile = useSelector((state) => state.User.profile);
  const [insights, setInsights] = useState();

  useEffect(() => {
    async function loadData() {
      const customer_id = currentClient || client.client_id;
      if (client) {
        try {
          const response = await api.get(`/insights/${customer_id}`);
          setInsights(response.data);
        } catch (err) {
          put(signOut());
          history.push('/');
        }
      }
    }
    // armazenar logs de acesso
    dispatch(registerAccessLog('Insights'));

    loadData();
  }, [client, client.client_id, currentClient, dispatch]);

  const data = [
    {
      name: 'Documentos',
      creators:
        insights && insights.drive_usage
          ? insights.drive_usage.media_creators_docs
          : 0,
      users:
        insights && insights.drive_usage
          ? insights.drive_usage.media_users_docs
          : 0,
    },

    {
      name: 'Planilhas',
      creators:
        insights && insights.drive_usage
          ? insights.drive_usage.media_creators_spreadsheets
          : 0,
      users:
        insights && insights.drive_usage
          ? insights.drive_usage.media_users_spreadsheets
          : 0,
    },

    {
      name: 'Formulários',
      creators:
        insights && insights.drive_usage
          ? insights.drive_usage.media_creators_forms
          : 0,
      users:
        insights && insights.drive_usage
          ? insights.drive_usage.media_users_forms
          : 0,
    },

    {
      name: 'Apresentações',
      creators:
        insights && insights.drive_usage
          ? insights.drive_usage.media_creators_presentations
          : 0,
      users:
        insights && insights.drive_usage
          ? insights.drive_usage.media_users_presentations
          : 0,
    },
  ];

  const dataShareExt = [
    {
      name: 'Nomeados',
      value:
        insights && insights.doc_share_ext
          ? insights.doc_share_ext.compartilhado_externamente
          : 0,
    },
    {
      name: 'Via link',
      value:
        insights && insights.doc_share_ext
          ? insights.doc_share_ext.compartilhado_ext_link
          : 0,
    },
    {
      name: 'Públicos',
      value:
        insights && insights.doc_share_ext
          ? insights.doc_share_ext.compartilhado_ext_publico
          : 0,
    },
  ];
  const dataUploadMs = [
    {
      name: 'Word',
      value:
        insights && insights.uploads_ms
          ? insights.uploads_ms.media_creators_msword
          : 0,
    },
    {
      name: 'Excel',
      value:
        insights && insights.uploads_ms
          ? insights.uploads_ms.media_creators_msexcel
          : 0,
    },
    {
      name: 'Powerpoint',
      value:
        insights && insights.uploads_ms
          ? insights.uploads_ms.media_creators_mspowerpoint
          : 0,
    },
  ];

  const dataUploadOtherTypes = [
    {
      name: 'Imagens',
      value:
        insights && insights.uploads_other
          ? insights.uploads_other.media_creators_image
          : 0,
    },
    {
      name: 'Vídeos',
      value:
        insights && insights.uploads_other
          ? insights.uploads_other.media_creators_video
          : 0,
    },
    {
      name: 'PDF',
      value:
        insights && insights.uploads_other
          ? insights.uploads_other.media_creators_pdf
          : 0,
    },
    {
      name: 'Outros',
      value:
        insights && insights.uploads_other
          ? insights.uploads_other.media_creators_other
          : 0,
    },
  ];

  function OtherTypesCategoryTick(value) {
    switch (value.index) {
      case 0:
        return (
          <foreignObject x={value.x - 15} y={value.y} width={30} height={30}>
            <ImageIcon />
          </foreignObject>
        );
      case 1:
        return (
          <foreignObject x={value.x - 15} y={value.y} width={30} height={30}>
            <VideoIcon />
          </foreignObject>
        );
      case 2:
        return (
          <foreignObject x={value.x - 15} y={value.y} width={30} height={30}>
            <PdfIcon />
          </foreignObject>
        );

      case 3:
        return (
          <foreignObject x={value.x - 15} y={value.y} width={30} height={30}>
            <OtherTypeIcon />
          </foreignObject>
        );
      default:
        return value.index;
    }
  }

  function MSCategoryTick(value) {
    switch (value.index) {
      case 0:
        return (
          <foreignObject x={value.x - 15} y={value.y} width={30} height={30}>
            <img src={word} width="25px" alt="" />
          </foreignObject>
        );
      case 1:
        return (
          <foreignObject x={value.x - 15} y={value.y} width={30} height={30}>
            <img src={excel} width="25px" alt="" />
          </foreignObject>
        );
      case 2:
        return (
          <foreignObject x={value.x - 15} y={value.y} width={30} height={30}>
            <img src={powerpoint} width="25px" alt="" />
          </foreignObject>
        );
      default:
        return value.index;
    }
  }

  function CategoryTick(value) {
    switch (value.index) {
      case 0:
        return (
          <foreignObject x={value.x - 10} y={value.y} width={20} height={30}>
            <img src={docs} width="110%" alt="" />
          </foreignObject>
        );
      case 1:
        return (
          <foreignObject x={value.x - 10} y={value.y} width={20} height={30}>
            <img src={sheets} width="110%" alt="" />
          </foreignObject>
        );
      case 2:
        return (
          <foreignObject x={value.x - 10} y={value.y} width={20} height={30}>
            <img src={forms} width="110%" alt="" />
          </foreignObject>
        );
      case 3:
        return (
          <foreignObject x={value.x - 10} y={value.y} width={20} height={30}>
            <img src={slides} width="110%" alt="" />
          </foreignObject>
        );

      default:
        return value.index;
    }
  }

  const getIntroOfPage = (label) => {
    if (label === 'Públicos') {
      return 'Acessíveis ao público na web';
    }

    if (label === 'Via link') {
      return 'Qualquer pessoa com link pode acessar';
    }

    if (label === 'Nomeados') {
      return 'Compartilhados com usuários fora do domínio';
    }

    return '';
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${
            payload[0].value ? payload[0].value : 0
          } ${label}`}</p>

          <p className="intro">{getIntroOfPage(label)}</p>
        </div>
      );
    }
    return null;
  };

  CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string,
        dataKey: PropTypes.string,
        fill: PropTypes.string,
        formatter: PropTypes.string,
        name: PropTypes.string,
        payload: PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.number,
        }),
        type: PropTypes.string,
        unit: PropTypes.string,
        value: PropTypes.number,
      })
    ),
    label: PropTypes.string,
  };

  CustomTooltip.defaultProps = {
    active: false,
    payload: [],
    label: '',
  };

  const TooltipDrive = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${
            payload[0] ? payload[0].value : 0
          }% criaram:`}</p>
          <p className="label">{` ${
            payload[0] ? payload[0].payload.name : ''
          }`}</p>
        </div>
      );
    }
    return null;
  };

  TooltipDrive.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string,
        dataKey: PropTypes.string,
        fill: PropTypes.string,
        formatter: PropTypes.string,
        name: PropTypes.string,
        payload: PropTypes.shape({
          name: PropTypes.string,
          creators: PropTypes.number,
          users: PropTypes.number,
        }),
        type: PropTypes.string,
        unit: PropTypes.string,
        value: PropTypes.number,
      })
    ),
  };

  TooltipDrive.defaultProps = {
    active: false,
    payload: [],
  };

  let disableDownload = false;

  function downloadPdf() {
    if (!disableDownload) {
      disableDownload = true;

      const domain = currentDomain
        ? currentDomain.split('@')[1]
        : `${profile.email.split('@')[1]}`;

      toPdf(
        document.getElementById('pageInsights'),
        profile,
        domain,
        'sti-pagina-insights'
      );
      disableDownload = false;
    }
  }

  return (
    <>
      <Container id="pageInsights">
        <DashboardTitleContainer>
          <TitleContent>
            <FaRegLightbulb size="50" color="#fff" />
            <DashboardTitleText>Insights</DashboardTitleText>
          </TitleContent>
          <PdfButton>
            <VscFilePdf size="30" color="#fff" onClick={downloadPdf} />
          </PdfButton>
        </DashboardTitleContainer>
        {!insights && (
          <Board>
            <CardInsightA order={1}>
              <ItemData
                className="boilerplate desktop-only"
                style={{ marginTop: -32 }}
              >
                <Skeleton animation="wave" height={100} />
                <br />
                <Skeleton width={340} />
                <Skeleton animation="wave" width={342} />
                <Skeleton width={338} />
                <Skeleton animation="wave" width={330} />
                <Skeleton width={330} />
                <Skeleton animation="wave" width={335} />
              </ItemData>

              <ItemData className="boilerplate" style={{ marginTop: -20 }}>
                <Skeleton animation="wave" height={40} />
                <br />
                <br />
                <br />
                <Skeleton animation="wave" height={40} />
              </ItemData>

              <ItemData
                className="boilerplate desktop-only tablet-hide"
                style={{ marginTop: -20 }}
              >
                <Skeleton animation="wave" height={40} />
                <br />
                <br />
                <Skeleton variant="rect" height={180} />
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.drive_usage && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Uso do Google Drive</h3>
                </ItemHeader>

                <p>
                  Lembramos que o uso do Google Drive pode trazer redução de
                  custos com armazenamento de arquivos, facilidade de pesquisa e
                  versionamento, além de permitir um trabalho mais colaborativo,
                  com mais de uma pessoa trabalhando no mesmo documento,
                  independente de lugar ou dispositivo. Algumas vezes, os
                  usuários podem estar guardando arquivos em outros locais ou
                  dispositivos, distantes do controle e segurança esperados pela
                  empresa.
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>Usuários ativos no google drive</ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.drive_usage.media : 0}%
                  </ItemMedia>
                  <p>
                    No último trimestre, em média{' '}
                    {insights ? insights.drive_usage.media : 0}% dos usuários
                    criaram arquivos nativos no Google Drive
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.drive_usage.media : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Usuários</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.drive_usage.num_users}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData className="desktop-only tablet-hide">
                <ItemHeader center>
                  % usuários que criaram cada tipo de arquivo
                </ItemHeader>
                <ResponsiveContainer minHeight="100%" width="100%">
                  <BarChart
                    width={400}
                    height={250}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="none"
                      axisLine={false}
                      tickLine={false}
                      tick={CategoryTick}
                    />
                    <YAxis
                      domain={[0, 100]}
                      tickFormatter={(percentage) => `${percentage} %`}
                    />
                    <Tooltip content={<TooltipDrive />} />
                    <Bar
                      unit="%"
                      dataKey="creators"
                      fill="rgba(64,132,249,0.9)"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.uploads_ms && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Arquivos MS Office</h3>
                </ItemHeader>

                <p>
                  O G Suite permite a conversão direta dos arquivos MS Office
                  para arquivos nativos do Google Drive como documentos,
                  planilhas e apresentações, mantendo mesmos conteúdos e
                  formatações, com raríssimas exceções. Continuar a usar o MS
                  Office implica em maior gasto de licenciamento e maior consumo
                  da cota de armazenamento do GDrive (se licença Basic).
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>
                  Usuários que gravaram arquivos MS Office no Google Drive
                </ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.uploads_ms.media_ms : 0}%
                  </ItemMedia>
                  <p>
                    No último trimestre, em média{' '}
                    {insights ? insights.uploads_ms.media_ms : 0}% dos usuários
                    gravaram arquivos MS Office no Google Drive sem convertê-los
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.uploads_ms.media_ms : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Usuários</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.uploads_ms.num_users_ms}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData className="desktop-only tablet-hide">
                <ItemHeader>
                  % usuários que criaram cada tipo de arquivo
                </ItemHeader>
                <ResponsiveContainer minHeight="100%" width="100%">
                  <BarChart
                    width={400}
                    height={250}
                    data={dataUploadMs}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="none"
                      axisLine={false}
                      tickLine={false}
                      tick={MSCategoryTick}
                    />
                    <YAxis
                      domain={[0, 100]}
                      tickFormatter={(percentage) => `${percentage} %`}
                    />
                    <Tooltip content={<TooltipDrive />} />
                    <Bar unit="%" dataKey="value" fill="rgba(64,132,249,0.9)" />
                  </BarChart>
                </ResponsiveContainer>
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.uploads_other && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Outros arquivos</h3>
                </ItemHeader>

                <p>
                  O Google Drive possibilita o armazenamento na nuvem tanto de
                  arquivos nativos do G Suite (documentos, planilhas,
                  apresentações), como outros arquivos criados por aplicativos
                  de terceiros ou levados para a nuvem através de upload.
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>
                  Usuários que criaram arquivos não nativos no Google Drive
                </ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.uploads_other.media_other : 0}%
                  </ItemMedia>
                  <p>
                    No último trimestre, em média{' '}
                    {insights ? insights.uploads_other.media_other : 0}% criaram
                    arquivos não nativos no Google Drive
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.uploads_other.media_other : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Usuários</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.uploads_other.num_users_other}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData className="desktop-only tablet-hide">
                <ItemHeader>
                  % usuários que criaram cada tipo de arquivo
                </ItemHeader>
                <ResponsiveContainer minHeight="100%" width="100%">
                  <BarChart
                    width={400}
                    height={250}
                    data={dataUploadOtherTypes}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="none"
                      axisLine={false}
                      tickLine={false}
                      tick={OtherTypesCategoryTick}
                    />
                    <YAxis
                      domain={[0, 100]}
                      tickFormatter={(percentage) => `${percentage} %`}
                    />
                    <Tooltip content={<TooltipDrive />} />
                    <Bar unit="%" dataKey="value" fill="rgba(64,132,249,0.9)" />
                  </BarChart>
                </ResponsiveContainer>
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.calendar_usage && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Uso da Agenda</h3>
                </ItemHeader>

                <p>
                  O Google Agenda pode facilitar bastante a organização de
                  compromissos, inclusive quando envolver vários participantes e
                  recursos alocados. A organização manual de reuniões consome
                  tempo precioso na verificação de disponibilidades, convites e
                  confirmações, além de dificultar o compartilhamento prévio do
                  conteúdo e documentos envolvidos.
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>Usuários ativos no Google Agenda</ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.calendar_usage.media : 0}%
                  </ItemMedia>
                  <p>
                    No último trimestre, em média{' '}
                    {insights ? insights.calendar_usage.media : 0}% dos usuários
                    criaram compromissos na agenda
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.calendar_usage.media : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Usuários</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.calendar_usage.users_total}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData className="grid desktop-only tablet-hide">
                <ItemHeader>Top 10 usuários Agenda</ItemHeader>
                <ItemDataGrid>
                  {insights && insights.calendar_usage.users
                    ? insights.calendar_usage.users
                        .slice(0, 10)
                        .map((user) => (
                          <Avatar
                            key={`${user.user_id}_calendar_usage`}
                            data={user}
                          />
                        ))
                    : ''}
                </ItemDataGrid>
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.meet_usage && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Uso do Google Meet</h3>
                </ItemHeader>

                <p>
                  O Google Meet possibilita grandes economias com deslocamento e
                  telefonia, facilitando reuniões e aproximando pessoas em prol
                  de um trabalho mais produtivo.
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>Usuários ativos no Google Meet</ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.meet_usage.media : 0}%
                  </ItemMedia>
                  <p>
                    No último trimestre, em média{' '}
                    <span>{insights ? insights.meet_usage.media : 0}% </span>{' '}
                    dos usuários fizeram reuniões pelo Meet
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.meet_usage.media : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Usuários</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.meet_usage.users_total}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData className="grid desktop-only tablet-hide">
                <ItemHeader>Top 10 usuários Meet</ItemHeader>
                <ItemDataGrid>
                  {insights && insights.meet_usage.users
                    ? insights.meet_usage.users
                        .slice(0, 10)
                        .map((user) => (
                          <Avatar
                            key={`${user.user_id}_meet_usage`}
                            data={user}
                          />
                        ))
                    : ''}
                </ItemDataGrid>
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.doc_share_ext && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Compartilhamento Externo</h3>
                </ItemHeader>

                <p>
                  Observar se perfil de atividades dos usuários que mais
                  compartilham documentos externamente condiz com tal prática.
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>
                  Usuários que compartilham documentos externamente
                </ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.doc_share_ext.media_ext : 0}%
                  </ItemMedia>
                  <p>
                    {insights ? insights.doc_share_ext.num_users_ext : 0}{' '}
                    usuários compartilham documentos externamente
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.doc_share_ext.media_ext : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Usuários</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.doc_share_ext.num_users_ext}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData className="desktop-only tablet-hide">
                <ItemHeader center>
                  Tipos de compartilhamento externo
                </ItemHeader>
                <ResponsiveContainer minHeight="100%" width="100%">
                  <BarChart
                    layout="vertical"
                    width={400}
                    max-height={100}
                    data={dataShareExt}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 50,
                      bottom: 50,
                    }}
                  >
                    <XAxis type="number" domain={[0, 'dataMax']} />
                    <YAxis dataKey="name" reversed type="category" />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                      barSize={20}
                      margin={2}
                      dataKey="value"
                      fill="rgba(64,132,249,0.9)"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </ItemData>
            </CardInsightA>
          </Board>
        )}

        {insights && insights.doc_share_int && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Compartilhamento Interno</h3>
                </ItemHeader>

                <p>
                  O compartilhamento de arquivos, com usuários do mesmo setor ou
                  com usuários de outros setores, possibilita maior
                  produtividade no trabalho em equipe, além de evitar o risco de
                  se trabalhar em versões diferentes do mesmo documento
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>
                  Usuários que compartilham documentos internamente
                </ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.doc_share_int.media_int : 0}%
                  </ItemMedia>
                  <p>
                    {insights ? insights.doc_share_int.num_users_int : 0}{' '}
                    usuários compartilham documentos internamente
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.doc_share_int.media_int : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Usuários</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.doc_share_int.num_users_int}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData className="grid desktop-only tablet-hide">
                <ItemHeader>Top 10 usuários compartilhadores</ItemHeader>
                <ItemDataGrid>
                  {insights && insights.doc_share_int.users
                    ? insights.doc_share_int.users.slice(0, 10).map((user) => (
                        <React.Fragment key={`${user.user}_doc_share_int`}>
                          <Avatar data={user} />
                        </React.Fragment>
                      ))
                    : ''}
                </ItemDataGrid>
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights &&
          (insights.trash || insights.downloads || insights.shared_ext) && (
            <Board style={{ display: 'inline-block' }}>
              <ItemData
                className="desktop-only"
                style={{
                  width: '100%',
                  display: 'flex',
                  paddingBottom: '40px',
                }}
              >
                <ItemHeader style={{ whiteSpace: 'nowrap' }}>
                  <h3>Ações em massa com arquivos</h3>
                </ItemHeader>

                <p style={{ marginLeft: '30px', paddingRight: '20px' }}>
                  A exclusão, download ou compartilhamento externo de muitos
                  arquivos de uma vez pelo mesmo usuário pode configurar
                  situação de risco de perda de dados pela empresa. Abaixo,
                  temos os usuários que realizaram ações desse tipo, numa
                  quantidade acima do gatilho diário configurado no sistema.
                </p>
              </ItemData>
              <CardInsightA order={1} style={{ marginBottom: '10px' }}>
                <ItemData className="desktop-only">
                  <ItemHeader
                    style={{ textAlign: 'center', minHeight: '30px' }}
                  >
                    Exclusão de arquivos
                  </ItemHeader>
                  <ItemDataGrid
                    total_result={
                      insights && insights.trash && insights.trash.users
                        ? insights.trash.users.length
                        : 0
                    }
                  >
                    {insights && insights.trash
                      ? insights.trash.users.map((user) => (
                          <Avatar
                            data={user}
                            key={`${user.profile_id}_${user.date.value}`}
                          />
                        ))
                      : ''}
                  </ItemDataGrid>
                </ItemData>
                <ItemData className="desktop-only">
                  <ItemHeader
                    style={{ textAlign: 'center', minHeight: '30px' }}
                  >
                    Downloads
                  </ItemHeader>
                  <ItemDataGrid
                    total_result={
                      insights && insights.downloads && insights.downloads.users
                        ? insights.downloads.users.length
                        : 0
                    }
                  >
                    {insights && insights.downloads
                      ? insights.downloads.users
                          .slice(0, 10)
                          .map((user) => (
                            <Avatar
                              data={user}
                              key={`${user.profile_id}_${user.date.value}`}
                            />
                          ))
                      : ''}
                  </ItemDataGrid>
                </ItemData>

                <ItemData className="grid desktop-only tablet-hide">
                  <ItemHeader
                    style={{ textAlign: 'center', minHeight: '30px' }}
                  >
                    Compartilhamento Externo
                  </ItemHeader>
                  <ItemDataGrid
                    total_result={
                      insights &&
                      insights.shared_ext &&
                      insights.shared_ext.users
                        ? insights.shared_ext.users.length
                        : 0
                    }
                  >
                    {insights && insights.shared_ext
                      ? insights.shared_ext.users
                          .slice(0, 10)
                          .map((user) => (
                            <Avatar
                              data={user}
                              key={`${user.profile_id}_${user.date.value}`}
                            />
                          ))
                      : ''}
                  </ItemDataGrid>
                </ItemData>
              </CardInsightA>
            </Board>
          )}
        {insights && (insights.quota_50_80 || insights.quota_80) && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Cotas de armazenamento</h3>
                </ItemHeader>

                <p>
                  As cotas de armazenamento desses usuários merecem atenção dos
                  administradores do ambiente.
                </p>
                <hr />
                <p>
                  As cotas de armazenamento de usuários com mais de 80% de
                  utilização podem ocasionar parada dos aplicativos. O usuário
                  será alertado nas telas do G Mail e G Drive, assim como, o
                  administrador do ambiente pode identificar esses usuários
                  através de relatório obtido pelo painel de administração do G
                  Suite.
                </p>
              </ItemData>
              <ItemData>
                {insights.quota_50_80 ? (
                  <>
                    <ItemHeader>
                      Usuários com 50 a 80% de armazenamento utilizado
                    </ItemHeader>
                    <ItemBar>
                      <ItemMedia>
                        {insights ? insights.quota_50_80.media : 0}%
                      </ItemMedia>
                      <p>
                        {insights ? insights.quota_50_80.cota_50_80 : 0}{' '}
                        usuários usando entre 50-80% da cota
                      </p>
                      <ProgressBar
                        style={{ marginTop: 30 }}
                        now={insights ? insights.quota_50_80.media : 0}
                      />
                      <ItemFooter>
                        <div>
                          <p>Usuários</p>
                        </div>
                        <Percent>
                          {insights
                            ? `${insights.quota_50_80.cota_50_80 || '0'}/${
                                insights.view_customer.accounts_num_users
                              }`
                            : '0/0'}
                        </Percent>
                      </ItemFooter>
                    </ItemBar>
                  </>
                ) : (
                  ''
                )}
              </ItemData>

              <ItemData className="desktop-only tablet-hide">
                {insights.quota_80 ? (
                  <>
                    <ItemHeader>
                      Usuários com mais de 80% de armazenamento utilizado
                    </ItemHeader>
                    <ItemBar>
                      <ItemMedia>
                        {insights ? insights.quota_80.media : 0}%
                      </ItemMedia>
                      <p>
                        {insights ? insights.quota_80.cota_80 : 0} usuários
                        usando entre 50-80% da cota
                      </p>
                      <ProgressBar
                        style={{ marginTop: 30 }}
                        now={insights ? insights.quota_80.media : 0}
                      />
                      <ItemFooter>
                        <div>
                          <p>Usuários</p>
                        </div>
                        <Percent>
                          {insights
                            ? `${insights.quota_80.cota_80 || '0'}/${
                                insights.view_customer.accounts_num_users
                              }`
                            : '0/0'}
                        </Percent>
                      </ItemFooter>
                    </ItemBar>
                  </>
                ) : (
                  ''
                )}
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.auth_2sv && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Dupla autenticação</h3>
                </ItemHeader>

                <p>
                  A dupla autenticação combina algo que o usuário sabe (senha)
                  com algo que ele está portando (celular, token físico),
                  exigindo uma confirmação pelo dispositivo após informação da
                  senha. O administrador do seu ambiente pode identificar,
                  através de relatório do painel de administração, quais
                  usuários ainda não estão utilizando dupla autenticação e
                  providenciar essa configuração.
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>Usuários sem dupla autenticação</ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.auth_2sv.media : 0}%
                  </ItemMedia>
                  <p>
                    <span>{insights ? insights.auth_2sv.no_auth_2sv : 0}</span>{' '}
                    usuários estão SEM dupla autenticação configurada em suas
                    contas
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.auth_2sv.media : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Usuários</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.auth_2sv.no_auth_2sv}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData
                className="desktop-only tablet-hide"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img width="100%" src={duble_auth} alt="" draggable="false" />
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.inative_license && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Licenças suspensas</h3>
                </ItemHeader>

                <p>
                  Verificar se os usuários que estão com licenças suspensas
                  podem ter seus dados migrados para outro usuário ou realizado
                  backup, de forma a excluir essas contas definitivamente e
                  liberar as mesmas da cobrança do contrato.
                </p>
                <hr />
                <ItemHeader>
                  <h3>Licenças sem uso</h3>
                </ItemHeader>
                <p>
                  Além disso, licenças ainda não atribuidas aos usuários
                  continuam cobradas
                </p>
              </ItemData>
              <ItemData>
                <ItemHeader>Licenças suspensas</ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.inative_license.media : 0}%
                  </ItemMedia>
                  <p>
                    Existem{' '}
                    {insights ? insights.inative_license.inative_accounts : 0}{' '}
                    licenças suspensas
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.inative_license.media : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Licenças</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.inative_license.inative_accounts}/${insights.view_customer.accounts_num_users}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>

              <ItemData className="desktop-only tablet-hide">
                <ItemHeader>Licenças sem uso</ItemHeader>
                <ItemBar>
                  <ItemMedia>
                    {insights ? insights.view_customer.media : 0}%
                  </ItemMedia>
                  <p>
                    Existem {insights ? insights.view_customer.non_used : 0}{' '}
                    licenças sem uso
                  </p>
                  <ProgressBar
                    style={{ marginTop: 30 }}
                    now={insights ? insights.view_customer.media : 0}
                  />
                  <ItemFooter>
                    <div>
                      <p>Licenças</p>
                    </div>
                    <Percent>
                      {insights
                        ? `${insights.view_customer.non_used}/${insights.view_customer.total_licenses}`
                        : '0/0'}
                    </Percent>
                  </ItemFooter>
                </ItemBar>
              </ItemData>
            </CardInsightA>
          </Board>
        )}
        {insights && insights.sites_gplus && (
          <Board>
            <CardInsightA order={1}>
              <ItemData className="desktop-only">
                <ItemHeader>
                  <h3>Sites e Comunidades</h3>
                </ItemHeader>
                <p>
                  A utilização do Google Sites e Google Plus (renomeado para
                  Currents) são duas plataformas poderosas de compartilhamento
                  de informações e engajamento de colaboradores, na forma de
                  Intranet e rede social própria, muito utilizadas em projetos
                  de RH, área Comercial, Projetos, etc.
                </p>
              </ItemData>
              <ItemData style={{ textAlign: 'center' }}>
                <ItemHeader>Número total de sites</ItemHeader>
                <ItemSites className="sites_e_communit">
                  {insights ? insights.sites_gplus.sites_created : 0}

                  <img src={sites} width="80px" alt="" draggable="false" />
                </ItemSites>
              </ItemData>

              <ItemData
                className="desktop-only"
                style={{ textAlign: 'center' }}
              >
                <ItemHeader>Número total de comunidades</ItemHeader>
                <ItemSites className="sites_e_communit">
                  {insights ? insights.sites_gplus.gplus_communit : 0}

                  <img src={currents} width="80px" alt="" draggable="false" />
                  {insights && insights.sites_gplus.gplus_communit <= 0 ? (
                    <p>
                      <p>Nenhuma Comunidade criada no Google Currents</p>
                    </p>
                  ) : (
                    ''
                  )}
                </ItemSites>
              </ItemData>
            </CardInsightA>
          </Board>
        )}
      </Container>
    </>
  );
}
