export function insightsRequest(customer_id) {
  return {
    type: '@insights/INSIGHTS_REQUEST',
    payload: { customer_id },
  };
}

export function insightsSuccess(insightsData) {
  return {
    type: '@insights/INSIGHTS_SUCCESS',
    payload: { insightsData },
  };
}
