import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#f8625c',
    },
    '& label': {
      color: '#f8625c',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#f8625c',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#f8625c',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#f8625c',
      },
    },
  },
})(TextField);

export const InputLabelOrange = styled(InputLabel)`
  color: #f8625c !important;
`;

export const ContainedButton = styled(Button)`
  font-weight: bold !important;
  border: 1px solid #f8625c !important;
  background-color: ${({ disabled }) =>
    disabled ? '#ccc' : '#f8625c'} !important;
  color: #fff !important;
`;

export const OutlinedButton = styled(Button)`
  font-weight: bold !important;
  border: 1px solid #f8625c;
  background-color: #fff !important;
  color: #f8625c !important;
`;
