import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';
import { signInSuccess, signUpSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  const { tokenRequest, accessToken, email, googleId, thumbnail } = payload;
  const domainClient = email.split('@');
  try {
    api.defaults.headers.common.Authorization = `Bearer ${tokenRequest}`;
    const response = yield call(api.post, 'sessions', {
      email,
      googleId,
      accessToken,
    });

    const { user, client, orgUnits } = response.data;
    const registerTime = new Date(client.created_at);

    if (!client.client_activated) {
      yield put(signFailure(email, thumbnail, domainClient[1], registerTime));
      toast.warn(
        'Seu cadastro ainda não foi ativado. Tente novamente mais tarde.',
        { autoClose: 5000 }
      );
    } else {
      yield put(signInSuccess(tokenRequest, user, client, orgUnits));
      history.push('/dashboard/organization');
    }
  } catch (err) {
    yield put(signFailure(email, thumbnail, domainClient[1], null));
    if (err) {
      if (err.response.status === 401) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.response.data.message);
        history.push('/register');
      }
    }
  }
}

export function* signUp({ payload }) {
  const { clientData, emailAdmin } = payload;

  try {
    const response = yield call(api.post, 'client/register', {
      ...clientData,
      emailAdmin,
    });
    const { message } = response.data;

    yield put(signUpSuccess());
    toast.success(message);
    // history.push('/');
  } catch (err) {
    if (err) {
      toast.error(err.response.data.message);
    }
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
]);
