import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdKeyboardArrowDown } from 'react-icons/md';
import logo from '~/assets/logo_icon.png';
import { signOut } from '~/store/modules/Auth/actions';
import history from '~/services/history';
import { Container, ProfileCard, TopCard, Logout } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User.profile);
  const [visible, setVisible] = useState(false);
  function handleToggleVisible() {
    setVisible(!visible);
  }
  function handlerLogout() {
    dispatch(signOut());
    history.push('/');
  }
  return (
    <Container>
      <span>
        <button type="button" onClick={handleToggleVisible}>
          <img
            src={user.thumbnail ? user.thumbnail : logo}
            alt="logo  ST insigths"
            draggable="false"
          />

          <MdKeyboardArrowDown size="12" color="#333" />
        </button>
      </span>
      <ProfileCard visible={visible}>
        <TopCard>
          <div>
            <img
              src={user.thumbnail ? user.thumbnail : logo}
              alt="logo ST insigths"
              draggable="false"
            />
            <span>
              <p>{user ? user.name : ''}</p>
              <small>{user ? user.email : ''}</small>
            </span>
          </div>
          <div>
            <Logout
              buttonText="Sair"
              clientId="171833423013-9crsjtnvkr9uqi9m6ifdasiqh82ogr9d.apps.googleusercontent.com"
              onLogoutSuccess={() => {
                handlerLogout();
              }}
            />
          </div>
        </TopCard>
      </ProfileCard>
    </Container>
  );
}
