import produce from 'immer';

const INITIAL_STATE = {
  clients: null,
  loading: false,
  loadingFeedback: false,
  feedbackSent: false,
  authorizations: null,
  customerId: null,
  modalActivating: false,
  userClient: null,
  currentUserClient: null,
  currentUserClientDomain: null,
  currentOrgUnits: [],
  registerDone: false,
};

export default function client(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@client/SEND_FEEDBACK_REQUEST': {
        draft.loadingFeedback = true;
        draft.feedbackSent = false;
        break;
      }
      case '@client/SEND_FEEDBACK_END_REQUEST': {
        draft.loadingFeedback = false;
        draft.feedbackSent = true;
        break;
      }
      case '@client/SEND_FEEDBACK_RESET': {
        draft.feedbackSent = false;
        break;
      }
      case '@client/SET_REGISTER_DONE': {
        draft.registerDone = action.payload.done;
        break;
      }
      case '@client/CREATE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@client/REGISTER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.userClient = action.payload.client;
        if (!draft.userClient.start_work_time) {
          draft.userClient.start_work_time = '08:00';
        }
        if (!draft.userClient.end_work_time) {
          draft.userClient.end_work_time = '18:00';
        }
        draft.currentOrgUnits = action.payload.orgUnits;
        draft.currentUserClient = action.payload.client.client_id;
        draft.currentUserClientDomain = `@${action.payload.client.domain}`;
        break;
      }
      case '@client/CREATE_SUCCESS': {
        draft.clients = action.payload.client;
        draft.loading = false;
        draft.modalActivating = false;
        break;
      }
      case '@client/REGISTER_SUCCESS': {
        draft.authorizations = action.payload.authorizations;
        draft.customerId = action.payload.customer_id;
        draft.modalActivating = true;
        draft.loading = false;
        break;
      }
      case '@client/CREATE_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@client/UPDATE_SUCCESS': {
        draft.userClient = action.payload.client;
        draft.loading = false;
        break;
      }
      case '@client/GET_DATA_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@client/UPDATE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@client/SET_CURRENT_REQUEST': {
        draft.currentUserClient = action.payload.client_id;
        draft.currentUserClientDomain = action.payload.domain;
        break;
      }
      default:
    }
  });
}
