import styled from 'styled-components';
import { AreaChart, ResponsiveContainer } from 'recharts';

export const Container = styled.div`
  position: relative;
  border-radius: 3px;
  font-size: 11px;
  max-width: 100%;
  background-color: #fff;

  .recharts-text.recharts-label {
    font-size: 12px;
  }
`;

export const TooltipContainer = styled.div`
  padding: 20px;
  color: #495057;
  max-width: 250px;
  background: rgba(256, 256, 256, 0.8);
`;

export const LabelTooltip = styled.p`
  font-weight: bolder;
  padding-bottom: 5px;
`;

export const BodyTooltip = styled.p`
  font-size: 11px;
  color: ${(props) => props.color || '#333'};
`;

export const HeaderChart = styled.header`
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #999;
  font-weight: bolder;
  text-align: left;
  padding: 20px 30px;

  svg {
    color: #f8625c;
    margin: 0 10px 0 0;
  }
`;

export const ResponsiveContainerChart = styled(ResponsiveContainer)`
  display: flex;
`;
export const AreaChartCustom = styled(AreaChart)``;
