import styled from 'styled-components';
import { transparentize } from 'polished';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  position: relative;
  border-radius: 3px;
  font-size: 11px;
  min-width: 45%;
  height: 99%;
  max-height: 643px;
  margin-bottom: 10px;

  @media ${device.laptop} {
    max-height: none;
    height: auto !important;
  }
`;

export const CardContainer = styled.div``;
export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  div > {
    display: block;
  }
`;
export const CardLineChart = styled.div`
  display: flex !important;
  position: relative;
  text-align: center;
  font-size: 11px;
  width: 50%;
  height: 200px;
  flex-grow: 2;
  @media ${device.laptop} {
    flex: 1 2 400px;
  }
`;
export const HeaderChart = styled.header`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 15px 20px;
  text-transform: uppercase;
  color: #999;
  font-weight: bolder;
  text-align: left;
  justify-content: space-between;

  span {
    align-items: center;
    display: flex;
    width: 30%;
    justify-content: space-between;
  }

  @media ${device.mobileL} {
    > div {
      display: none !important;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.mobileM} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.tablet} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
`;
export const TitleApp = styled.div`
  display: flex !important;
  justify-content: space-between;
  width: 10% !important;
  color: ${(props) => props.color};
  font-weight: bolder;
  padding: 15px 0px 10px 0px;
  height: 87%;
  font-size: 12px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-transform: capitalize;

  svg {
    fill: ${(props) => transparentize(0.1, props.color)};
  }
`;

export const NoData = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #999;
  margin: -10px 10px 10px 10px;
  border-radius: 10px;
  background: repeating-linear-gradient(
    -55deg,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 30px,
    rgba(0, 0, 0, 0.1) 30px,
    rgba(0, 0, 0, 0.1) 60px
  );
`;
