import styled from 'styled-components';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  display: grid;
  padding: 20px 40px;
  padding-top: 0px !important;
  grid-gap: 5px;
  margin-top: 80px;
  width: 100%;
  overflow: hidden;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'Title          Title'
    'Ranking        Ranking'
    'gridProfile    gridProfile';

  @media ${device.laptopM} {
    grid-template-areas:
      'Title          Title'
      'Ranking        Ranking'
      'gridProfile    gridProfile';
  }
  @media ${device.laptopL} {
    grid-template-areas:
      'Title          Title'
      'Ranking        Ranking'
      'gridProfile    gridProfile';
  }

  @media ${device.mobileL} {
    padding: 0;
  }
  @media ${device.mobileS} {
    padding: 0;
  }
`;

export const Header = styled.header`
  position: fixed;
  z-index: 1;
  top: 0;
  text-shadow: 1px 2px 2px #333;
  width: 83.33%;
  height: 60px;
  background: #f75a48;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ChartTeste = styled.div`
  height: 300px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DashboardTitleContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  margin-top: 10px;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  border-radius: 5px;
  background: #f8625c;
  grid-gap: 5px;
  grid-area: Title !important;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
`;

export const DashboardTitleText = styled.span`
  width: 235px;
  white-space: nowrap;
  font-size: 22px;
  padding: 20px 30px;
  /* text-transform: uppercase; */
  color: #eee;
  font-weight: bolder;
  text-align: left;
`;

export const PdfButton = styled.span`
  width: 80%;
  margin-left: 60px;

  svg {
    cursor: pointer;
    float: right;
    margin-right: 22px;
  }
`;
