import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  z-index: 10000;
  color: #495057;
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
`;
export const ContentTopMenu = styled.div`
  color: #f8625c;
  padding: 0 25px;
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all 0.2s;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  a.menu {
    color: #f8625c;
    text-decoration: none;
    font-weight: bolder;
    padding: 20px 35px;
    transition: all 0.4s;
    border-radius: 3px;

    &:hover {
      background: #f8625c;
      color: #fff;
    }
  }
`;

export const HeaderLogo = styled.div`
  padding: 0 1.5rem;
  height: 60px;
  width: 500px;
  display: flex;
  align-items: center;
  transition: width 0.2s;
  a {
    font-size: 18px;
    font-weight: bold;
    color: #f8625c;
    outline: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  img {
    width: 30%;
    height: 30%;
  }
  @media ${device.mobileS} {
    div {
      display: none !important;
    }
  }
  @media ${device.mobileM} {
    div {
      display: none !important;
    }
  }
  @media ${device.tablet} {
    div {
      display: none !important;
    }
  }
`;
export const HeaderContent = styled.div`
  font-size: 14px;
  color: #495057;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin: 0 97px;
  p {
    font-weight: bold;
  }
  img {
    border-radius: 50px;
    width: 45px;
    height: 45px;
  }

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      margin: 0 10px;
    }
    padding: 0 10px;
  }

  @media ${device.mobileS} {
    margin: 0;
    span {
      padding: 0;
    }
  }
  @media ${device.mobileM} {
    margin: 0;
    span {
      padding: 0;
    }
  }
  @media ${device.tablet} {
    margin: 0;
    span {
      padding: 0;
    }
  }
`;
export const HeaderDashboard = styled.div`
  display: flex;

  align-items: center;
  padding: 0 50px;
  width: 100%;
  background: hsla(0, 0%, 100%, 0.55);
  box-sizing: border-box;
  @media ${device.mobileS} {
    padding: 0 10px;
  }
  @media ${device.mobileM} {
    padding: 0 10px;
  }
`;
export const ItemMenu = styled(Link)`
  display: flex;
  text-decoration: none !important;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  transition: background 0.2s;
  min-width: 100px;
  cursor: pointer;
  &.isActive {
    background: #f8625c;
    p {
      color: #fff;
    }
    div {
      svg {
        fill: #fff;
      }
    }
  }
  &:hover {
    background: #f8625c;
    p {
      color: #fff;
    }
    div {
      svg {
        fill: #fff;
      }
    }
  }
  div {
    padding: 10px 0;
    margin-right: 10px;
  }
  span {
    p {
      color: #495057;
      font-size: 1em;
    }
    small {
      color: #f8625c;
      font-size: 0.8em;
    }
  }
  @media ${device.mobileS} {
    span {
      display: block !important;
      font-size: 0.7em;
    }
  }
  @media ${device.mobileM} {
    span {
      display: block !important;
      font-size: 0.7em;
    }
  }
`;
export const ProfileContent = styled.div`
  @media ${device.mobileS} {
    display: none !important;
  }
  @media ${device.mobileM} {
    display: none !important;
  }
  @media ${device.tablet} {
    display: none !important;
  }
`;
