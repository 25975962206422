import styled from 'styled-components';

export const CardSettings = styled.div`
  position: relative;
  width: 50%;
  padding: 20px 30px 30px 30px;
  margin: 0 2px;
  max-height: auto;
  background: #fff;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);

  justify-content: left;
`;

export const SearchClient = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 80%;
  margin-top: 10px;
  h3 {
    color: #333;
  }
  nav {
    width: 100%;
    display: flex;
    align-items: baseline;
    input {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      height: 35px;
      color: #f75a48;
      margin-bottom: 10px;
      border: none;
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
      box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -webkit-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -moz-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
    }
    span {
      padding: 9px;
      align-items: baseline;
    }
  }
`;

export const ContainerClient = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  select {
    width: 50%;
  }
`;

export const Table = styled.table`
  width: 100%;

  -webkit-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:hover {
        background-color: #e0f3ff;
      }
      td {
        padding: 10px 5px;

        button {
          border: none;
          font-size: 12px;
          padding: 10px 20px;
          border-radius: 3px;
          display: flex;
          svg {
            margin-right: 10px;
          }
        }
        img {
          margin-right: 10px;
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }
        &:first-child,
        &:last-child {
          text-align: center;
        }
      }
    }
  }
`;

export const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Change width of the form depending if the bar is opened or not */
  width: ${(props) => (props.barOpened ? '20rem' : '2rem')};
  /* If bar opened, normal cursor on the whole form. If closed, show pointer on the whole form so user knows he can click to open it */
  cursor: ${(props) => (props.barOpened ? 'auto' : 'pointer')};
  padding: 22px;
  height: 5px;
  border-radius: 10rem;
  transition: width 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const Input = styled.input`
  font-size: 14px;
  line-height: 1;
  background-color: transparent;
  width: 100%;
  margin-left: ${(props) => (props.barOpened ? '1rem' : '0rem')};
  border: none;
  color: white;
  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #f75a48;

  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

export const Button = styled.button`
  line-height: 1;
  pointer-events: ${(props) => (props.barOpened ? 'auto' : 'none')};
  cursor: ${(props) => (props.barOpened ? 'pointer' : 'none')};
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
`;

export const CardList = styled.div`
  margin-top: 46px;
  /* height: auto; */
  max-height: 250px;
  width: 20rem;
  /* overflow-y: auto; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  overflow: auto;
  position: absolute;
`;

export const Container = styled.div`
  display: flex;

  flex-wrap: wrap;
  width: ${(props) => (props.barOpened ? '30%' : '4%')};
  justify-items: center;
`;

export const CardContainer = styled.div`
  height: 50px;
  width: 100%;
  background: #fff;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const Title = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 15px;
  color: #999;
  img {
    border-radius: 50px;
    max-width: 50px;
    max-height: 30px;
    width: auto;
    height: auto;
    margin-right: 10px;
  }
`;
