import { produce } from 'immer';

const INITIAL_STATE = {
  clientAppUsageData: { loading: true, filter: { period: 'year', ou: '/' } },
  clientAppUsageDetailsData: {
    loading: true,
    filter: { period: 'trimester', ou: '/' },
  },
  clientDocsCreateData: { loading: true, filter: { period: 'year', ou: '/' } },
  clientEditorDriveData: [],
  clientStatesData: { loading: true, data: [] },
  userProfilesData: { loading: true, data: [] },
  clientSharedDrives: { loading: true, filter: { period: 'year' } },
};
export default function clientData(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@clientdata/GET_APP_USAGE_REQUEST': {
        draft.clientAppUsageData = {
          loading: true,
          filter: action.payload.filter || draft.clientAppUsageData.filter,
        };
        break;
      }
      case '@clientdata/GET_APP_USAGE_SUCCESS': {
        draft.clientAppUsageData = {
          ...draft.clientAppUsageData,
          ...action.payload.clientData,
          loading: false,
        };
        break;
      }

      case '@clientdata/GET_APP_USAGE_DETAILS_REQUEST': {
        draft.clientAppUsageDetailsData = {
          loading: true,
          filter:
            action.payload.filter || draft.clientAppUsageDetailsData.filter,
        };
        break;
      }

      case '@clientdata/GET_APP_USAGE_DETAILS_SUCCESS': {
        draft.clientAppUsageDetailsData = {
          ...draft.clientAppUsageDetailsData,
          ...action.payload.appUsageDetails,
          loading: false,
        };

        break;
      }

      case '@clientdata/GET_EDITOR_DRIVE_SUCCESS': {
        draft.clientEditorDriveData = action.payload.clientEditorDrive;

        break;
      }

      case '@clientdata/GET_DOCS_CREATE_REQUEST': {
        draft.clientDocsCreateData = {
          loading: true,
          filter: action.payload.filter || draft.clientDocsCreateData.filter,
        };
        break;
      }

      case '@clientdata/GET_DOCS_CREATE_SUCCESS': {
        draft.clientDocsCreateData = {
          ...draft.clientDocsCreateData,
          data: action.payload.clientDocsData,
          loading: false,
        };

        break;
      }

      case '@clientdata/GET_CUSTOMER_STATES_SUCCESS': {
        draft.clientStatesData = {
          data: action.payload.clientStatesData,
          loading: false,
        };

        break;
      }
      case '@clientdata/GET_USER_PROFILES_SUCCESS': {
        draft.userProfilesData = {
          loading: false,
          data: action.payload.userprofilesData,
        };

        break;
      }
      case '@clientdata/GET_USER_PROFILES_REQUEST': {
        draft.userProfilesData = {
          ...draft.userProfilesData,
          loading: true,
        };
        break;
      }
      case '@clientdata/GET_SHARED_DRIVES_REQUEST': {
        draft.clientSharedDrives = {
          loading: true,
          filter: action.payload.filter || draft.clientSharedDrives.filter,
        };
        break;
      }
      case '@clientdata/GET_SHARED_DRIVES_SUCCESS': {
        draft.clientSharedDrives = {
          ...draft.clientSharedDrives,
          data: action.payload.sharedDrivesData,
          loading: false,
        };
        break;
      }

      default:
    }
  });
}
