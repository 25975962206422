import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Switch from '@material-ui/core/Switch';
import { RiShareBoxLine } from 'react-icons/ri';

import UsersModal from '~/components/Dashboard/Organization/Modal/UsersSharedDrives';
import CSVModal from '~/components/UI/CSVColumns';

import {
  Head,
  CellHead,
  RankingTable,
  RankingContainer,
  RankingRoot,
  MyTableRow,
  MyTableCell,
  UsersButton,
  ColumnSelector,
  ColumnSpan,
  CsvButton,
  CsvIcon,
} from './styles';

const OrangeSwitch = withStyles({
  switchBase: {
    color: '#f75a48',
    '&$checked': {
      color: '#f75a48',
    },
    '&$checked + $track': {
      backgroundColor: '#f75a48',
    },
  },
  checked: {},
  track: {},
})(Switch);

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) =>
        (orderBy !== 'name' && desc(a, b, orderBy)) ||
        b.name.localeCompare(a.name)
    : (a, b) =>
        (orderBy !== 'name' && -desc(a, b, orderBy)) ||
        a.name.localeCompare(b.name);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    headCells,
    setHeadCells,
    onRequestSort,
    showColumnContainer,
    showColumns,
    numberOfColumnsShowing,
    copyDrives,
    period,
    dataMonths,
  } = props;

  const [showError, setShowError] = useState(false);
  const [openCSV, setOpenCSV] = useState(false);

  const getColumnColor = useCallback(
    ({ isPosition, isActive }) =>
      (isPosition && isActive && 'black') || (isPosition && '#696969'),
    []
  );

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const showColumnHandler = () => {
    setShowError(false);
    showColumnContainer();
  };

  const handleClose = () => {
    setOpenCSV(false);
  };

  return (
    <>
      <Head>
        <TableRow>
          <TableCell padding="checkbox" />
          {headCells.map(
            (headCell) =>
              headCell.show && (
                <CellHead
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  display="none"
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    style={{
                      color: getColumnColor({
                        isPosition: headCell.isPosition,
                        isActive: orderBy === headCell.id,
                      }),
                    }}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id && (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    )}
                  </TableSortLabel>
                </CellHead>
              )
          )}
          <TableCell
            style={{
              textAlign: 'right',
              minWidth: 106,
            }}
          >
            <ColumnSelector
              type="button"
              title="Opções de exibição de colunas"
              onClick={showColumnHandler}
            >
              <ViewColumnIcon />
            </ColumnSelector>

            <CsvButton
              title="Exportar dados em CSV"
              onClick={() => setOpenCSV(true)}
            >
              <CsvIcon size="24" color="#f8625c" />
            </CsvButton>
            {showColumns && (
              <Backdrop
                style={{ backgroundColor: 'transparent', zIndex: 5 }}
                open={showColumns}
                onClick={showColumnContainer}
              />
            )}
            <ColumnSpan style={{ display: showColumns ? 'block' : 'none' }}>
              {showError && (
                <>
                  <small>Remova uma coluna para incluir outra</small> <br />
                </>
              )}
              Colunas (máximo 9 por vez)
              {headCells.map((col) => {
                if (col.id !== 'name') {
                  return (
                    <React.Fragment key={col.id}>
                      <br />
                      <OrangeSwitch
                        checked={col.show}
                        onChange={() => {
                          if (
                            numberOfColumnsShowing === 10 &&
                            !col.show === true
                          ) {
                            setShowError(true);
                          } else {
                            col.show = !col.show;
                            setHeadCells(headCells);

                            setShowError(false);
                          }
                        }}
                        name={col.label}
                        id={col.id}
                      />
                      {col.label}
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </ColumnSpan>
          </TableCell>
        </TableRow>
      </Head>
      {openCSV && (
        <CSVModal
          handleClose={handleClose}
          data={copyDrives}
          period={period}
          dataMonths={dataMonths}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
        />
      )}
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    paper: PropTypes.string,
    visuallyHidden: PropTypes.string,
  }).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      isPosition: PropTypes.bool,
      label: PropTypes.string,
      show: PropTypes.bool,
    })
  ).isRequired,
  setHeadCells: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  showColumnContainer: PropTypes.func.isRequired,
  showColumns: PropTypes.bool.isRequired,
  numberOfColumnsShowing: PropTypes.number.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  copyDrives: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  period: PropTypes.string.isRequired,
  dataMonths: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    gridArea: 'Ranking',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  drives = [],
  headCellsArray = [],
  period = 'trimester',
  dataMonths,
}) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [showColumns, setShowColumns] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [usersDrive, setUsersDrive] = useState([]);
  const [driveName, setDriveName] = useState('name');

  function hideModal() {
    setIsShowing(false);
  }

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [headCells, setHeadCells] = useState(
    new Map(headCellsArray.map((headCell) => [headCell.id, headCell]))
  );

  const numberOfColumnsShowing = useMemo(() => {
    let count = 0;

    Array.from(headCells.values()).forEach((headCell) => {
      if (headCell.show) {
        count += 1;
      }
    });

    return count;
  }, [headCells]);

  const rows = useSelector((state) => state.ClientData.clientSharedDrives.data);

  const copyDrives = useMemo(
    () =>
      drives && drives.length > 0
        ? rows.filter((item) =>
            drives.find((drive) => drive.value === item.name)
          )
        : rows,
    [rows, drives]
  );

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showColumnContainer = () => {
    setShowColumns(!showColumns);
  };

  const showUsers = (drive) => {
    setUsersDrive(drive.users);
    setDriveName(drive.name);
    setIsShowing(true);
  };

  return (
    <>
      <RankingRoot>
        <Paper className={classes.paper}>
          <RankingContainer>
            <RankingTable
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={Array.from(headCells.values())}
                setHeadCells={setHeadCells}
                showColumnContainer={showColumnContainer}
                showColumns={showColumns}
                rowCount={copyDrives.length}
                numberOfColumnsShowing={numberOfColumnsShowing}
                copyDrives={copyDrives}
                period={period}
                dataMonths={dataMonths}
              />
              <TableBody>
                {stableSort(copyDrives, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <MyTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <MyTableCell padding="checkbox" />

                        {Array.from(headCells.values()).map((headCell) => {
                          let result;
                          if (headCell.id === 'name') {
                            return (
                              <MyTableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                key={row.id + headCell.id}
                              >
                                <a
                                  href={`https://drive.google.com/drive/folders/${row.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: '#495057' }}
                                >
                                  {row.name || 'Desconhecido'}{' '}
                                  <RiShareBoxLine size="15" />
                                </a>
                              </MyTableCell>
                            );
                          }
                          if (headCell.show) {
                            result = row[headCell.id] || 0;

                            return (
                              <MyTableCell
                                key={row.user + headCell.id}
                                align="right"
                              >
                                {result.toLocaleString('pt-BR')}
                              </MyTableCell>
                            );
                          }

                          return null;
                        })}

                        <MyTableCell align="right">
                          <UsersButton
                            type="button"
                            onClick={() => showUsers(row)}
                          >
                            usuários
                          </UsersButton>
                        </MyTableCell>
                      </MyTableRow>
                    );
                  })}
              </TableBody>
            </RankingTable>
          </RankingContainer>
          <TablePagination
            labelRowsPerPage="Linhas por página"
            nextIconButtonText="Próxima página"
            backIconButtonText="Página anterior"
            rowsPerPageOptions={[5, 10, 25]}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `mais que ${to}`}`
            }
            component="div"
            count={copyDrives.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </RankingRoot>
      <UsersModal
        isShowing={isShowing}
        hide={hideModal}
        data={usersDrive}
        driveName={driveName}
      />
    </>
  );
}
EnhancedTable.propTypes = {
  drives: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  headCellsArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      show: PropTypes.bool,
    })
  ).isRequired,
  period: PropTypes.string.isRequired,
  dataMonths: PropTypes.number,
};
EnhancedTable.defaultProps = {
  drives: [],
  dataMonths: 12,
};
