import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdSearch } from 'react-icons/md';
import { FiActivity } from 'react-icons/fi';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartRankingTable from '~/components/Dashboard/People/Chart/Ranking/Table';
import { SearchUser } from './Table/styles';
import {
  Container,
  Dashboard,
  HeaderChart,
  MultipleSelect,
  LoadingArea,
  MuiButtonGroup,
  PeriodButton,
} from './styles';
import { getRankingDataRequest } from '~/store/modules/Ranking/actions';

export default function SecurityDashboard() {
  const dispatch = useDispatch();

  const { rankingData, loading } = useSelector((state) => state.Ranking);
  const {
    currentUserClient,
    currentUserClientDomain,
    currentOrgUnits,
  } = useSelector((state) => state.Client);
  const [users, setUsers] = useState(null);
  const [filterOu, setFilterOu] = useState(currentOrgUnits[0]);

  const [filter, setFilter] = useState({
    period: 'trimester',
  });

  const headCellsArray = useMemo(
    () => [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Usuário',
        show: true,
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'Email',
        show: false,
      },
      {
        id: 'emails_sent',
        numeric: true,
        disablePadding: false,
        label: 'Emails Enviados',
        show: true,
      },
      {
        id: 'natives_created',
        numeric: true,
        disablePadding: false,
        label: 'Arquivos Criados',
        show: true,
      },
      {
        id: 'docs_edited',
        numeric: true,
        disablePadding: false,
        label: 'Edição Arquivos',
        show: true,
      },
      {
        id: 'files_upload',
        numeric: true,
        disablePadding: false,
        label: 'Uploads',
        show: true,
      },
      {
        id: 'files_download',
        numeric: true,
        disablePadding: false,
        label: 'Downloads',
        show: true,
      },
      {
        id: 'activities_currents',
        numeric: true,
        disablePadding: false,
        label: 'Currents',
        show: false,
      },
      {
        id: 'shared_internally',
        numeric: true,
        disablePadding: false,
        label: 'Compart. Interno',
        show: false,
      },
      {
        id: 'shared_externally',
        numeric: true,
        disablePadding: false,
        label: 'Compart. Externo',
        show: true,
      },
      {
        id: 'total_shared_drive',
        numeric: true,
        disablePadding: false,
        label: 'Drive Compart.',
        show: false,
      },
      {
        id: 'sku_name',
        numeric: true,
        disablePadding: false,
        label: 'Tipo Licença',
        show: false,
      },
      {
        id: 'used_quota_in_mb',
        numeric: true,
        disablePadding: false,
        label: 'Armaz. em\u00a0MB',
        show: false,
      },
      {
        id: 'used_quota',
        numeric: true,
        disablePadding: false,
        label: '%\u00a0Armaz. Utilizado',
        show: false,
      },
      {
        id: 'total_meet_duration',
        numeric: true,
        disablePadding: false,
        label: 'Meet (horas)',
        show: true,
      },
      {
        id: 'interaction_out_bussiness_time',
        numeric: true,
        disablePadding: false,
        label: '%\u00a0Dias Extras',
        show: true,
      },
      {
        id: 'days_last_login',
        numeric: true,
        disablePadding: false,
        label: 'Dias\u00a0Ult. Login',
        show: false,
      },
    ],
    []
  );

  function handleChangeOu(e) {
    setFilterOu(e.target.value);
  }

  useEffect(() => {
    // dispatch(getUserProfilesDataRequest(currentUserClient, filterOu));
    dispatch(
      getRankingDataRequest(
        currentUserClient,
        currentUserClientDomain,
        filterOu,
        filter.period
      )
    );
  }, [currentUserClient, currentUserClientDomain, dispatch, filterOu, filter]);

  const selectOptions = useMemo(
    () =>
      rankingData
        ? rankingData
            .map((rankingUser) => ({
              value: rankingUser.user,
              label: rankingUser.name,
            }))
            .sort((user1, user2) => (user1.label > user2.label ? 1 : -1))
        : [],
    [rankingData]
  );

  const { dataMonths } = useSelector((state) => state.Client.userClient);

  const colorsGoogle = {
    green: '#34A853',
    red: '#EA4335',
    yellow: '#FBBC05',
    blue: '#4285F4',
  };

  return (
    <Container>
      <Dashboard>
        <HeaderChart>
          <div className="title">
            <FiActivity size="30" />
            <span>
              <p>Ranking por Atividade</p>
              <small>
                {(filter.period === 'year' && `últimos ${dataMonths} meses`) ||
                  (filter.period === 'trimester' && 'últimos 3 meses') ||
                  'últimos 30 dias'}
              </small>
            </span>
          </div>
          <FormControl
            // className={classes.formControl}
            style={{
              width: '400px',
              margin: '-4px auto',
              borderRight: '2px solid rgba(0, 0, 0, 0.1)',
              paddingRight: '10px',
              marginRight: '10px',
            }}
          >
            <InputLabel
              id="ranking-ou-label"
              htmlFor="ranking-ou-select"
              style={{ color: '#f8625c' }}
            >
              Unidade Organizacional
            </InputLabel>
            <Select
              labelId="ranking-ou-label"
              id="ranking-ou-select"
              value={filterOu}
              onChange={handleChangeOu}
              label="OU"
            >
              {currentOrgUnits.map((ou) => (
                <MenuItem value={ou} key={ou}>
                  {ou === '/' ? 'TODAS' : ou}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <SearchUser className="search">
            <nav>
              <span className="icon-search">
                <MdSearch color="#333" size={20} />
              </span>
              <MultipleSelect
                isMulti
                name="rankingData"
                options={selectOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Filtrar usuários"
                noOptionsMessage={() => 'Não encontrado'}
                onChange={setUsers}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
              />
            </nav>
          </SearchUser>
          <MuiButtonGroup
            color="secondary"
            aria-label="outlined secondary button group"
          >
            <PeriodButton
              disabled={filter.period === 'month'}
              variant={filter.period === 'month' ? 'contained' : 'outlined'}
              onClick={() => {
                setFilter({ ...filter, period: 'month' });
              }}
            >
              Mês
            </PeriodButton>
            <PeriodButton
              disabled={filter.period === 'trimester'}
              variant={filter.period === 'trimester' ? 'contained' : 'outlined'}
              onClick={() => {
                setFilter({ ...filter, period: 'trimester' });
              }}
            >
              Trimestre
            </PeriodButton>
            <PeriodButton
              disabled={filter.period === 'year'}
              variant={filter.period === 'year' ? 'contained' : 'outlined'}
              onClick={() => {
                setFilter({ ...filter, period: 'year' });
              }}
            >
              Ano
            </PeriodButton>
          </MuiButtonGroup>
        </HeaderChart>
        <hr />
        {(!loading && (
          <ChartRankingTable
            valueName="score_docs_shared"
            colorTitle={colorsGoogle.green}
            users={users}
            headCellsArray={headCellsArray}
            period={filter.period}
            dataMonths={dataMonths}
          />
        )) || (
          <LoadingArea>
            <CircularProgress style={{ color: '#f8625c' }} />
          </LoadingArea>
        )}
      </Dashboard>
    </Container>
  );
}
