import styled from 'styled-components';
import { darken } from 'polished';
import { device } from '~/utils/mediaQueries';

export const BoxRegister = styled.div`
  width: 70%;
  margin: 90px auto 0 auto;
  display: flex;
  justify-content: center;
  .MuiStepper-root {
    padding: 15px !important;
    border-radius: 5px 5px 0 0;
  }
  .container-steps {
    background: #fff;
    width: 100%;
    min-height: 79px;
  }
  .button-steps {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    width: 80%;
    padding: 0 20px 30px 20px;
  }
  .loading {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 80%;
    padding: 0 20px 30px 20px;
  }
  .final-buttom {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 135px;
    p {
      margin-left: 10px;
    }
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #3fb13f !important;
  }
`;
export const BoxInfo = styled.div`
  text-align: left;
  color: #fff;
  p {
    font-weight: bold;
    font-size: 15px;
    margin-top: 35px;
  }
  span {
    display: flex;
    align-items: center;
    div {
      font-size: 14px;
      color: #fff;
      input[type='checkbox'] {
        margin-right: 5px;
        background: #fff !important;
        border: none;
        width: 20px;
        border-radius: 3px;
      }
    }
  }
`;
export const Container = styled.div`
  width: 100%;

  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  select {
    background: rgba(0, 0, 0, 0.1);
    font-size: 14px;
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: rgba(255, 255, 255, 0.9);
    margin: 0 0 10px;
  }

  select option {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    background: #f8e6e0;
    opacity: 0.1;
    border: 0;
    border-radius: 4px;
    line-height: 22px; /* altura das options */
    min-height: 22px; /* este valor deve ser o mesmo do acima */
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #3b9eff;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#3b9eff')};
    }
    svg {
      color: #fff;
      margin: 10px;
    }
  }

  a {
    color: #fff;
    margin-top: 15px;
    font-size: 15px;
    opacity: 0.8;
    transition: background 0.2s;

    &:hover {
      opacity: 1;
    }
  }
`;
export const InputFile = styled.input.attrs({
  type: 'file',
  name: 'fileupload',
  id: 'inputfile',
})`
  display: none;
`;
export const Label = styled.label`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 44px;
  background: #fff;
  font-weight: bold;
  color: #f75a48;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  transition: background 0.2s;
  svg {
    margin-left: 10px;
  }
  cursor: pointer;
  margin: 5px 0 0 3px;
`;
export const ChoiceButton = styled.div`
  display: flex;
  margin-top: -5px;
  margin-bottom: 5px;
  a {
    margin: 0 !important;
    text-decoration: none;
    opacity: 1 !important;
  }
  button {
    opacity: 1 !important;
    flex: 1;
    padding: 0 20px;
    background: none !important;
    border: 1px solid #fff !important;
    margin: 0 3px 0 0;
    color: #fff !important;
    font-weight: normal !important;
    font-size: 16px !important;

    &:hover {
      background: #f75a48 !important;
      color: #fff !important;
    }
  }
`;
export const CircularDiv = styled.div`
  margin-top: 50px;
  .MuiCircularProgress-root {
    width: 70px !important;
    height: 70px !important;
  }
  p {
    color: #fff;
    margin-top: 30px;
  }
  svg {
    color: #fff !important;
    width: 70px !important;
    height: 70px !important;
  }
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  top: 0;
  justify-content: space-between;
  position: absolute;
  padding: 20px 100px;
  img {
    width: 80px;
  }
  div.user {
    color: #fff;
  }
`;
export const ProfileContent = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    width: 40px;
    border-radius: 50px;
  }

  @media ${device.mobileS} {
    display: none !important;
  }
  @media ${device.mobileM} {
    display: none !important;
  }
  @media ${device.tablet} {
    display: none !important;
  }
`;

export const ContainerForms = styled.div`
  width: 100%;
  background: #fff;
  padding: 1px 20px;
`;
