import React from 'react';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import UISideBarMenu from '~/components/UI/SideBarMenu';

import { Wrapper, Container } from './styles';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

export default function DefaultLayout({ children, path }) {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.root}>
        <UISideBarMenu path={path} />
        <Wrapper>{children}</Wrapper>
      </div>
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
};
