import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import { GoogleLogin } from 'react-google-login';
import { MdCheckCircle, MdContentCopy } from 'react-icons/md';
import * as Yup from 'yup';
import gif from '~/assets/gifs/permission.gif';
import { Container, Terms } from './styles';

export const Info = ({ submitFromOutside }) => {
  const schema = Yup.object().shape({
    name: Yup.string().required('O campo nome é obrigatório'),
    lastname: Yup.string().required('O campo sobrenome é obrigatório'),
    company_name: Yup.string().required(
      'O campo nome da empresa é obrigatório'
    ),
    phone: Yup.string().required('O campo telefone comercial é obrigatório'),
    city: Yup.string(),
    state: Yup.string(),
  });

  return (
    <Container>
      <Form schema={schema} id="my-form" onSubmit={submitFromOutside}>
        <div className="formRow">
          <div>
            <Input label="Nome *" name="name" />
          </div>
          <div>
            <Input label="Sobrenome *" name="lastname" />
          </div>
        </div>
        <div className="formRow">
          <div>
            <Input label="Nome da empresa *" name="company_name" />
          </div>
          <div>
            <Input label="Telefone comercial *" name="phone" />
          </div>
        </div>
        <div className="formRow">
          <div>
            <Input label="Cidade da sede" name="city" />
          </div>
          <div>
            <Input label="Estado" name="state" />
          </div>
        </div>
        <Terms>
          <p>* Campos obrigatórios</p>
        </Terms>
        <span className="button">
          <button type="submit">Avançar</button>
        </span>
      </Form>
    </Container>
  );
};

Info.propTypes = {
  submitFromOutside: PropTypes.func.isRequired,
};

export const Configuration = () => (
  <Container>
    <div className="config">
      <div>
        <p>
          Para autorizar o ST Insights a ter acesso aos dados de relatórios para
          gerar insights siga os dois passos abaixo
        </p>
        <p>
          1.1 -acesse{' '}
          <a
            href="https://admin.google.com/ac/owl/domainwidedelegation"
            target="blank"
          >
            https://admin.google.com/ac/owl/domainwidedelegation
          </a>
          <br />
          Clique em <strong> Adicionar Novo</strong> e cole os códigos nos seus
          respectivos campos de configuração
        </p>
        <div
          className="box-code"
          style={{
            padding: '5px 10px',
            margin: '10px auto ',
            backgroundColor: '#f5f6fa',
            display: 'flex',
            width: '290px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: '11px', color: '#999' }}>
              ID do Cliente
            </span>
            <span className="code-span">
              <input
                style={{ overflowX: 'auto', color: '#333' }}
                readOnly
                value="117310322945210736652"
                type="text"
              />
            </span>
          </div>
          <div>
            <CopyToClipboard
              text="117310322945210736652"
              onCopy={() =>
                process.env.NODE_ENV === 'development' &&
                console.tron.log('copy')
              }
            >
              <button
                type="button"
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MdContentCopy size="30" color="#f75a48" />
              </button>
            </CopyToClipboard>
          </div>
        </div>
        <div
          className="box-code"
          style={{
            padding: '5px 10px',
            margin: '10px auto',
            backgroundColor: '#f5f6fa',
            display: 'flex',
            width: '290px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: '11px', color: '#999' }}>
              Escopos OAuth
            </span>
            <span className="scopes">
              <input
                style={{ overflowX: 'auto', color: '#333' }}
                readOnly
                value="https://www.googleapis.com/auth/apps.licensing,https://www.googleapis.com/auth/admin.directory.domain.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly,https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly,https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.reports.audit.readonly,https://www.googleapis.com/auth/admin.reports.usage.readonly,https://www.googleapis.com/auth/admin.directory.customer.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly"
                type="text"
              />
            </span>
          </div>
          <div>
            <CopyToClipboard
              text="https://www.googleapis.com/auth/apps.licensing,https://www.googleapis.com/auth/admin.directory.domain.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly,https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly,https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.reports.audit.readonly,https://www.googleapis.com/auth/admin.reports.usage.readonly,https://www.googleapis.com/auth/admin.directory.customer.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly"
              onCopy={() =>
                process.env.NODE_ENV === 'development' &&
                console.tron.log('copy')
              }
            >
              <button
                type="button"
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MdContentCopy size="30" color="#f75a48" />
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <picture>
        <img src={gif} alt="" />
      </picture>
    </div>
  </Container>
);

export const Integration = ({ getLastData, registerDone, isLoading }) => {
  const { registerTime } = useSelector((state) => state.Auth);
  return (
    <Container>
      <div className="integration">
        {((registerDone || registerTime) && (
          <p>
            A coleta inicial de dados depende da quantidade de usuários e do
            volume de atividades deles no Google Workspace, sendo impossível
            prever com exatidão o tempo de processamento, que pode durar de
            minutos a horas. Assim que a coleta for concluída, o administrador
            será notificado por email e já poderá acessar o ST Insights
            normalmente.
            {/* Você deve esperar pelo menos 15 minutos após ativação antes de
            tentar acessar o sistema. */}
          </p>
        )) || (
          <p>
            Clique no botão abaixo para fazer a carga inicial dos indicadores G
            suite para o ST Insights. As demais cargas serão automáticas e
            diárias.
          </p>
        )}
        <span>
          {((registerDone || registerTime) && (
            <Link to="/" className="register-done" type="button">
              <MdCheckCircle size="28" color="#3fb13f" />
              Fechar
            </Link>
          )) || (
            <GoogleLogin
              className="loginButton"
              clientId="171833423013-9crsjtnvkr9uqi9m6ifdasiqh82ogr9d.apps.googleusercontent.com"
              onSuccess={getLastData}
              onFailure={getLastData}
              cookiePolicy="single_host_origin"
              buttonText="Cadastrar com Google"
              autoLoad={false}
              disabled={isLoading}
            />
          )}
        </span>
      </div>
    </Container>
  );
};
Integration.propTypes = {
  getLastData: PropTypes.func.isRequired,
  registerDone: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
