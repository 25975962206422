/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

import {
  CartesianGrid,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  Container,
  HeaderChart,
  CardLineChart,
  FlexContainer,
  CardContainer,
  NoData,
} from './styles';

export default function EditedItens({ data, period, monthNames, total }) {
  return (
    <Container>
      <HeaderChart>
        <div>Histórico de edições de arquivos</div>
      </HeaderChart>
      <CardContainer>
        <FlexContainer>
          <CardLineChart>
            {data.length > 0 && total > 0 ? (
              <ResponsiveContainer>
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 50,
                    left: -10,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <Legend />
                  <XAxis
                    dataKey="date.value"
                    tickFormatter={(date) =>
                      period === 'year' && date
                        ? monthNames[parseISO(date).getMonth()].substring(0, 3)
                        : format(date ? parseISO(date) : new Date(), 'dd/MM')
                    }
                  >
                    <Label value="Data" offset={0} position="insideBottom" />
                  </XAxis>
                  <YAxis />
                  <Tooltip
                    labelFormatter={(value) => {
                      if (value) {
                        if (period === 'year') {
                          return `${
                            monthNames[parseISO(value).getMonth()]
                          } de ${parseISO(value).getFullYear()} `;
                        }
                        if (period === 'trimester') {
                          return `Semana terminada em ${format(
                            parseISO(value),
                            'dd/MM'
                          )}`;
                        }

                        return format(parseISO(value), 'dd/MM');
                      }
                      return format(new Date(), 'dd/MM');
                    }}
                  />

                  <Bar
                    name="Documentos editados"
                    dataKey="num_docs"
                    stackId="a"
                    fill="rgb(64, 132, 249)"
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <NoData>Sem dados</NoData>
            )}
          </CardLineChart>
        </FlexContainer>
      </CardContainer>
    </Container>
  );
}

EditedItens.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      num_docs: PropTypes.number,
      date: PropTypes.string,
      dia: PropTypes.number,
      mes: PropTypes.number,
      semana: PropTypes.number,
    })
  ).isRequired,
  period: PropTypes.string.isRequired,
  monthNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  total: PropTypes.number.isRequired,
};
