import React, { useState, useEffect, useMemo } from 'react';
import { put } from 'redux-saga/effects';
import { useSelector, useDispatch } from 'react-redux';
import { MdSearch } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import api from '~/services/api';
import history from '~/services/history';
import { updateClientRequest } from '~/store/modules/Client/actions';
import logo from '~/assets/logo_icon.png';
import { signOut } from '~/store/modules/Auth/actions';
import {
  CardSettings,
  HeaderSettings,
  ContainerForm,
  InfoAccount,
  Badge,
  SearchUser,
  ContainerUsers,
  Table,
  Loading,
} from './styles';

export default function Account() {
  const dispatch = useDispatch();

  const client = useSelector((state) => state.Client.userClient);

  const [allUsers, setAllUsers] = useState([]);
  const [userLoading, setUserLoading] = useState('');
  const [allUsersConfig, setMyUsersConfig] = useState([]);
  const [errorsGoogleApi, setErrorsGoogleApi] = useState([]);
  // verificar aqui
  const accountWarning = useMemo(() => {
    if (errorsGoogleApi.length) {
      const codeErrors = [401, 403];
      const errorsArray = errorsGoogleApi.filter((item) =>
        codeErrors.includes(item.status)
      );

      return errorsArray.length
        ? 'Por algum motivo o Usuário Administrador não tem mais permissões para buscar dados de adoção de sua conta G Suite. Por favor, forneça um email de usuário super administrador para resolver este problema.'
        : '';
    }

    return '';
  }, [errorsGoogleApi]);

  useEffect(() => {
    async function LoadAllUsers(client_id) {
      try {
        const response = await api.get(`/users/allow/${client_id}`);
        const configResponse = await api.get(`/users/config/${client_id}`);
        const errors = await api.get(`/errors/errors_google_api/${client_id}`);

        setAllUsers(response.data[0]);
        setMyUsersConfig(configResponse.data[0]);
        setErrorsGoogleApi(errors.data[0]);
      } catch (error) {
        put(signOut());
        history.push('/');
      }
    }
    LoadAllUsers(client.client_id);
  }, [client.client_id]);

  function handleSubmit(data) {
    dispatch(updateClientRequest(data));
    history.push('/administracao/config');
  }

  const [filter, setFilter] = useState('');
  function handleChangeFilter(e) {
    setFilter(e.target.value);
  }

  const handleChange = async (event, permission) => {
    const email = event.target.name;

    // const elementSwitch = document.getElementById(email);
    // elementSwitch.disabled = true;

    const config_exist =
      allUsersConfig.find((element) => element.email === email) !== undefined;

    const allUsersConfigResponse = await api.put(
      `/users/allow/${client.client_id}`,
      {
        email,
        config_exist,
        permission,
      }
    );

    setUserLoading('');

    setMyUsersConfig(allUsersConfigResponse.data[0]);
    // elementSwitch.disabled = false;
  };

  const filteredUsers = useMemo(() => {
    let result = [...allUsers];

    if (filter !== '') {
      const filterLc = filter.toLowerCase();

      result = result.filter((myUser) => {
        if (filterLc === '/') {
          return myUser.ou === '/';
        }

        const emailLc = myUser.email.toLowerCase();
        const ouLc = myUser.ou.toLowerCase();
        return emailLc.includes(filterLc) || ouLc.includes(filterLc);
      });
    }

    return result;
  }, [allUsers, filter]);

  return (
    <>
      <CardSettings>
        <HeaderSettings>
          <h3>Conta</h3>
          <hr />
        </HeaderSettings>
        <InfoAccount>
          <div>
            <h4>
              <b>Configuração de Administrador</b>
            </h4>
            <p>
              {client.nameAdmin} {client.lastname}
            </p>
            <p>{client.emailadmin}</p>
            <p>{client.domain}</p>
          </div>
          <div>
            <img
              src={client.thumbnailAdmin ? client.thumbnailAdmin : logo}
              alt="foto perfil"
            />
          </div>
          {accountWarning && (
            <Badge title={accountWarning} error={accountWarning}>
              i
            </Badge>
          )}
        </InfoAccount>
        <ContainerForm>
          <Form onSubmit={handleSubmit} initialData={client}>
            <Input
              label="Email de Administrador da conta"
              name="emailadmin"
              type="email"
              placeholder="Administrador da conta"
            />
            <Input
              label="Email para recebimento de alertas"
              name="alert_email"
              type="email"
              placeholder="Email para receber alertas"
            />
            <Input
              label="Organização"
              name="company_name"
              placeholder="Organização"
            />
            <Input
              label="Telefone para contato"
              name="phone"
              placeholder="Telefone profissional"
            />
            <Input
              label="Área de atuação"
              name="area_atuacao"
              placeholder="ex.: Tecnologia"
            />
            <Input label="Cidade" name="city" placeholder="Cidade" />

            <Input label="Estado" name="state" placeholder="Estado" />
            <Input
              label="Horário inicial de trabalho"
              name="start_work_time"
              type="time"
            />
            <Input
              label="Horário final de trabalho"
              name="end_work_time"
              type="time"
            />

            <Input name="client_id" />
            <button type="submit">Atualizar Perfil</button>
          </Form>
        </ContainerForm>
        <hr />
        <HeaderSettings>
          <h3>Permissões</h3>
          <hr />
        </HeaderSettings>
        <SearchUser>
          <nav>
            <span>
              <MdSearch color="#333" size={20} />
            </span>
            <input
              type="text"
              placeholder="Buscar email ou OU"
              value={filter}
              onChange={handleChangeFilter}
            />
          </nav>
        </SearchUser>
        <ContainerUsers>
          {filteredUsers.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th> </th>
                  <th>Usuário</th>
                  <th>Ver OU</th>
                  <th>Ver Tudo</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((myuser) => {
                  const userConfig = allUsersConfig.find(
                    (element) => element.email === myuser.email
                  );
                  return (
                    <tr key={myuser.email}>
                      <td>
                        <img
                          src={myuser.thumbnail ? myuser.thumbnail : logo}
                          alt=""
                        />
                      </td>
                      <td>
                        {myuser.name}
                        <br />
                        {myuser.email}
                        <br />
                        OU: {myuser.ou}
                      </td>
                      <td>
                        {userLoading === myuser.email ? (
                          <Loading />
                        ) : (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  userConfig
                                    ? userConfig.view_ou ||
                                      userConfig.is_allowed
                                    : false
                                }
                                disabled={userLoading.length > 0}
                                onChange={(e) => {
                                  setUserLoading(e.target.name);
                                  handleChange(e, 'view_ou');
                                }}
                                name={myuser.email}
                                id={myuser.email}
                              />
                            }
                          />
                        )}
                      </td>
                      <td>
                        {userLoading === myuser.email ? (
                          <Loading />
                        ) : (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  userConfig ? userConfig.is_allowed : false
                                }
                                disabled={userLoading.length > 0}
                                onChange={(e) => {
                                  setUserLoading(e.target.name);
                                  handleChange(e, 'is_allowed');
                                }}
                                name={myuser.email}
                                id={myuser.email}
                              />
                            }
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </ContainerUsers>
      </CardSettings>
    </>
  );
}
