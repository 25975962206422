import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  registerTime: null,
  requester: {},
  loginTime: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.registerTime = new Date(action.payload.client.created_at);
        draft.signed = true;
        draft.loading = false;
        draft.loginTime = new Date();
        break;
      }
      case '@auth/SIGN_IN_FAILURE': {
        draft.loading = false;
        draft.requester = {
          email: action.payload.email,
          thumbnail: action.payload.thumbnail,
          authorization: `https://admin.google.com/${action.payload.domain}/AdminHome?clientScopeToAdd=https://www.googleapis.com/auth/admin.directory.domain.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly,https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly,https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.reports.audit.readonly,https://www.googleapis.com/auth/admin.reports.usage.readonly,https://www.googleapis.com/auth/admin.directory.customer.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly&clientNameToAdd=117310322945210736652&chromeless=1#OGX:ManageOauthClients`,
        };
        draft.registerTime = action.payload.registerTime;
        break;
      }
      case '@auth/SIGN_UP_SUCCESS': {
        draft.registerTime = new Date().toISOString();
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.signed = false;
        draft.token = null;
        draft.registerTime = null;
        break;
      }
      default:
    }
  });
}
