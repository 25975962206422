import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';

import { store } from '../store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  Admin,
  ...rest
}) {
  const { path } = rest;
  const { signed, requester } = store.getState().Auth;

  const user = useSelector((state) => state.User.profile);

  if ((path === '/' || path === '/dashboard/adoption') && signed && isPrivate) {
    return <Redirect to="/dashboard/organization" />;
  }

  if (path === '/dashboard/profile' && signed && isPrivate) {
    return <Redirect to="/dashboard/people" />;
  }

  if (path === '/user/admin' && user.client_id.name !== 'C017224q7') {
    return <Redirect to="/dashboard/organization" />;
  }

  if (signed && Admin && !user.is_admin) {
    return <Redirect to="/dashboard/organization" />;
  }

  if (signed && path === '/administracao/config' && !user.is_admin) {
    return <Redirect to="/dashboard/organization" />;
  }

  if (signed && path === '/insights/config' && !user.is_admin) {
    return <Redirect to="/dashboard/organization" />;
  }

  if (path === '/dashboard' && signed && !isPrivate) {
    return <Redirect to="/dashboard/organization" />;
  }

  // Se usário tentar acessar uma página sem estar logado, redirecionar
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (path === '/register' && !requester.email) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/dashboard/organization" />;
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout path={path}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

// Declarando os tipos e valores padroes dos props
RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  Admin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  Admin: false,
  component: null,
};
