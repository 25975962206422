import { produce } from 'immer';

const INITIAL_STATE = {
  rankingData: [],
  loading: true,
};
export default function RankingData(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@ranking/GET_RANKING_DATA_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@ranking/GET_RANKING_DATA_SUCCESS': {
        draft.rankingData = action.payload.ranking;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
