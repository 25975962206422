import { all } from 'redux-saga/effects';
import Client from './Client/sagas';
import Auth from './Auth/sagas';
import User from './User/sagas';

import ClientData from './ClientData/sagas';
import Ranking from './Ranking/sagas';
import Insights from './Insights/sagas';
import ClientList from './ClientList/sagas';

export default function* rootSaga() {
  return yield all([
    Client,
    ClientData,
    Auth,
    User,
    Ranking,
    Insights,
    ClientList,
  ]);
}
