import React from 'react';
import PropTypes from 'prop-types';
import { MdCopyright } from 'react-icons/md';
import { RiFeedbackLine } from 'react-icons/ri';
import { BiBarChartAlt2 } from 'react-icons/bi';
import { FiSettings, FiUsers, FiMap } from 'react-icons/fi';
import { FaRegLightbulb } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardPeopleChartProfile from '~/components/Dashboard/People/Chart/Profile';
import Feedback from '~/components/UI/Feedback';
import UserSettingsClientSelector from '~/components/User/Settings/ClientSelector';
import { signOut } from '~/store/modules/Auth/actions';
import history from '~/services/history';
import logo from '~/assets/logo_nova_50.png';
import {
  Container,
  ContentTopMenu,
  HeaderLogo,
  HeaderContent,
  ProfileContent,
  Footer,
  ViewArea,
  Logout,
  Version,
  CustomListItem,
  CustomListItemText,
} from './styles';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function SideBarMenu({ path }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User.profile);
  const clientLoaded = useSelector(
    (state) => state.ClientData.clientAppUsageData
  );
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openFeedback, setOpenFeedback] = React.useState(false);
  const [feedbackText, setFeedbackText] = React.useState('');

  function handlerLogout() {
    dispatch(signOut());
    history.push('/');
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleFeedbackClose = () => {
    setOpenFeedback(false);
  };

  const handleChangeFeedbackText = (event) => {
    setFeedbackText(event.target.value);
  };

  const clearFeedbackText = () => setFeedbackText('');

  return (
    <Container>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            style={{ padding: '5px' }}
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            {/* <MdDashboard size="25" color="#f8625c" /> */}

            <img
              src={logo}
              alt="Safetec"
              width="100%"
              height="80%"
              draggable="false"
            />
          </IconButton>

          {user &&
            user.client_id.name === 'C017224q7' &&
            (path === '/dashboard/organization' ||
              path === '/dashboard/people') && (
              <>
                {' '}
                <UserSettingsClientSelector />
                <ViewArea>
                  <p>
                    Visualizando como: <b>{clientLoaded.domain}</b>{' '}
                  </p>
                </ViewArea>
              </>
            )}
          <ContentTopMenu>
            <HeaderContent>
              <DashboardPeopleChartProfile />
              <ProfileContent>
                <p>{user ? user.name : ''}</p>
                <small>{user ? user.email : ''}</small>
              </ProfileContent>
            </HeaderContent>
          </ContentTopMenu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <HeaderLogo>
            <Link to="/dashboard/organization">
              {/* <MdDashboard size="25" /> */}
              <img src={logo} alt="Safetec" draggable="false" />
              <div style={{ lineHeight: '.8em' }}>
                ST INSIGHTS <br /> <small> Safetec Informática</small>
              </div>
            </Link>
          </HeaderLogo>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            {
              title: 'Organização',
              link: '/dashboard/organization',
              icon: BiBarChartAlt2,
            },
            {
              title: 'Pessoas',
              link: '/dashboard/people',
              icon: FiUsers,
            },
            {
              title: 'Insights',
              link: '/insights',
              icon: FaRegLightbulb,
            },
            {
              title: 'Configurações',
              link: '/administracao/config',
              icon: FiSettings,
            },
          ].map((navItem) => {
            const classNameLink =
              navItem.title === 'Configurações' &&
              ((clientLoaded &&
                user &&
                clientLoaded.domain !== 'safetec.com.br' &&
                user.client_id.name === 'C017224q7') ||
                (user && !user.is_admin))
                ? 'disable_link'
                : '';
            const selected =
              path === navItem.link ||
              (navItem.title === 'Configurações' &&
                path === '/insights/config');
            return (
              <React.Fragment key={navItem.link}>
                {navItem.title === 'Configurações' && (
                  <Divider style={{ marginBottom: 8 }} />
                )}
                <Link className={classNameLink} to={navItem.link}>
                  <CustomListItem
                    title={navItem.title}
                    button
                    key={navItem.title}
                    onClick={handleDrawerClose}
                    selected={selected}
                  >
                    <ListItemIcon>
                      <navItem.icon
                        size="24"
                        color={selected ? '#ffffff' : '#f8625c'}
                      />
                    </ListItemIcon>
                    <CustomListItemText selected={selected}>
                      {navItem.title}
                    </CustomListItemText>
                  </CustomListItem>
                </Link>
              </React.Fragment>
            );
          })}

          {[
            {
              title: 'Roadmap',
              link: 'https://hostst.com.br/roadmap_sti.html',
            },
          ].map((navItem) => (
            <ListItem
              title={navItem.title}
              component="a"
              button
              key={navItem.title}
              onClick={handleDrawerClose}
              href={navItem.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemIcon>
                <FiMap size="24" color="#f8625c" />
              </ListItemIcon>
              <CustomListItemText>{navItem.title}</CustomListItemText>
            </ListItem>
          ))}

          {openFeedback && (
            <Feedback
              path={path}
              handleClose={handleFeedbackClose}
              handleChangeFeedbackText={handleChangeFeedbackText}
              clearFeedbackText={clearFeedbackText}
              feedbackText={feedbackText}
            />
          )}

          <ListItem
            title="Feedback"
            button
            onClick={() => {
              setOpenFeedback(true);
              // handleDrawerClose();
            }}
          >
            <ListItemIcon>
              <RiFeedbackLine size="24" color="#f8625c" />
            </ListItemIcon>
            <CustomListItemText>Feedback</CustomListItemText>
          </ListItem>
        </List>

        <List style={{ position: 'absolute', bottom: 50, width: '100%' }}>
          <ListItem style={{ justifyContent: 'center' }}>
            <Logout
              buttonText="Sair"
              clientId="171833423013-9crsjtnvkr9uqi9m6ifdasiqh82ogr9d.apps.googleusercontent.com"
              onLogoutSuccess={() => {
                handlerLogout();
              }}
            />
          </ListItem>
          {open ? (
            <Version>ST Insights v:2.39.1</Version>
          ) : (
            <Version>v:2.39.1</Version>
          )}
        </List>
        <Footer>
          {open ? (
            <>Copyright © 2020 Safetec</>
          ) : (
            <MdCopyright color="495057" title="Copyright © 2020 Safetec" />
          )}
        </Footer>
      </Drawer>
    </Container>
  );
}

SideBarMenu.propTypes = {
  path: PropTypes.string,
};

SideBarMenu.defaultProps = {
  path: '/',
};
