import styled from 'styled-components';
import { device } from '~/utils/mediaQueries';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`;
export const Wraper = styled.div`
  position: fixed;
  top: 0;
  z-index: 20000;
  width: 85%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

export const ModalCard = styled.div`
  z-index: 100;
  background: white;
  position: absolute;
  border-radius: 3px;
  bottom: 0px;
  top: 0;
  overflow-y: auto;
  width: 50%;
  height: 440px;
  margin: auto;

  @media ${device.laptopL} {
    width: 60%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
  margin-bottom: 10px;
  padding: 10px 30px 10px 30px;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);

  span {
    p {
      color: #495057;
      font-size: 1.7em;
    }
  }

  button {
    background: none;
    color: #f75a48;
    border: 1px solid #f75a48;
    border-radius: 4px;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 160px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: scroll;
`;

export const SearchUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  h3 {
    color: #333;
  }
  nav {
    width: 60%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    input {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      height: 35px;
      color: #f75a48;
      margin-bottom: 20px;
      border: none;
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
      box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -webkit-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
      -moz-box-shadow: 0px 0px 2px 1px rgba(46, 46, 46, 0.75) inset;
    }
    span {
      padding: 9px;
      align-items: baseline;
    }
  }
`;

export const ContainerUsers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;

  -webkit-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 7px 17px -5px rgba(0, 0, 0, 0.2);

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:hover {
        background-color: #e0f3ff;
      }
      td {
        padding: 10px 5px;
        img {
          margin-right: 20px;
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }
        /* &:first-child,
        &:last-child {
          text-align: center;
        } */
      }
    }
  }
`;
