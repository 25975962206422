export function getAppUsageDataRequest(customer_id, filter) {
  return {
    type: '@clientdata/GET_APP_USAGE_REQUEST',
    payload: { customer_id, filter },
  };
}

export function getAppUsageDataSuccess(clientData) {
  return {
    type: '@clientdata/GET_APP_USAGE_SUCCESS',
    payload: { clientData },
  };
}

export function getAppUsageDetailsDataRequest(customer_id, filter) {
  return {
    type: '@clientdata/GET_APP_USAGE_DETAILS_REQUEST',
    payload: { customer_id, filter },
  };
}

export function getAppUsageDetailsDataSuccess(appUsageDetails) {
  return {
    type: '@clientdata/GET_APP_USAGE_DETAILS_SUCCESS',
    payload: { appUsageDetails },
  };
}

export function getEditorDriveDataRequest(customer_id, ou) {
  return {
    type: '@clientdata/GET_EDITOR_DRIVE_REQUEST',
    payload: { customer_id, ou },
  };
}

export function getEditorDriveDataSuccess(clientEditorDrive) {
  return {
    type: '@clientdata/GET_EDITOR_DRIVE_SUCCESS',
    payload: { clientEditorDrive },
  };
}

export function getDocsCreateDataRequest(customer_id, filter) {
  return {
    type: '@clientdata/GET_DOCS_CREATE_REQUEST',
    payload: { customer_id, filter },
  };
}

export function getDocsCreateDataSuccess(clientDocsData) {
  return {
    type: '@clientdata/GET_DOCS_CREATE_SUCCESS',
    payload: { clientDocsData },
  };
}
export function getCustomerStatesDataRequest(customer_id) {
  return {
    type: '@clientdata/GET_CUSTOMER_STATES_REQUEST',
    payload: { customer_id },
  };
}

export function getCustomerStatesDataSuccess(clientStatesData) {
  return {
    type: '@clientdata/GET_CUSTOMER_STATES_SUCCESS',
    payload: { clientStatesData },
  };
}

export function getUserProfilesDataRequest(customer_id, ou, period) {
  return {
    type: '@clientdata/GET_USER_PROFILES_REQUEST',
    payload: { customer_id, ou, period },
  };
}

export function getUserProfilesDataSuccess(userprofilesData) {
  return {
    type: '@clientdata/GET_USER_PROFILES_SUCCESS',
    payload: { userprofilesData },
  };
}

export function getSharedDrivesDataRequest(customer_id, filter) {
  return {
    type: '@clientdata/GET_SHARED_DRIVES_REQUEST',
    payload: { customer_id, filter },
  };
}

export function getSharedDrivesDataSuccess(sharedDrivesData) {
  return {
    type: '@clientdata/GET_SHARED_DRIVES_SUCCESS',
    payload: { sharedDrivesData },
  };
}
