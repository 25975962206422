import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getRankingDataSuccess } from './actions';
import api from '~/services/api';

export function* getRankingData({ payload }) {
  try {
    const { customer_id, domain, ou = '/', period } = payload;
    const response = yield call(api.get, '/users/ranking', {
      params: { customer_id, domain, ou, period },
    });
    yield put(getRankingDataSuccess(response.data));
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.tron.log(err);
    }
  }
}

export default all([
  takeLatest('@ranking/GET_RANKING_DATA_REQUEST', getRankingData),
]);
