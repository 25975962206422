import styled from 'styled-components';
import Select from 'react-select';
import { MdInfo } from 'react-icons/md';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { device } from '~/utils/mediaQueries';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background: #fff;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.2);
  grid-area: Ranking !important;

  hr {
    padding: 0;
    margin: 0;
  }
`;

export const InfoIcon = styled(MdInfo)`
  margin-right: -13px;
  margin-left: 10px;
`;

export const LoadingArea = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#f8625c' : 'white',
  }),
};

export const MultipleSelect = styled(Select).attrs({ styles: customStyles })`
  width: 100%;
`;

export const Header = styled.header`
  text-shadow: 1px 2px 2px #333;
  width: 100%;
  height: 60px;
  background: #f75a48;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dashboard = styled.div`
  height: 100%;
`;
export const HeaderChart = styled.header`
  padding: 11px 30px 0px;
  color: #333;
  text-align: left;
  align-items: center;
  justify-content: space-around;
  display: flex;
  svg {
    color: #f8625c;
  }
  div.title {
    width: 65%;
    display: flex;
    align-items: flex-start;
    padding: 10px;
    span {
      margin-left: 20px;
      p {
        padding: 0;
        line-height: 1;
        font-size: 1.5em;
        display: flex;
      }

      small {
        color: #f8625c;
        font-size: 1em;
      }
    }
  }
  div.search {
    width: 50%;
  }

  @media ${device.mobileL} {
    > div {
      display: none !important;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.mobileM} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
  @media ${device.tablet} {
    > div {
      display: none;
    }
    span {
      width: 100%;
    }
  }
`;

export const MuiButtonGroup = styled(ButtonGroup)`
  margin: auto;

  @media ${device.tablet} {
    display: none !important;
  }
`;

export const PeriodButton = styled(Button)`
  font-weight: bold !important;
  border-color: #f8625c !important;
  background-color: ${(props) =>
    props.variant === 'contained' ? '#f8625c' : '#fff'} !important;
  color: ${(props) =>
    props.variant === 'contained' ? '#fff' : '#f8625c'} !important;
`;
