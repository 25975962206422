import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MdSearch, MdClose } from 'react-icons/md';

import default_icon from '~/assets/sem-imagem-avatar.png';
import {
  Overlay,
  ModalCard,
  Wraper,
  Header,
  SearchUser,
  ContainerUsers,
  Table,
  Content,
} from './styles';

export default function Modal({ isShowing, hide, data, driveName }) {
  const [searchEmail, setSearchEmail] = useState('');

  const allUsers = useMemo(() => {
    const dataCopy = [...data];

    dataCopy.sort((a, b) => a.name.localeCompare(b.name));

    setSearchEmail('');

    return dataCopy;
  }, [data]);

  const filteredUsers = useMemo(() => {
    let result = [...allUsers];

    if (searchEmail !== '') {
      result = result.filter((user) => {
        const lc = user.email.toLowerCase();
        const filter = searchEmail.toLowerCase();

        return lc.includes(filter);
      });
    }

    return result;
  }, [allUsers, searchEmail]);

  function handlerGetUser(e) {
    setSearchEmail(e.target.value);
  }

  return isShowing ? (
    <>
      <Overlay />
      <Wraper>
        <ModalCard>
          <Header>
            <span>
              <p>Usuários ativos no Drive {driveName} </p>
            </span>
            <button type="button" onClick={hide}>
              <MdClose color="#f75a48" size={30} />
            </button>
          </Header>
          <SearchUser>
            <nav>
              <span>
                <MdSearch color="#333" size={20} />
              </span>
              <input
                type="text"
                placeholder="Buscar email"
                value={searchEmail}
                onChange={handlerGetUser}
              />
            </nav>
          </SearchUser>
          <Content>
            <ContainerUsers>
              <Table>
                <tbody>
                  {filteredUsers.map((myuser) => (
                    <tr key={myuser.email}>
                      <td style={{ textAlign: 'center', width: '200px' }}>
                        <img
                          src={
                            myuser.thumbnail ? myuser.thumbnail : default_icon
                          }
                          alt=""
                        />
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {myuser.name}
                        <br />
                        {myuser.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ContainerUsers>
          </Content>
        </ModalCard>
      </Wraper>
    </>
  ) : null;
}

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    })
  ).isRequired,
  driveName: PropTypes.string.isRequired,
};
