export function getRankingDataRequest(customer_id, domain, ou, period) {
  return {
    type: '@ranking/GET_RANKING_DATA_REQUEST',
    payload: { customer_id, domain, ou, period },
  };
}

export function getRankingDataSuccess(ranking) {
  return {
    type: '@ranking/GET_RANKING_DATA_SUCCESS',
    payload: { ranking },
  };
}
