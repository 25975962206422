import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import UserAdmin from '~/pages/User/Admin';

import UserSignIn from '~/pages/User/SignIn';
import UserSignUp from '~/pages/User/SignUp';

import DashboardOrganization from '~/pages/Dashboard/Organization';
import DashboardPeople from '~/pages/Dashboard/People';
import UserTutorials from '~/pages/User/Tutorials';
import Insights from '~/pages/Insights';
import Config from '~/pages/Config';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={UserSignIn} />
      <Route path="/register" component={UserSignUp} />
      <Route path="/tutorial" component={UserTutorials} />

      <Route exact path="/insights" component={Insights} isPrivate />
      <Route path="/user/admin" component={UserAdmin} isPrivate Admin />
      <Route
        path="/dashboard/organization"
        component={DashboardOrganization}
        isPrivate
      />
      <Route path="/dashboard/people" component={DashboardPeople} isPrivate />
      <Route path="/administracao/config" component={Config} isPrivate Admin />
      <Route path="/insights/config" component={Config} isPrivate Admin />

      {/* redirecionador para rotas desconhecidas e antigas */}
      <Route path="/dashboard/profile" component={null} isPrivate />
      <Route path="/dashboard/adoption" component={null} isPrivate />
      <Route path="/" component={null} isPrivate />
    </Switch>
  );
}
