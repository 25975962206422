import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  getAppUsageDataSuccess,
  getDocsCreateDataSuccess,
  getCustomerStatesDataSuccess,
  getEditorDriveDataSuccess,
  getAppUsageDetailsDataSuccess,
  getUserProfilesDataSuccess,
  getSharedDrivesDataSuccess,
} from './actions';
import api from '~/services/api';
import expireSession from '~/utils/logout';

let isLogout = false;

export function* getClientAppUsageData({ payload }) {
  try {
    let { filter } = payload;
    filter = yield select(
      (state) => filter || state.ClientData.clientAppUsageData.filter
    );
    const { customer_id } = payload;
    const response = yield call(api.get, '/app/usage', {
      params: { customer_id, filter },
    });

    yield put(getAppUsageDataSuccess(response.data));
  } catch (err) {
    if (!isLogout) {
      isLogout = true;
      yield expireSession();
      setTimeout(() => {
        isLogout = false;
      }, 2000);
    }
  }
}

export function* getClientAppUsageDetailsData({ payload }) {
  try {
    let { filter } = payload;
    filter = yield select(
      (state) => filter || state.ClientData.clientAppUsageDetailsData.filter
    );
    const { customer_id } = payload;
    const response = yield call(api.get, '/app/usage/details', {
      params: { customer_id, filter },
    });

    yield put(getAppUsageDetailsDataSuccess(response.data));
  } catch (err) {
    if (!isLogout) {
      isLogout = true;
      yield expireSession();
      setTimeout(() => {
        isLogout = false;
      }, 2000);
    }
  }
}

export function* getClientEditorDriveData({ payload }) {
  try {
    const { customer_id } = payload;
    const response = yield call(api.get, '/app/editor/drive', {
      params: { customer_id },
    });

    yield put(getEditorDriveDataSuccess(response.data));
  } catch (err) {
    if (!isLogout) {
      isLogout = true;
      yield expireSession();
      setTimeout(() => {
        isLogout = false;
      }, 2000);
    }
  }
}

export function* getClientDocsCreateData({ payload }) {
  try {
    let { filter } = payload;
    filter = yield select(
      (state) => filter || state.ClientData.clientDocsCreateData.filter
    );
    const { customer_id } = payload;
    const response = yield call(api.get, '/app/docs/create', {
      params: { customer_id, filter },
    });
    yield put(getDocsCreateDataSuccess(response.data));
  } catch (err) {
    if (!isLogout) {
      isLogout = true;
      yield expireSession();
      setTimeout(() => {
        isLogout = false;
      }, 2000);
    }
  }
}
export function* getClientStatesData({ payload }) {
  try {
    const { customer_id, date } = payload;
    const response = yield call(api.get, '/client/organization', {
      params: { customer_id, date },
    });
    yield put(getCustomerStatesDataSuccess(response.data));
  } catch (err) {
    if (!isLogout) {
      isLogout = true;
      yield expireSession();
      setTimeout(() => {
        isLogout = false;
      }, 2000);
    }
  }
}
export function* getUserProfilesData({ payload }) {
  try {
    const { customer_id, ou = '/', period } = payload;
    const response = yield call(api.get, '/users/people/content', {
      params: { customer_id, ou, period },
    });

    yield put(getUserProfilesDataSuccess(response.data));
  } catch (error) {
    if (!isLogout) {
      isLogout = true;
      yield expireSession();
      setTimeout(() => {
        isLogout = false;
      }, 2000);
    }
  }
}

export function* getSharedDrivesData({ payload }) {
  try {
    let { filter } = payload;
    filter = yield select(
      (state) => filter || state.ClientData.clientSharedDrives.filter
    );
    const { customer_id } = payload;
    const response = yield call(api.get, '/app/shared/drives', {
      params: { customer_id, filter },
    });

    yield put(getSharedDrivesDataSuccess(response.data));
  } catch (err) {
    if (!isLogout) {
      isLogout = true;
      yield expireSession();
      setTimeout(() => {
        isLogout = false;
      }, 2000);
    }
  }
}

export default all([
  takeLatest('@clientdata/GET_APP_USAGE_REQUEST', getClientAppUsageData),
  takeLatest(
    '@clientdata/GET_APP_USAGE_DETAILS_REQUEST',
    getClientAppUsageDetailsData
  ),
  takeLatest('@clientdata/GET_DOCS_CREATE_REQUEST', getClientDocsCreateData),
  takeLatest('@clientdata/GET_CUSTOMER_STATES_REQUEST', getClientStatesData),
  takeLatest('@clientdata/GET_EDITOR_DRIVE_REQUEST', getClientEditorDriveData),
  takeLatest('@clientdata/GET_USER_PROFILES_REQUEST', getUserProfilesData),
  takeLatest('@clientdata/GET_SHARED_DRIVES_REQUEST', getSharedDrivesData),
]);
