import React from 'react';
import { MdBubbleChart, MdDeveloperBoard } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardPeopleChartProfile from '~/components/Dashboard/People/Chart/Profile';
import logo from '~/assets/logo_50.png';
import {
  Container,
  ContentTopMenu,
  HeaderLogo,
  HeaderContent,
  HeaderDashboard,
  ItemMenu,
  ProfileContent,
} from './styles';

export default function Header() {
  const user = useSelector((state) => state.User.profile);

  function handleActive() {}
  return (
    <>
      <Container>
        <ContentTopMenu>
          <HeaderLogo>
            <Link to="/dashboard/organization">
              {/* <MdDashboard size="25" /> */}
              <img src={logo} alt="Safetec" draggable="false" />
              <div style={{ lineHeight: '.8em' }}>
                ST INSIGHTS - <small> Safetec Informática</small>
              </div>
            </Link>
          </HeaderLogo>
          <HeaderContent>
            <DashboardPeopleChartProfile />
            <ProfileContent>
              <p>{user ? user.name : ''}</p>
              <small>{user ? user.email : ''}</small>
            </ProfileContent>
          </HeaderContent>
        </ContentTopMenu>
        <HeaderDashboard>
          <ItemMenu to="/dashboard/organization" onClick={() => handleActive()}>
            <div>
              <MdBubbleChart size="24" color="#f8625c" />
            </div>
            <span>
              <p>Painel de adoção</p>
            </span>
          </ItemMenu>
          <ItemMenu
            to="/insights"
            activeClassName="isActive"
            onClick={() => handleActive()}
          >
            <div>
              <MdDeveloperBoard size="24" color="#f8625c" />
            </div>
            <span>
              <p>Quadro de Insights</p>
            </span>
          </ItemMenu>
        </HeaderDashboard>
      </Container>
    </>
  );
}
