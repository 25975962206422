import styled from 'styled-components';
import { darken } from 'polished';

export const CardSettings = styled.div`
  position: relative;

  margin: 0 40px;
  min-height: 200px;
  background: #fff;
  -webkit-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  -moz-box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
  box-shadow: 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05);
`;
export const HeaderSettings = styled.div`
  padding: 20px 20px 0 20px;
  color: #495057;
  h3 {
    font-size: 1.3em;
  }
`;

export const ContainerUsers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  form {
    width: 100%;
    button.submit {
      height: 40px;
      margin: 5px 0 0;
      width: 30%;
      background: #f75a48;
      font-weight: bold;
      border-radius: 4px;
      font-size: 16px;
      border: none;
      color: #fff;
      transition: background 0.2s;
      margin: 20px 0 20px 20px;
      div {
        margin: auto;
        width: 30px !important;
        height: 30px !important;
        svg {
          color: #fff;
        }
      }

      &:hover {
        background-color: ${darken(0.03, '#f75a48')};
      }
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  thead {
    tr {
      padding: 0 20px !important;
      th {
        position: relative;
        padding: 0px 20px 10px 20px;
        text-align: left !important;
        border-bottom: 1px solid #999;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #e0f3ff;
      }
      td {
        position: relative;
        padding: 5px 20px;
        text-align: left !important;
        p {
          font-size: 14px;
        }
        span.MuiSwitch-colorSecondary.Mui-checked,
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
          color: #f75a48 !important;
        }
        input.hide {
          display: none;
        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;

          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        input.show_ever {
          position: relative;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .checkmark {
          margin-top: 8px;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #eee;
          border-radius: 3px;
        }
        input:checked ~ .checkmark {
          background-color: #f75a48;
        }
        .checkmark:after {
          content: '';
          position: relative;
          display: none;
        }
        input:checked ~ .checkmark:after {
          display: block;
        }
        .checkmark:after {
          left: 6px;
          top: 3px;
          width: 9px;
          height: 9px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &.flex {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 60px;

          input {
            width: 50px;
            height: 30px;
            margin-left: 10px;
            text-align: center;
            color: #555;
            font-weight: bolder;
            border-radius: 3px;
          }
          p {
            width: 250px;
            font-size: 11px;
            color: #888;
          }
        }
      }
    }
  }
`;
