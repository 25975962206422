export function getClientListRequest(customer_id) {
  return {
    type: '@clientlist/GET_CLIENT_LIST_REQUEST',
    payload: { customer_id },
  };
}

export function getClientListSuccess(clientList) {
  return {
    type: '@clientlist/GET_CLIENT_LIST_SUCCESS',
    payload: { clientList },
  };
}
