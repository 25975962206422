export function sendFeedbackRequest(page, feedbackText) {
  return {
    type: '@client/SEND_FEEDBACK_REQUEST',
    payload: { page, feedbackText },
  };
}
export function sendFeedbackEndRequest() {
  return {
    type: '@client/SEND_FEEDBACK_END_REQUEST',
  };
}
export function sendFeedbackReset() {
  return {
    type: '@client/SEND_FEEDBACK_RESET',
  };
}
export function getDataClientRequest(customer_id) {
  return {
    type: '@client/GET_DATA_REQUEST',
    payload: { customer_id },
  };
}
export function registerClientRequest(token, userName, emailadmin) {
  return {
    type: '@client/REGISTER_REQUEST',
    payload: { token, userName, emailadmin },
  };
}
export function setRegisterDone(done) {
  return {
    type: '@client/SET_REGISTER_DONE',
    payload: { done },
  };
}

export function createClientSuccess(client) {
  return {
    type: '@client/CREATE_SUCCESS',
    payload: { client },
  };
}

export function registerClientSuccess(authorizations, customer_id) {
  return {
    type: '@client/REGISTER_SUCCESS',
    payload: { authorizations, customer_id },
  };
}

export function createClientFailure() {
  return {
    type: '@client/CREATE_FAILURE',
  };
}

export function updateClientRequest(data) {
  return {
    type: '@client/UPDATE_REQUEST',
    payload: { data },
  };
}
export function updateClientSuccess(client) {
  return {
    type: '@client/UPDATE_SUCCESS',
    payload: { client },
  };
}

export function setCurrentClient(client_id, domain) {
  return {
    type: '@client/SET_CURRENT_REQUEST',
    payload: { client_id, domain },
  };
}
