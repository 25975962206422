import styled from 'styled-components';
import { AreaChart, ResponsiveContainer } from 'recharts';

export const Container = styled.div`
  position: relative;
  border-radius: 3px;
  font-size: 11px;
  max-width: 100%;
  height: 310px;
  background-color: #fff;
  margin-bottom: 20px;

  .recharts-text.recharts-label {
    font-size: 12px;
  }
`;
export const HeaderChart = styled.header`
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #999;
  font-weight: bolder;
  text-align: left;
  padding: 20px 30px;

  svg {
    color: #f8625c;
    margin: 0 10px 0 0;
  }
`;

export const NoData = styled.div`
  height: 319px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #999;
  margin: 10px 10px 10px 10px;
  border-radius: 10px;
  background: repeating-linear-gradient(
    -55deg,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 30px,
    rgba(0, 0, 0, 0.1) 30px,
    rgba(0, 0, 0, 0.1) 60px
  );
`;

export const ResponsiveContainerChart = styled(ResponsiveContainer)`
  display: flex;
`;
export const AreaChartCustom = styled(AreaChart)``;
