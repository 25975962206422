import { combineReducers } from 'redux';
import Auth from './Auth/reducer';
import ClientData from './ClientData/reducer';
import Client from './Client/reducer';
import User from './User/reducer';
import Ranking from './Ranking/reducer';
import Insights from './Insights/reducer';
import ClientList from './ClientList/reducer';

export default combineReducers({
  Client,
  ClientData,
  Auth,
  User,
  Ranking,
  Insights,
  ClientList,
});
