import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdSearch } from 'react-icons/md';

import {
  Container,
  Form,
  Input,
  Button,
  CardList,
  CardContainer,
  Title,
} from './styles';
import { setCurrentClient } from '~/store/modules/Client/actions';
import {
  getAppUsageDataRequest,
  getDocsCreateDataRequest,
  getCustomerStatesDataRequest,
  getEditorDriveDataRequest,
  getAppUsageDetailsDataRequest,
  getUserProfilesDataRequest,
} from '~/store/modules/ClientData/actions';
import { getRankingDataRequest } from '~/store/modules/Ranking/actions';

export default function ClientSelector() {
  const [barOpened, setBarOpened] = useState(false);
  const inputFocus = useRef();
  const user = useSelector((state) => state.User.profile);
  const lista = useSelector((state) => state.ClientList.clientListData);

  const dispatch = useDispatch();

  /* var myClients = Object.keys(lista).map(function(key) {
    return [Number(key), lista[key]];
  }); */

  let myClients = lista.listClients;

  const [client, setClient] = useState('');
  const [allClients, setAllClients] = useState('');
  function handlerGetClient(e) {
    setAllClients('');
    setClient(e.target.value);
  }

  async function handleChooseClient(e) {
    const customer_id = e.target.id;
    const domain = `@${e.target.innerHTML}`;
    dispatch(setCurrentClient(customer_id, domain));
    dispatch(getAppUsageDataRequest(customer_id));
    dispatch(getAppUsageDetailsDataRequest(customer_id));
    dispatch(getDocsCreateDataRequest(customer_id));
    dispatch(getCustomerStatesDataRequest(customer_id));
    dispatch(getRankingDataRequest(customer_id, domain));
    dispatch(getEditorDriveDataRequest(customer_id));
    dispatch(getUserProfilesDataRequest(customer_id));

    setClient('');
  }

  if (
    client !== '' &&
    myClients !== undefined &&
    allClients !== 'all-clients-secrets'
  ) {
    myClients = myClients.filter((item) => {
      const lc = item.name.toLowerCase();
      const filter = client.toLowerCase();
      return lc.includes(filter);
    });
  }

  function handlerFocus() {
    setAllClients('all-clients-secrets');
  }

  return (
    <>
      {user && user.client_id.name === 'C017224q7' ? (
        <Container barOpened={barOpened}>
          <div className="App">
            <Form
              id="formId"
              autoComplete="off"
              barOpened={barOpened}
              onClick={() => {
                // When form clicked, set state of baropened to true and focus the input
                setBarOpened(true);
                inputFocus.current.focus();
              }}
              // on focus open search bar
              onFocus={() => {
                setBarOpened(true);
                inputFocus.current.focus();
              }}
              /* onBlur={e => {
                handleChooseClient(e);
              }} */

              onBlur={() => {
                setClient('');
                setAllClients('');
                setBarOpened(false);
              }}
            >
              <Button type="submit" barOpened={barOpened}>
                <MdSearch color="#333" size={20} />
              </Button>
              <Input
                id="inputId"
                ref={inputFocus}
                autoComplete="off"
                barOpened={barOpened}
                placeholder="Buscar clientes"
                onChange={handlerGetClient}
                onFocus={handlerFocus}
                value={client}
              />
            </Form>
          </div>
          <CardList id="Cards">
            {myClients !== undefined && (client !== '' || allClients !== '') ? (
              myClients.map((myClient) => (
                <CardContainer key={myClient.customer_id}>
                  <Title
                    id={myClient.customer_id}
                    onMouseDown={handleChooseClient}
                  >
                    {myClient.name}
                  </Title>
                </CardContainer>
              ))
            ) : (
              <></>
            )}
          </CardList>
        </Container>
      ) : null}
    </>
  );
}
