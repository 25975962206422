export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function registerAccessLog(page) {
  return {
    type: '@user/REGISTER_ACCESS_LOG',
    payload: { page },
  };
}
